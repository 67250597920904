import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Transaction, TransactionsState } from "./transactions";

const initialState: TransactionsState = {
   arr: [],
   init: true,
};

export const transactionsSlice = createSlice({
   name: "transactions",
   initialState,
   reducers: {
      setTransactions: (state, action: PayloadAction<TransactionsState>) => {
         // Clear the state before setting new transactions
         state.arr.splice(0, state.arr.length);
         // Push each new transaction into the state
         action.payload.arr.forEach((transaction) => {
            state.arr.push(transaction);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleTransaction: (
         state: any,
         action: PayloadAction<{
            transaction_id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // Find the transaction with the transaction_id and update the fields
         const { transaction_id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (transaction: Transaction) =>
               transaction.transaction_id === transaction_id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleTransaction: (state, action: PayloadAction<Transaction>) => {
         // Add a single transaction to the state
         state.arr.push(action.payload);
      },
      clearTransactions: () => initialState, // Reset the state to the initial state
   },
});

export const {
   setTransactions,
   updateSingleTransaction,
   clearTransactions,
   addSingleTransaction,
} = transactionsSlice.actions;
export const selectTransactions = (state: RootState) => state.transactions;

export default transactionsSlice.reducer;
