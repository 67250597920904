import { useState, useEffect } from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridToolbar,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ValidateOTPState } from "../../../features/validateOTP/validateOTP";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { setValidateOTP } from "../../../features/validateOTP/validateOTPSlice";
import { fetchData } from "../../../utilities/gen";

export default function ValidateOTPGrid() {
   const dispatch = useAppDispatch();
   const validateOTP: ValidateOTPState = useAppSelector(
      (state: any) => state.validateOTP
   );
   const session = useAppSelector((state: any) => state.session);

   const [loading, setLoading] = useState(true);
   const rows = validateOTP.arr;
   const EN = session.lang === "en" ? true : false;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("validate_otp", session.user.token); // Replace with actual table name and token
               dispatch(setValidateOTP({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load validate OTP data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (id: string) => {
      const editRow = validateOTP.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit" : "Editar",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "validateOTP",
            },
         })
      );
   };

   const handleView = (id: string) => {
      const viewRow = validateOTP.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View Validate OTP` : `Ver Validar OTP`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "validateOTP",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 150 },
      { field: "otc", headerName: EN ? "OTP Code" : "Código OTP", width: 120 },
      {
         field: "expirationtime",
         headerName: EN ? "Expiration Time" : "Tiempo de Expiración",
         width: 200,
      },
      {
         field: "phoneno",
         headerName: EN ? "Phone Number" : "Teléfono",
         width: 150,
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={EN ? "Validate OTP" : "Validar OTP"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Validate OTP" : "Validar OTP"}>
         <DataGrid
            slots={{ toolbar: GridToolbar }}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
