import React from "react";
import PublicTemplate from ".";
import { BORDER_RADIUS, ELEVATION } from "../../constants/styles";
import { Box, CssBaseline, Grid, Paper, Typography } from "@mui/material";
interface PublicCenteredFormProps {
   children: any;
   title?: string;
}

export default function PublicCenteredForm(
   props: PublicCenteredFormProps
): JSX.Element {
   const { children, title = "" } = props;

   return (
      <PublicTemplate>
         <Grid
            container
            component='main'
            sx={{
               // height: "80vh",
               justifyContent: "center",
               alignItems: "center",
               px: 2,
            }}
         >
            <CssBaseline />
            <Grid
               item
               xs={12}
               sm={8}
               md={6}
               component={Paper}
               elevation={ELEVATION}
               sx={{
                  borderRadius: BORDER_RADIUS,
               }}
            >
               <Box
                  sx={{
                     my: 8,
                     mx: 4,
                     // display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                  }}
               >
                  <Typography component='h3' variant='h5'>
                     {title ? title : ""}
                  </Typography>
                  {children}
               </Box>
            </Grid>
         </Grid>
      </PublicTemplate>
   );
}
