import React, { useState } from "react";
import {
   Button,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Container,
   Box,
   CircularProgress,
   Typography,
   TextField,
   InputAdornment,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { apiPost } from "../../utilities/ApiRequests";
import { API_URL } from "../../constants/api";
import { closeDialog } from "../../features/dialog/dialogSlice";
import { msg } from "../../utilities/gen";
import { addSingleFee } from "../../features/fees/feesSlice";

interface AddFeesProps {
   params: {
      EN: boolean;
      DEMO: boolean;
      school_id?: number;
      student_id?: string;
      student_name?: string;
   };
}

const AddFees = (props: AddFeesProps) => {
   const { EN, DEMO, school_id = null, student_id = null } = props.params;
   const dispatch = useAppDispatch();
   const schools = useAppSelector((state: any) => state.schools.arr);
   const students = useAppSelector((state: any) => state.students.arr);
   const userStudentLinks = useAppSelector(
      (state: any) => state.userStudentLinks.arr
   );
   const users = useAppSelector((state: any) => state.users.arr);
   const session = useAppSelector((state: any) => state.session);
   const token = session.user.token;

   const [schoolId, setSchoolId] = useState<number | null>(school_id);
   const [studentId, setStudentId] = useState<string | null>(student_id);
   const [guardianId, setGuardianId] = useState<number | null>(null);
   const [numPayments, setNumPayments] = useState<number>(1);
   const [fee, setFee] = useState<number>(0);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState<any>({});
   const [startMonth, setStartMonth] = useState<number>(
      new Date().getMonth() + 1
   );
   const [year, setYear] = useState<number>(new Date().getFullYear());
   const [startYear, setStartYear] = useState<number>(new Date().getFullYear());
   const [formData, setFormData] = useState<any>({});
   const [dayOfMonth, setDayOfMonth] = useState<number>(1);

   const handleChangeYear = (event: any) => {
      setStartYear(Number(event.target.value));
   };

   const handleChangeDOM = (event: any) => {
      setDayOfMonth(Number(event.target.value));
   };

   const handleChangeMonth = (event: any) => {
      setStartMonth(Number(event.target.value));
   };

   const handleChangeFee = (event: any) => {
      setFee(Number(event.target.value));
   };

   const handleChangeNumPayments = (event: any) => {
      setNumPayments(Number(event.target.value));
   };

   const handleSchoolChange = (event: any) => {
      setSchoolId(Number(event.target.value));
      setStudentId(null);
      setGuardianId(null);
   };

   const handleStudentChange = (event: any) => {
      setStudentId(event.target.value);
      setGuardianId(null);
   };

   const handleGuardianChange = (event: any) => {
      setGuardianId(Number(event.target.value));
   };

   const handleChange = (e: any) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const months = EN
      ? [
           { label: "January", value: 1 },
           { label: "February", value: 2 },
           { label: "March", value: 3 },
           { label: "April", value: 4 },
           { label: "May", value: 5 },
           { label: "June", value: 6 },
           { label: "July", value: 7 },
           { label: "August", value: 8 },
           { label: "September", value: 9 },
           { label: "October", value: 10 },
           { label: "November", value: 11 },
           { label: "December", value: 12 },
        ]
      : [
           { label: "Enero", value: 1 },
           { label: "Febrero", value: 2 },
           { label: "Marzo", value: 3 },
           { label: "Abril", value: 4 },
           { label: "Mayo", value: 5 },
           { label: "Junio", value: 6 },
           { label: "Julio", value: 7 },
           { label: "Agosto", value: 8 },
           { label: "Septiembre", value: 9 },
           { label: "Octubre", value: 10 },
           { label: "Noviembre", value: 11 },
           { label: "Diciembre", value: 12 },
        ];
   const currentYear = new Date().getFullYear();
   const years = [
      { label: currentYear, value: currentYear },
      { label: currentYear + 1, value: currentYear + 1 },
      { label: currentYear + 2, value: currentYear + 2 },
   ];

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      try {
         if (fee <= 0) {
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? "Fee must be greater than 0 " +
                             new Date().toLocaleString()
                        : "La cuota debe ser mayor a 0" +
                             new Date().toLocaleString(),
                     "error"
                  )
               )
            );
         } else {
            setLoading(true);

            const res = await apiPost(API_URL + "/add_fees", {
               token,
               school_id: schoolId,
               student_id: studentId,
               guardian_id: guardianId,
               fee,
               numPayments,
               startMonth,
               startYear,
               dayOfMonth,
               demo: DEMO,
            });

            // gather array of fees
            console.log(res.data.fees);
            // res.data.fees is an array of fees let put them into the fees state
            let fees = res.data.fees;

            // Check if fees is an array
            if (!Array.isArray(fees)) {
               try {
                  // Attempt to parse it as JSON
                  fees = JSON.parse(fees);
               } catch (error) {
                  console.error("Failed to parse fees:", error);
                  return;
               }
            }

            // Check again if it's an array after parsing
            if (Array.isArray(fees)) {
               fees.forEach((fee: any) => {
                  dispatch(addSingleFee(fee));
               });
            } else {
               console.error("Fees data is not an array:", fees);
            }

            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? "Fees added successfully"
                        : "Cuotas agregadas con éxito",
                     "success"
                  )
               )
            );

            setLoading(false);
            dispatch(closeDialog());
         }
      } catch (error: any) {
         setLoading(false);
         console.log(error);
         dispatch(
            addSnackbarMsg(
               msg(
                  EN
                     ? `Error: ${new Date().toLocaleString()}`
                     : `Error: ${new Date().toLocaleString()}`,
                  "error"
               )
            )
         );
      }
   };

   const filteredStudents = students.filter(
      (student: any) => student.school_id === schoolId
   );

   const filteredGuardians = userStudentLinks
      .filter((link: any) => link.student_id === studentId)
      .map((link: any) => ({
         ...link,
         name:
            users.find((user: any) => user.id === link.user_id)?.name1 ||
            "Unknown",
      }));

   return (
      <Container component='main' maxWidth='md' sx={{ padding: 2 }}>
         <Typography variant='h5' gutterBottom>
            {EN ? "Add Fees" : "Agregar Cuotas"}
         </Typography>
         <Box sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit} autoComplete='off'>
               <FormControl fullWidth margin='normal' error={!!errors.schoolId}>
                  <InputLabel>
                     {EN ? "Select School" : "Seleccionar Escuela"}
                  </InputLabel>
                  <Select
                     size='small'
                     value={schoolId || ""}
                     onChange={handleSchoolChange}
                     sx={{ minWidth: 350 }}
                  >
                     {schools.map((school: any) => (
                        <MenuItem key={school.id} value={school.id}>
                           {school.name}
                        </MenuItem>
                     ))}
                  </Select>
                  {errors.schoolId && (
                     <Typography color='error' variant='caption'>
                        {errors.schoolId}
                     </Typography>
                  )}
               </FormControl>

               {schoolId && (
                  <FormControl
                     fullWidth
                     margin='normal'
                     error={!!errors.studentId}
                  >
                     <InputLabel>
                        {EN ? "Select Student" : "Seleccionar Estudiante"}
                     </InputLabel>
                     <Select
                        value={studentId || ""}
                        size='small'
                        onChange={handleStudentChange}
                     >
                        {filteredStudents
                           .sort((a: any, b: any) =>
                              a.name.localeCompare(b.name)
                           )
                           .map((student: any) => (
                              <MenuItem
                                 key={student.document}
                                 value={student.document}
                              >
                                 {student.name}
                              </MenuItem>
                           ))}
                     </Select>
                     {errors.studentId && (
                        <Typography color='error' variant='caption'>
                           {errors.studentId}
                        </Typography>
                     )}
                  </FormControl>
               )}

               {studentId && (
                  <FormControl
                     fullWidth
                     margin='normal'
                     error={!!errors.guardianId}
                  >
                     <InputLabel>
                        {EN ? "Select Guardian" : "Seleccionar Tutor"}
                     </InputLabel>
                     <Select
                        value={guardianId || ""}
                        size='small'
                        onChange={handleGuardianChange}
                     >
                        {filteredGuardians.map((guardian: any) => (
                           <MenuItem
                              key={guardian.user_id}
                              value={guardian.user_id}
                           >
                              {guardian.name}
                           </MenuItem>
                        ))}
                     </Select>
                     {errors.guardianId && (
                        <Typography color='error' variant='caption'>
                           {errors.guardianId}
                        </Typography>
                     )}
                  </FormControl>
               )}

               {guardianId && (
                  <>
                     <FormControl fullWidth margin='normal'>
                        <InputLabel>
                           {EN ? "Number of Payments" : "Número de pagos"}
                        </InputLabel>
                        <Select
                           value={numPayments}
                           size='small'
                           onChange={handleChangeNumPayments}
                           label={EN ? "Number of Payments" : "Número de pagos"}
                        >
                           {Array.from({ length: 24 }, (_, i) => i + 1).map(
                              (num) => (
                                 <MenuItem key={num} value={num}>
                                    {num}
                                 </MenuItem>
                              )
                           )}
                        </Select>
                     </FormControl>
                     <TextField
                        label={EN ? "Fee Amount" : "Monto de la Cuota"}
                        value={fee}
                        size='small'
                        onChange={handleChangeFee}
                        fullWidth
                        margin='normal'
                        InputProps={{
                           startAdornment: (
                              <InputAdornment position='start'>
                                 $
                              </InputAdornment>
                           ),
                        }}
                     />
                     <FormControl fullWidth margin='normal'>
                        <InputLabel>
                           {EN ? "Payment Day of the month" : "Día de pago"}
                        </InputLabel>
                        <Select
                           value={dayOfMonth}
                           size='small'
                           onChange={handleChangeDOM}
                           label={
                              EN ? "Payment Day of the month" : "Día de pago"
                           }
                        >
                           {Array.from({ length: 31 }, (_, i) => i + 1).map(
                              (num) => (
                                 <MenuItem key={num} value={num}>
                                    {num}
                                 </MenuItem>
                              )
                           )}
                        </Select>
                     </FormControl>
                     <FormControl fullWidth margin='normal'>
                        <InputLabel>
                           {EN ? "Start Month" : "Mes de inicio"}
                        </InputLabel>
                        <Select
                           value={startMonth}
                           size='small'
                           onChange={handleChangeMonth}
                           label={EN ? "Month" : "Mes"}
                        >
                           {months.map((month) => (
                              <MenuItem key={month.value} value={month.value}>
                                 {month.label}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                     <FormControl fullWidth margin='normal'>
                        <InputLabel>
                           {EN ? "Start Year" : "Año de inicio"}
                        </InputLabel>
                        <Select
                           value={year}
                           size='small'
                           onChange={handleChangeYear}
                           label={EN ? "Year" : "Año"}
                        >
                           {years.map((year) => (
                              <MenuItem key={year.value} value={year.value}>
                                 {year.value}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>

                     <Button
                        size='small'
                        type='submit'
                        fullWidth
                        variant='contained'
                        sx={{ mt: 3, mb: 2 }}
                        disabled={loading}
                     >
                        {loading ? (
                           <CircularProgress size={24} />
                        ) : EN ? (
                           "Submit"
                        ) : (
                           "Enviar"
                        )}
                     </Button>
                  </>
               )}
            </form>
         </Box>
      </Container>
   );
};

export default AddFees;
