import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import React, { useEffect } from "react";
import {
   Button,
   Grid,
   TextField,
   Typography,
   CircularProgress,
   ButtonGroup,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { apiPost } from "../../../utilities/ApiRequests";
import { API_URL } from "../../../constants/api";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { msg } from "../../../utilities/gen";

export default function Settings() {
   const dispatch = useAppDispatch();
   const token = useAppSelector((state: any) => state.session.user.token);
   const [tokenToDecode, setTokenToDecode] = React.useState(token);
   const [decodedToken, setDecodedToken] = React.useState("");
   const [loading, setLoading] = React.useState(false);
   const [demoCanWrite, setDemoCanWrite] = React.useState<string>("-");

   const handleDecoded = async () => {
      setLoading(true);
      try {
         const res = await apiPost(API_URL + "/dddd", {
            token,
            tokenToDecode,
         });
         setDecodedToken(JSON.stringify(res.data, null, 2));
      } catch (e) {
         console.log(e);
         dispatch(addSnackbarMsg(msg("Error decoding token", "error")));
      } finally {
         setLoading(false);
      }
   };

   const handleCopy = () => {
      navigator.clipboard.writeText(token);
      dispatch(addSnackbarMsg(msg("Copied to clipboard", "success")));
   };
   const handleChange = async () => {
      try {
         const res = await apiPost(
            API_URL + "/settings/toggle_demo_db_access",
            { token }
         );
         setDemoCanWrite(demoCanWrite === "no" ? "yes" : "no");
         dispatch(
            addSnackbarMsg(
               msg(
                  `Demo access changed to: (${
                     demoCanWrite === "no" ? "yes" : "no"
                  })`,
                  "success"
               )
            )
         );
      } catch (e) {
         console.log(e);
         dispatch(addSnackbarMsg(msg("Error changing demo access", "error")));
      }
   };
   useEffect(() => {
      const fetchDemoAccess = async () => {
         try {
            const extra = new Date().getTime();
            const res: any = await apiPost(
               API_URL + "/settings/read_demo_access",
               {
                  token,
                  extra,
               }
            );
            setDemoCanWrite(res.data);
         } catch (e) {
            console.log(e);
            dispatch(addSnackbarMsg(msg("Error loading demo access", "error")));
         }
      };

      if (demoCanWrite === "-") fetchDemoAccess();
   }, []);

   return (
      <DashboardTemplate title='Settings'>
         <Typography variant='h6'>Can Demo write to DB?</Typography>
         {demoCanWrite === "-" ? (
            <CircularProgress />
         ) : (
            <FormControl fullWidth>
               <InputLabel id='demo-simple-select-label'>
                  Demo Status
               </InputLabel>
               <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={demoCanWrite}
                  label='Demo Can Write to DB'
                  onChange={handleChange}
               >
                  <MenuItem value='yes'>yes</MenuItem>
                  <MenuItem value='no'>no</MenuItem>
               </Select>
            </FormControl>
         )}
         <div style={{ padding: 10 }}></div>
         <Typography variant='h6'>Decode a token</Typography>
         <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
               <TextField
                  label='Token'
                  fullWidth
                  multiline
                  rows={5}
                  value={tokenToDecode}
                  onChange={(e) => setTokenToDecode(e.target.value)}
               />
               <br />
               <ButtonGroup fullWidth>
                  <Button
                     variant='contained'
                     onClick={handleDecoded}
                     fullWidth
                     disabled={loading}
                  >
                     {loading ? <CircularProgress size={24} /> : "Decode"}
                  </Button>
                  <Button
                     variant='contained'
                     onClick={handleCopy}
                     fullWidth
                     disabled={loading}
                  >
                     Copy
                  </Button>
               </ButtonGroup>
            </Grid>
            <Grid item xs={12} md={6}>
               <TextField
                  label='Decoded Token'
                  fullWidth
                  multiline
                  rows={7}
                  value={decodedToken}
                  InputProps={{
                     readOnly: true,
                  }}
               />
            </Grid>
         </Grid>
      </DashboardTemplate>
   );
}
