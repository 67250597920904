import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import {
   clearSnackbar,
   addSnackbarMsg,
} from "../../../features/snackbar/snackbarSlice";
import {
   clearSession,
   setSession,
   setToggleLang,
} from "../../../features/session/sessionSlice";
import { msg } from "../../../utilities/gen";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { SessionState } from "../../../features/session/session";
import { clearFees } from "../../../features/fees/feesSlice";
import { FormControl, MenuItem, Select } from "@mui/material";
import { clearStudents } from "../../../features/students/studentsSlice";
import { clearUsers } from "../../../features/users/usersSlice";
import { clearPayments } from "../../../features/payments/paymentsSlice";
import { clearOtherPayments } from "../../../features/otherPayments/otherPaymentsSlice";
import { clearSettlements } from "../../../features/settlements/settlementsSlice";
import { clearValidateOTP } from "../../../features/validateOTP/validateOTPSlice";
import { clearVouchers } from "../../../features/vouchers/vouchersSlice";
import { clearUserSchoolLinkState } from "../../../features/user_school_links/userSchoolLinkSlice";
import { clearSchools } from "../../../features/schools/schoolsSlice";
import { get, set } from "idb-keyval";
import { clearUserStudentLinkState } from "../../../features/user_student_links/userStudentLinkSlice";
import { clearLogs } from "../../../features/logs/logsSlice";
import { clearOptionalFees } from "../../../features/optional_fees/optional_feesSlice";

export default (): JSX.Element => {
   const navigate = useNavigate();
   const dis = useAppDispatch();
   const session: SessionState = useAppSelector((state: any) => state.session);

   const [anchorSe, setAnchorSe] = useState<null | HTMLElement>(null);
   const openSe = Boolean(anchorSe);
   const [anchorPr, setAnchorPr] = useState<null | HTMLElement>(null);
   const openPr = Boolean(anchorPr);

   const handleChangeLang = () => {
      try {
         dis(setToggleLang());
      } catch (error) {
         console.error("Error in ResponsiveAppBar.tsx: ", error);
      }
   };

   const handleMenuClosePr = () => setAnchorPr(null);

   const handleProfile = () => {
      setAnchorPr(null);
      dis(addSnackbarMsg(msg(`Loading Profile`, "info")));
      navigate(`/clients/profile`);
   };

   const handleLogout = async (
      event: React.MouseEvent<HTMLButtonElement> | any
   ): Promise<void> => {
      dis(addSnackbarMsg(msg(`Logging out`, "info")));
      setAnchorPr(event.currentTarget);

      try {
         // Retrieve the current state from IndexedDB
         const reduxState = await get("reduxState");

         if (reduxState && reduxState.session) {
            const lang = reduxState.session.lang;
            const darkMode = reduxState.session.darkMode;

            // Clear everything from IndexedDB except lang and darkMode
            console.log("Setting reduxState with session data:", {
               session: { lang, darkMode },
            });
            await set("reduxState", { session: { lang, darkMode } });
            console.log("Redux state has been updated.");

            console.log(`Language: ${lang}`); // Output the value of lang
            console.log(`Dark Mode: ${darkMode}`); // Output the value of darkMode
         } else {
            console.log("Session state not found.");
         }
      } catch (error) {
         console.error("Error handling logout:", error);
      }

      dis(clearFees());
      dis(clearLogs());
      dis(clearPayments());
      dis(clearOtherPayments());
      dis(clearSchools());
      dis(clearSettlements());
      dis(clearSession());
      dis(clearSnackbar());
      dis(clearStudents());
      dis(clearUsers());
      dis(clearUserStudentLinkState());
      dis(clearUserSchoolLinkState());
      dis(clearValidateOTP());
      dis(clearVouchers());
      dis(clearOptionalFees());

      setTimeout(() => navigate(`/login`), 1200);
   };

   const toggleDM = () =>
      dis(setSession({ ...session, darkMode: !session.darkMode }));

   const handleClickPr = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorPr(event.currentTarget);
   };

   const handleChangeDensity = (event: any) => {
      dis(setSession({ ...session, density: event.target.value }));
   };

   return (
      <div
         style={{
            minWidth: 80,
            position: "fixed",
            right: 10,
            top: 4,
            padding: 0,
            margin: 0,
         }}
      >
         <FormControl>
            <Select
               value={session.lang ? session.lang : "es"}
               size='small'
               onChange={handleChangeLang}
            >
               <MenuItem value={"en"}>EN</MenuItem>
               <MenuItem value={"es"}>ES</MenuItem>
            </Select>
         </FormControl>
         <IconButton
            id='basic-button'
            aria-controls={openSe ? "basic-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={openSe ? "true" : undefined}
            onClick={toggleDM}
         >
            {session.darkMode ? (
               <LightModeIcon style={{ color: "white" }} />
            ) : (
               <DarkModeIcon style={{ color: "white" }} />
            )}
         </IconButton>

         <IconButton
            id='basic-button'
            aria-controls={openPr ? "basic-menu" : undefined}
            aria-haspopup='true'
            aria-expanded={openPr ? "true" : undefined}
            onClick={handleClickPr}
         >
            <PersonIcon style={{ color: "white" }} />
         </IconButton>
         <Menu
            id='basic-menu'
            anchorEl={anchorPr}
            open={openPr}
            onClose={handleMenuClosePr}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
         >
            <MenuItem onClick={handleProfile}>Profile</MenuItem>
            <MenuItem onClick={(event) => handleLogout(event)}>Logout</MenuItem>
         </Menu>
      </div>
   );
};
