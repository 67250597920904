import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { VouchersState } from "../../../features/vouchers/vouchers";
import { setVouchers } from "../../../features/vouchers/vouchersSlice";
import { fetchData } from "../../../utilities/gen";

const formatDate = (dateString: number | string) => {
   const date = new Date(dateString);
   return date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "short",
      day: "2-digit",
   });
};

export default function VouchersGrid() {
   const dispatch = useAppDispatch();
   const session = useAppSelector((state: any) => state.session);
   const vouchers: VouchersState = useAppSelector(
      (state: any) => state.vouchers
   );

   const [loading, setLoading] = useState(true);
   const rows = vouchers.arr;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("vouchers", session.user.token); // Replace with actual table name and token
               dispatch(setVouchers({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load vouchers data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch]);

   const handleEdit = (id: number) => {
      const editRow = vouchers.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: "Edit Voucher",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "vouchers",
            },
         })
      );
   };

   const handleView = (id: number) => {
      const viewRow = vouchers.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Voucher`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "vouchers",
            },
         })
      );
   };

   const handleDelete = (id: number) => {
      // Handle delete action
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='delete'
                  size='small'
                  color='primary'
                  onClick={() => handleDelete(params.row.id)}
               >
                  <DeleteIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 150 },
      { field: "whatsAppNumber", headerName: "WhatsApp Number", width: 150 },
      { field: "imageUrl", headerName: "Image URL", width: 200 },
      {
         field: "createdat",
         headerName: "Created At",
         width: 180,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      {
         field: "applied",
         headerName: "Applied",
         width: 100,
         valueFormatter: (params: any) => (params.value ? "Yes" : "No"),
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={"Vouchers Grid View"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={"Vouchers Grid View"}>
         <DataGrid
            density='compact'
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
