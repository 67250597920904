export const schools = [
   { id: 1, name: "Escuela de Maia" },
   { id: 2, name: "ICP" },
   { id: 3, name: "Kids World" },
   { id: 4, name: "OIS" },
   { id: 5, name: "Pupa" },
   { id: 6, name: "San Lucas" },
   { id: 7, name: "Vamos" },
   { id: 8, name: "York" },
];
