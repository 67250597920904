import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
   GridToolbar,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Students, StudentsState } from "../../../features/students/students";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { IconButton, Tooltip, CircularProgress, Box } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import AddLinkIcon from "@mui/icons-material/AddLink";
import WhatsAppClick from "../../../components/WhatsAppClick";
import ViewSchoolClick from "../../../components/ViewSchool";
import EmailClick from "../../../components/EmailClick";
import { setStudents } from "../../../features/students/studentsSlice";
import { fetchData } from "../../../utilities/gen";
import { useEffect, useState } from "react";

export default function StudentGrid() {
   const dispatch = useAppDispatch();
   const students: StudentsState = useAppSelector(
      (state: any) => state.students
   );
   const session = useAppSelector((state: any) => state.session);

   const [loading, setLoading] = useState(true);
   const rows = students.arr;
   const EN = session.lang === "en" ? true : false;
   const USER_LEVEL = session.user_level;
   const DEMO = session.demo;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("students", session.user.token); // Replace with actual table name and token
               dispatch(setStudents({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load students data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (document: string) => {
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit Student" : "Editar Estudiante",
            content: "EditStudent",
            params: {
               EN,
               USER_LEVEL,
               DEMO,
               document,
            },
         })
      );
   };

   const handleFees = (document: string) => {
      const editRow = students.arr.filter((a: any) => a.document === document);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Fees" : "Cuotas",
            content: "Fees",
            params: {
               columnField: "student_id",
               searchTerm: document,
               title: editRow[0].name,
            },
         })
      );
   };

   const handleView = (document: string) => {
      const viewRow = students.arr.filter((a: any) => a.document === document);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View Student` : `Ver Estudiante`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "students",
            },
         })
      );
   };

   const handleLink = (document: string) => {
      const viewRow = students.arr.filter((a: any) => a.document === document);
      dispatch(
         setDialog({
            open: true,
            title: EN
               ? `Student Guardian Link`
               : `Vincular Estudiante con Tutor`,
            content: "StudentGuardianLink",
            params: {
               viewRow: viewRow[0],
            },
         })
      );
   };

   const handleAddFee = (row: Students) => {
      dispatch(
         setDialog({
            open: true,
            title: `Add Fee`,
            content: "AddFees",
            params: {
               EN,
               DEMO,
               school_id: row.school_id,
               student_id: row.document,
               student_name: row.name,
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "",
         width: 190,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <Tooltip title={EN ? `Edit` : `Editar`} arrow>
                  <IconButton
                     aria-label='edit'
                     size='small'
                     color='primary'
                     onClick={() => handleEdit(params.row.document)}
                  >
                     <EditIcon fontSize='inherit' />
                  </IconButton>
               </Tooltip>
               <Tooltip title={EN ? `View` : `Ver`} arrow>
                  <IconButton
                     size='small'
                     color='primary'
                     onClick={() => handleView(params.row.document)}
                  >
                     <RemoveRedEyeIcon fontSize='inherit' />
                  </IconButton>
               </Tooltip>
               <Tooltip
                  title={EN ? `Associated Fees` : `Tarifas asociadas`}
                  arrow
               >
                  <IconButton
                     size='small'
                     color='primary'
                     onClick={() => handleFees(params.row.document)}
                  >
                     <CurrencyExchangeIcon />
                  </IconButton>
               </Tooltip>
               <Tooltip
                  title={
                     EN ? `Add Fee to Student` : `Agregar Cuota al Estudiante`
                  }
                  arrow
               >
                  <IconButton
                     size='small'
                     color='primary'
                     onClick={() => handleAddFee(params.row)}
                  >
                     <MonetizationOnIcon />
                  </IconButton>
               </Tooltip>
               <Tooltip
                  title={
                     EN
                        ? `Student Guardian Link`
                        : `Enlace de tutor de estudiante`
                  }
                  arrow
               >
                  <IconButton
                     size='small'
                     color='primary'
                     onClick={() => handleLink(params.row.document)}
                  >
                     <AddLinkIcon />
                  </IconButton>
               </Tooltip>
            </>
         ),
      },
      {
         field: "name",
         headerName: EN ? "Name" : "Nombre",
         width: 170,
      },
      {
         field: "document",
         headerName: EN ? "Document" : "Documento",
         width: 150,
      },
      {
         field: "grade",
         headerName: EN ? "Grade" : "Grado",
         width: 70,
      },
      {
         field: "year",
         headerName: EN ? "Year" : "Año",
         width: 100,
         type: "number",
      },
      {
         field: "guardian_id",
         headerName: EN ? "Gu/ID" : "ID/dTutor",
         width: 60,
         type: "number",
      },
      {
         field: "school_id",
         headerName: EN ? "Sc/ID" : "ID/Escu",
         width: 80,
         type: "number",
         renderCell: (params) =>
            params && params.value ? (
               <ViewSchoolClick schoolId={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "monthly_fee",
         headerName: EN ? "Monthly Fee" : "Cuota Mensual",
         width: 100,
         type: "number",
      },
      {
         field: "telefono1",
         headerName: EN ? "Phone 1" : "Teléfono 1",
         width: 110,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 6 ? (
               <WhatsAppClick phoneNumber={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "telefono2",
         headerName: EN ? "Phone 2" : "Teléfono 2",
         width: 110,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 6 ? (
               <WhatsAppClick phoneNumber={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "email",
         headerName: EN ? "Email" : "Correo Electrónico",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <EmailClick email={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "comment",
         headerName: EN ? "Comment" : "Comentario",
         width: 200,
      },
      {
         field: "createdat",
         headerName: EN ? "Created At" : "Creado En",
         width: 200,
      },
      {
         field: "updatedat",
         headerName: EN ? "Updated At" : "Actualizado En",
         width: 200,
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={EN ? "Students" : "Estudiantes"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Students" : "Estudiantes"}>
         <div className='dataGridContainer'>
            <DataGrid
               getRowId={(row) => row.document}
               rows={rows}
               columns={columns}
               initialState={{
                  pagination: {
                     paginationModel: {
                        pageSize: 10,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               disableRowSelectionOnClick
               slots={{ toolbar: GridToolbar }}
            />
         </div>
      </DashboardTemplate>
   );
}
