import React, { useEffect, useRef, useState } from "react";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import LoadBox from "./LoadBox";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { fetchData, msg } from "../../../utilities/gen";
import { clearFees, setFees } from "../../../features/fees/feesSlice";
import {
   clearSchools,
   setSchools,
} from "../../../features/schools/schoolsSlice";
import {
   clearOtherPayments,
   setOtherPayments,
} from "../../../features/otherPayments/otherPaymentsSlice";
import {
   clearPayments,
   setPayments,
} from "../../../features/payments/paymentsSlice";
import {
   clearStudents,
   setStudents,
} from "../../../features/students/studentsSlice";
import {
   clearUserSchoolLinkState,
   setUserSchoolLinkState,
} from "../../../features/user_school_links/userSchoolLinkSlice";
import { setUserStudentLinkState } from "../../../features/user_student_links/userStudentLinkSlice";
import { clearUsers, setUsers } from "../../../features/users/usersSlice";
import { clearLogs, setLogs } from "../../../features/logs/logsSlice";

import {
   VIEW_FEES,
   VIEW_PAYMENTS,
   VIEW_SCHOOLS,
   VIEW_STUDENTS,
   VIEW_USERS,
   VIEW_USER_SCHOOL_LINKS,
   VIEW_USER_STUDENT_LINKS,
} from "../../../constants/security";
import { Button } from "@mui/material";

const Success = React.memo(() => <CheckCircleIcon color='success' />);
const Loading = React.memo(() => (
   <>
      <HourglassTopIcon color='info' /> Loading from Database
   </>
));

const Dashboard = React.memo(() => {
   const dispatch = useAppDispatch();

   // Call useAppSelector hooks at the top level
   const token = useAppSelector((state) => state.session.user.token);
   const EN = useAppSelector((state) => state.session.lang === "en");
   const USER_LEVEL = useAppSelector((state) => state.session.user.ul) || 10;

   const feesState = useAppSelector((state) => state.fees);
   const logsState = useAppSelector((state) => state.logs);
   const otherPaymentsState = useAppSelector((state) => state.otherPayments);
   const paymentsState = useAppSelector((state) => state.payments);
   const schoolsState = useAppSelector((state) => state.schools);
   const studentsState = useAppSelector((state) => state.students);
   const usersState = useAppSelector((state) => state.users);
   const userSchoolLinksState = useAppSelector(
      (state) => state.userSchoolLinks
   );
   const userStudentLinksState = useAppSelector(
      (state) => state.userStudentLinks
   );

   // Initialize refs at the top level
   const feesInitRef = useRef<boolean>(true);
   const logsInitRef = useRef<boolean>(true);
   const otherPaymentsInitRef = useRef<boolean>(true);
   const paymentsInitRef = useRef<boolean>(true);
   const schoolsInitRef = useRef<boolean>(true);
   const studentsInitRef = useRef<boolean>(true);
   const usersInitRef = useRef<boolean>(true);
   const userSchoolLinksInitRef = useRef<boolean>(true);
   const userStudentLinksInitRef = useRef<boolean>(false); // Initially set to false to trigger load

   const [entityStates, setEntityStates] = useState<any>({});

   const entities = React.useMemo(
      () => [
         {
            name: "fees",
            state: feesState,
            setFn: setFees,
            clearFn: clearFees,
            initRef: feesInitRef,
            accessLevel: VIEW_FEES,
         },
         {
            name: "logs",
            state: logsState,
            setFn: setLogs,
            clearFn: clearLogs,
            initRef: logsInitRef,
            accessLevel: 3,
         },
         {
            name: "other_payments",
            state: otherPaymentsState,
            setFn: setOtherPayments,
            clearFn: clearOtherPayments,
            initRef: otherPaymentsInitRef,
            accessLevel: 1,
         },
         {
            name: "payments",
            state: paymentsState,
            setFn: setPayments,
            clearFn: clearPayments,
            initRef: paymentsInitRef,
            accessLevel: VIEW_PAYMENTS,
         },
         {
            name: "schools",
            state: schoolsState,
            setFn: setSchools,
            clearFn: clearSchools,
            initRef: schoolsInitRef,
            accessLevel: VIEW_SCHOOLS,
         },
         {
            name: "students",
            state: studentsState,
            setFn: setStudents,
            clearFn: clearStudents,
            initRef: studentsInitRef,
            accessLevel: VIEW_STUDENTS,
         },
         {
            name: "users",
            state: usersState,
            setFn: setUsers,
            clearFn: clearUsers,
            initRef: usersInitRef,
            accessLevel: VIEW_USERS,
         },
         {
            name: "user_school_links",
            state: userSchoolLinksState,
            setFn: setUserSchoolLinkState,
            clearFn: clearUserSchoolLinkState,
            initRef: userSchoolLinksInitRef,
            accessLevel: VIEW_USER_SCHOOL_LINKS,
         },
         {
            name: "user_student_links",
            state: userStudentLinksState,
            setFn: setUserStudentLinkState,
            clearFn: clearUserSchoolLinkState,
            initRef: userStudentLinksInitRef,
            accessLevel: VIEW_USER_STUDENT_LINKS,
         },
      ],
      [
         feesState,
         logsState,
         otherPaymentsState,
         paymentsState,
         schoolsState,
         studentsState,
         usersState,
         userSchoolLinksState,
         userStudentLinksState,
         feesInitRef,
         logsInitRef,
         otherPaymentsInitRef,
         paymentsInitRef,
         schoolsInitRef,
         studentsInitRef,
         usersInitRef,
         userSchoolLinksInitRef,
         userStudentLinksInitRef,
      ]
   );

   const handleFunction = React.useCallback(
      (tableName: string, clearFn: any, setFn: any, initRef: any) => {
         initRef.current = false;
         if (clearFn) dispatch(clearFn());
         setEntityStates((prevState: any) => ({
            ...prevState,
            [tableName]: <Loading />,
         }));

         fetchData(tableName, token)
            .then((data) => {
               if (tableName === "users")
                  data.sort((a: any, b: any) => b.id - a.id);
               dispatch(setFn({ arr: data, init: true }));
               setEntityStates((prevState: any) => ({
                  ...prevState,
                  [tableName]: <Success />,
               }));
               dispatch(addSnackbarMsg(msg(`${tableName} Loaded`, "success")));
            })
            .catch((error) => {
               console.error(error);
               dispatch(
                  addSnackbarMsg(msg(`Error Loading ${tableName}`, "error"))
               );
            });
      },
      [dispatch, token]
   );

   const loadAll = React.useCallback(() => {
      entities.forEach((entity: any) => {
         entity.initRef.current = false;
         handleFunction(
            entity.name,
            entity.clearFn,
            entity.setFn,
            entity.initRef
         );
      });
   }, [entities, handleFunction]);

   useEffect(() => {
      const initialEntityStates: any = {};
      entities.forEach((entity: any) => {
         initialEntityStates[entity.name] = entity.state.init ? (
            <Success />
         ) : (
            <Loading />
         );
      });
      setEntityStates(initialEntityStates);

      // Load userStudentLinks and schools by default when the component mounts
      const entitiesToLoad = ["user_student_links", "schools"];
      entitiesToLoad.forEach((entityName) => {
         const entity = entities.find((e: any) => e.name === entityName);
         if (entity && !entity.state.init) {
            handleFunction(
               entity.name,
               entity.clearFn,
               entity.setFn,
               entity.initRef
            );
         }
      });
   }, [entities, handleFunction]);
   return (
      <DashboardTemplate>
         <Button onClick={loadAll} variant='outlined' sx={{ mb: 1 }}>
            {EN ? "Load/Refresh All Data" : "Cargar/Actualizar todos los datos"}
         </Button>
         {entities.map((entity: any, index: any) => (
            <LoadBox
               key={index}
               objArr={entity.state.arr}
               msg={entityStates[entity.name]}
               init={entity.state.init}
               tableName={entity.name}
               EN={EN}
               handleFunction={() =>
                  handleFunction(
                     entity.name,
                     entity.clearFn,
                     entity.setFn,
                     entity.initRef
                  )
               }
            />
         ))}
      </DashboardTemplate>
   );
});

export default Dashboard;
