import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridToolbar,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { User, UsersState } from "../../../features/users/users";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
   CircularProgress,
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Tooltip,
   Box,
} from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import WhatsAppClick from "../../../components/WhatsAppClick";
import { msg, updateUserByID } from "../../../utilities/gen";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { setUsers } from "../../../features/users/usersSlice";
import { useState, useEffect } from "react";
import EmailClick from "../../../components/EmailClick";
import ViewSchoolClick from "../../../components/ViewSchool";
import { fetchData } from "../../../utilities/gen";

const formatDate = (dateString: number | string) => {
   return dateString;
};

// set this up based on the object types in usersState

export default function UsersGrid() {
   const dispatch = useAppDispatch();
   const users: UsersState = useAppSelector((state: any) => state.users);
   const session = useAppSelector((state: any) => state.session);

   const [filter, setFilter] = useState("guardians");
   const [filteredRows, setFilteredRows] = useState<User[]>([]);
   const [loading, setLoading] = useState(true);

   const EN = session.lang === "en" ? true : false;
   const DEMO = session.demo;
   const token = session.user.token;
   const USER_LEVEL = session.user.ul;

   useEffect(() => {
      const loadData = async () => {
         if (users.arr.length === 0) {
            try {
               const data = await fetchData("users", token); // Replace with actual table name and token
               dispatch(setUsers({ arr: data, init: true }));
               setFilteredRows(applyFilter(filter, data));
            } catch (error) {
               console.error("Failed to load users data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setFilteredRows(applyFilter(filter, users.arr));
            setLoading(false);
         }
      };

      loadData();
   }, [filter, users.arr, dispatch, token]);

   const applyFilter = (filter: string, rows: User[]) => {
      switch (filter) {
         case "viewAll":
            return rows;
         case "notActive":
            return rows.filter((row) => !row.is_active);
         case "super":
            return rows.filter((row) => row.user_level === 1);
         case "countryAdmins":
            return rows.filter((row) => row.user_level === 3);
         case "schoolAdmins":
            return rows.filter((row) => row.user_level === 4);
         case "guardians":
            return rows.filter((row) => row.user_level >= 10);
         default:
            return rows;
      }
   };

   const handleEnableToggle = async (row: User) => {
      try {
         const message = EN
            ? `Are you sure you want to ${
                 row.is_active ? "disable" : "enable"
              } this user?`
            : `¿Estás seguro de que deseas ${
                 row.is_active ? "deshabilitar" : "habilitar"
              } este usuario?`;
         if (window.confirm(message)) {
            const updatedArr = users.arr.map((user) =>
               user.id === row.id
                  ? { ...user, is_active: !user.is_active }
                  : user
            );
            dispatch(setUsers({ ...users, arr: updatedArr }));

            // Call the API to update
            await updateUserByID("is_active", !row.is_active, token, row.id);
            setFilteredRows(applyFilter(filter, updatedArr));
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? "Active status updated for: " + row.name1
                        : "Estado activo actualizado para: " + row.name1,
                     "success"
                  )
               )
            );
         }
      } catch (err) {
         console.log(err);
         dispatch(
            addSnackbarMsg(
               msg(EN ? "Update Failed: " : "Actualización fallida", "error")
            )
         );
      }
   };

   const handleFilterChange = (event: any) => {
      const value = event.target.value;
      setFilter(value);
      setFilteredRows(applyFilter(value, users.arr));
   };

   const handleEdit = (id: string) => {
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit User" : "Editar Tutor",
            content: "EditUser",
            params: {
               EN,
               USER_LEVEL,
               DEMO,
               id,
            },
         })
      );
   };

   const handleView = (id: string) => {
      const viewRow = users.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View Users` : "Ver Tutores",
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "users",
            },
         })
      );
   };

   const getUserLevelConfig = (level: any, EN: any) => {
      switch (level) {
         case 1:
            return {
               color: "success",
               tooltip: EN ? "Super User" : "Súper Usuario",
            };
         case 3:
            return {
               color: "warning",
               tooltip: EN ? "Country Admin" : "Administrador de País",
            };
         case 4:
            return {
               color: "secondary",
               tooltip: EN ? "School Admin" : "Administrador de Escuela",
            };
         default:
            return { color: "primary", tooltip: EN ? "Guardian" : "Guardián" };
      }
   };

   const UserLevelCell = ({ value, EN }: { value: any; EN: boolean }) => {
      const { color, tooltip } = getUserLevelConfig(value, EN);
      return (
         <span>
            <Tooltip title={tooltip}>
               <AccountCircleIcon
                  color={
                     color as
                        | "inherit"
                        | "primary"
                        | "secondary"
                        | "action"
                        | "error"
                        | "disabled"
                        | "info"
                        | "success"
                        | "warning"
                  }
               />
            </Tooltip>
         </span>
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 110,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => {
            const isDisabled =
               params.row.user_level === 1 ||
               params.row.user_level <= session.user.ul;

            return (
               <>
                  <IconButton
                     aria-label='edit'
                     size='small'
                     color='primary'
                     onClick={() => handleEdit(params.row.id)}
                     disabled={isDisabled}
                  >
                     <EditIcon fontSize='inherit' />
                  </IconButton>
                  <IconButton
                     aria-label='view'
                     size='small'
                     color='primary'
                     onClick={() => handleView(params.row.id)}
                  >
                     <RemoveRedEyeIcon fontSize='inherit' />
                  </IconButton>
                  {params.row.is_active === false ? (
                     <Tooltip title={EN ? "Enable User" : "Habilitar usuario"}>
                        <span>
                           <IconButton
                              aria-label='enable'
                              size='small'
                              color='primary'
                              onClick={() => handleEnableToggle(params.row)}
                              disabled={isDisabled}
                           >
                              <DoneIcon fontSize='inherit' />
                           </IconButton>
                        </span>
                     </Tooltip>
                  ) : (
                     <Tooltip
                        title={EN ? "Disable User" : "Deshabilitar usuario"}
                     >
                        <span>
                           <IconButton
                              aria-label='disable'
                              size='small'
                              color='secondary'
                              onClick={() => handleEnableToggle(params.row)}
                              disabled={isDisabled}
                           >
                              <CloseIcon fontSize='inherit' />
                           </IconButton>
                        </span>
                     </Tooltip>
                  )}
               </>
            );
         },
      },
      {
         field: "user_level",
         headerName: "LV",
         width: 30,
         renderCell: (params) => <UserLevelCell value={params.value} EN={EN} />,
      },
      {
         field: "id",
         headerName: "ID",
         width: 60,
      },
      {
         field: "school_id",
         headerName: EN ? "School#" : "Escuela#",
         width: 60,
         renderCell: (params) =>
            params && params.value && parseInt(params.value) > 0 ? (
               <ViewSchoolClick schoolId={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "name1",
         headerName: EN ? "Name1" : "Nombre1",
         width: 120,
      },
      {
         field: "name2",
         headerName: EN ? "Name2" : "Nombre2",
         width: 100,
      },
      {
         field: "telefono1",
         headerName: EN ? "Phone 1" : "Teléfono 1",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 6 ? (
               <WhatsAppClick phoneNumber={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "telefono2",
         headerName: EN ? "Phone 2" : "Teléfono 2",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <WhatsAppClick phoneNumber={params.value} />
            ) : (
               ""
            ),
      }, // Assuming telefono2 is a secondary phone number
      {
         field: "email1",
         headerName: EN ? "Email1" : "Correo1",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <EmailClick email={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "email2",
         headerName: EN ? "Email2" : "Correo2",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <EmailClick email={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "createdat",
         headerName: EN ? "Created At" : "Creado En",
         width: 180,
      },
      {
         field: "updatedat",
         headerName: EN ? "Updated At" : "Actualizado En",
         width: 180,
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={EN ? "Users" : "Usuarios"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Users" : "Usuarios"}>
         <FormControl
            variant='outlined'
            style={{ marginBottom: "20px", minWidth: 200 }}
         >
            <InputLabel>{EN ? "Filter Users" : "Filtrar Usuarios"}</InputLabel>
            <Select
               value={filter}
               onChange={handleFilterChange}
               label={EN ? "Filter Users" : "Filtrar Usuarios"}
            >
               {session.user.ul < 10 && (
                  <MenuItem value='notActive'>
                     {EN ? "View All Not Active" : "Ver Todos No Activos"}
                  </MenuItem>
               )}
               {session.user.ul === 1 && (
                  <MenuItem value='super'>
                     {EN ? "Super Admins" : "Súper Administradores"}
                  </MenuItem>
               )}
               {session.user.ul < 4 && (
                  <MenuItem value='countryAdmins'>
                     {EN ? "Country Admins" : "Administradores de País"}
                  </MenuItem>
               )}
               {session.user.ul < 4 && (
                  <MenuItem value='schoolAdmins'>
                     {EN ? "School Admins" : "Administradores de Escuela"}
                  </MenuItem>
               )}
               {session.user.ul < 10 && (
                  <MenuItem value='guardians'>
                     {EN ? "Guardians" : "Guardianes"}
                  </MenuItem>
               )}
            </Select>
         </FormControl>
         <div
            className='dataGridContainer'
            id={"j" + new Date().getMilliseconds}
         >
            <DataGrid
               key={"i" + new Date().getMilliseconds()}
               slots={{ toolbar: GridToolbar }}
               getRowId={(row) => row.id}
               rows={filteredRows}
               columns={columns}
               initialState={{
                  pagination: {
                     paginationModel: {
                        pageSize: 10,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               disableRowSelectionOnClick
               getRowClassName={(params) =>
                  params.row.is_active === false ? "inactiveRow" : ""
               }
            />
         </div>
      </DashboardTemplate>
   );
}
