import React, { Fragment } from "react";
import { useSnackbar } from "notistack";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SnackbarState } from "../../features/snackbar/snackbar";
import { setPrev } from "../../features/snackbar/snackbarSlice";

const Messages = () => {
   const snackbar: SnackbarState = useAppSelector((state) => state.snackbar);
   const { enqueueSnackbar } = useSnackbar();
   const dispatch = useAppDispatch();

   React.useEffect(() => {
      try {
         const msg = snackbar.msg ? snackbar.msg : snackbar.severity;
         if (msg !== snackbar.prev && msg !== "" && msg !== "init") {
            enqueueSnackbar(snackbar.msg, { variant: snackbar.severity });
            setTimeout(() => dispatch(setPrev(snackbar.msg)), 200);
         } else {
            console.log("Duplicate message: ", snackbar);
         }
      } catch (e) {
         console.log(e);
      }
   }, [snackbar.msg, enqueueSnackbar]);

   return <></>;
};

export default Messages;
