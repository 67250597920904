import {
   Box,
   Button,
   Chip,
   Grid,
   LinearProgress,
   Paper,
   Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

interface LoadBoxProps {
   objArr: any[];
   msg: string | any;
   init: boolean;
   tableName: string;
   EN: boolean;
   handleFunction: any;
   // handleReload: (type: string) => void;
}

const DemoPaper = styled(Paper)(({ theme }) => ({
   width: "100%",
   padding: theme.spacing(2),
   ...theme.typography.body1,
   textAlign: "left",
   m: 1,
}));

export default function LoadBox(props: LoadBoxProps) {
   const { objArr, msg, init, tableName, EN, handleFunction } = props;

   return (
      <div style={{ marginBottom: 10 }}>
         <DemoPaper>
            {init === false && objArr.length === 0 ? (
               <Box sx={{ width: "100%" }}>
                  {EN ? "Loading " : "Descargando "} {tableName}
                  <LinearProgress />
               </Box>
            ) : (
               <>
                  <Grid container spacing={1}>
                     <Grid item sm={4}>
                        <Chip
                           label={
                              objArr.length > 0
                                 ? EN
                                    ? "Loaded"
                                    : "Cargado"
                                 : EN
                                 ? "No records"
                                 : "Sin registros"
                           }
                           color={objArr.length > 0 ? "success" : "default"}
                        />
                     </Grid>
                     <Grid item sm={4}>
                        {tableName}
                        <Typography variant='body2'>
                           {EN
                              ? ` ${objArr.length || 0} records`
                              : ` ${objArr.length || 0} registros`}
                        </Typography>
                     </Grid>
                     <Grid item sm={4}>
                        <Button
                           onClick={() => handleFunction(tableName)}
                           size='small'
                           variant='outlined'
                        >
                           {EN ? "Refresh/Download" : "Refrescar/Descargar"}
                        </Button>
                     </Grid>
                  </Grid>
               </>
            )}
         </DemoPaper>
      </div>
   );
}
