import { useEffect } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { DialogState } from "../../features/dialog/dialog";

import { SnackbarState } from "../../features/snackbar/snackbar";
import { isValidSession } from "../../utilities/validate";
import Dialog from "../../components/Dialog";
import SnackStack from "../../components/Snackbar/SnackStack";
import { SessionState } from "../../features/session/session";

const ProtectedRoute = () => {
   const navigate = useNavigate();

   const snackbar: SnackbarState = useAppSelector((state) => state.snackbar);
   const session: SessionState = useAppSelector((state) => state.session);
   const dialog: DialogState = useAppSelector((state) => state.dialog);

   const EN = session.lang === "en" ? true : false;

   useEffect(() => {
      //console.log("UE");
   }, [snackbar, dialog]);

   if (!isValidSession(session)) {
      setTimeout(() => navigate(`/`), 500);
      return (
         <div className='unauthorized'>
            <h1>{EN ? "Unauthorized" : "No autorizado"} </h1>

            <span>
               <NavLink to='/login'>
                  {EN ? "Sign In" : "Iniciar sesión"}
               </NavLink>{" "}
               {EN ? "to gain access" : "ganar acceso"}
            </span>
         </div>
      );
   }

   // returns child route elements
   return (
      <>
         <Outlet />
         <SnackStack />
         {dialog && dialog.open ? <Dialog /> : <></>}
      </>
   );
};
export default ProtectedRoute;
