import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserSchoolLinkState } from "./userSchoolLink";

const initialState: UserSchoolLinkState = {
   arr: [],
   init: true,
};

export const linksSlice = createSlice({
   name: "user_school_links",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setUserSchoolLinkState: (
         state,
         action: PayloadAction<UserSchoolLinkState>
      ) => {
         // Clear the state before setting new links
         state.arr.splice(0, state.arr.length);
         // Push each new link into the state
         action.payload.arr.forEach((link) => {
            state.arr.push(link);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleUserSchoolLink: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the link with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((link: any) => link.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleUserSchoolLink: (
         state,
         action: PayloadAction<UserSchoolLinkState>
      ) => {
         // Add a single link
         state.arr.push(action.payload.arr[0]);
      },
      clearUserSchoolLinkState: () => initialState,
   },
});

export const {
   setUserSchoolLinkState,
   updateSingleUserSchoolLink,
   addSingleUserSchoolLink,
   clearUserSchoolLinkState,
} = linksSlice.actions;
export const selectUserSchoolLink = (state: RootState) => state.userSchoolLinks;

export default linksSlice.reducer;
