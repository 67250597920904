import { useState, useEffect } from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridToolbar,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { otherPaymentsState } from "../../../features/otherPayments/otherPayments";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { setOtherPayments } from "../../../features/otherPayments/otherPaymentsSlice";
import { fetchData } from "../../../utilities/gen";

export default function OtherPaymentsGrid() {
   const dispatch = useAppDispatch();
   const otherPayments: otherPaymentsState = useAppSelector(
      (state: any) => state.otherPayments
   );
   const session = useAppSelector((state: any) => state.session);

   const [loading, setLoading] = useState(true);
   const rows = otherPayments.arr;
   const EN = session.lang === "en" ? true : false;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData(
                  "other_payments",
                  session.user.token
               ); // Replace with actual table name and token
               dispatch(setOtherPayments({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load other payments data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (id: string) => {
      const editRow = otherPayments.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit Other Payments" : "Editar Otros Pagos",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "otherPayments",
            },
         })
      );
   };

   const handleView = (id: string) => {
      const viewRow = otherPayments.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View Other Payments` : `Ver Otros Pagos`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "otherPayments",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 100,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      {
         field: "payment_id",
         headerName: EN ? "Pay/ID" : "Pagar/ID",
         width: 110,
      },
      { field: "id_appsheet", headerName: "Appsh/ID", width: 100 },
      {
         field: "payment_date",
         headerName: EN ? "Payment Date" : "Fecha de Pago",
         width: 100,
         valueFormatter: (params: any) => params.value,
      },
      {
         field: "student_id",
         headerName: EN ? "St/ID" : "Estud/ID",
         width: 150,
      },
      {
         field: "school_id",
         headerName: EN ? "Sc/ID" : "Escuela/ID",
         width: 50,
      },
      {
         field: "payment_method",
         headerName: EN ? "Payment Method" : "Método de pago",
         width: 150,
      },
      {
         field: "comment",
         headerName: EN ? "Comment" : "Comentario",
         width: 205,
      },
      {
         field: "amount_paid",
         headerName: EN ? "Amnt/Paid" : "Cantidad",
         width: 110,
      },
      { field: "concept", headerName: EN ? "Concept" : "Concepto", width: 180 },
      { field: "user_id", headerName: EN ? "Gu/ID" : "Tut/ID", width: 110 },
      {
         field: "registration_date",
         headerName: EN ? "Registration Date" : "Fecha de Registro",
         width: 180,
         valueFormatter: (params: any) => params.value,
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={EN ? "Other Payments" : "Otros pagos"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Other Payments" : "Otros pagos"}>
         <DataGrid
            slots={{ toolbar: GridToolbar }}
            getRowId={(row) => row.payment_id}
            rows={rows}
            columns={columns}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
