import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { OptionalFee, OptionalFeesState } from "./optional_fees";

// Initial state for the optional fees slice
const initialState: OptionalFeesState = {
   arr: [],
   init: true,
};

export const optional_feesSlice = createSlice({
   name: "optional_fees",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setOptionalFees: (state, action: PayloadAction<OptionalFeesState>) => {
         // Clear the state before setting new optional fees
         state.arr.splice(0, state.arr.length);
         // Push each new optional fee into the state
         action.payload.arr.forEach((fee: any) => {
            state.arr.push(fee);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleOptionalFee: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // Given that you are passed the id, updateFields[] and updateValues[]
         // Find the optional fee with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((fee: OptionalFee) => fee.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleOptionalFee: (state, action: PayloadAction<OptionalFee>) => {
         // Add a single optional fee to the state
         state.arr.push(action.payload);
      },
      clearOptionalFees: (state) => {
         console.log("Clearing optional fees");
         state.arr = []; // Clear the array
         state.init = true; // Reset other state properties if needed
      },
   },
});

// Export the actions
export const {
   setOptionalFees,
   updateSingleOptionalFee,
   clearOptionalFees,
   addSingleOptionalFee,
} = optional_feesSlice.actions;

// Selector to select the optional fees slice from the root state
export const selectOptionalFees = (state: RootState) => state.optional_fees;

// Export the reducer
export default optional_feesSlice.reducer;
