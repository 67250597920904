import { SnackbarProvider } from "notistack";
import React from "react";
import Messages from "./Messages";

const SnackStack = () => {
   return (
      <SnackbarProvider maxSnack={5}>
         <Messages />
      </SnackbarProvider>
   );
};

export default React.memo(SnackStack);
