import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
   GridToolbar, // Import the GridFilterModel type
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Fee, FeesState } from "../../../features/fees/fees";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useEffect } from "react";
import { fetchData } from "../../../utilities/gen";
import { setFees } from "../../../features/fees/feesSlice";
import {
   OptionalFee,
   OptionalFeesState,
} from "../../../features/optional_fees/optional_fees";
import { setOptionalFees } from "../../../features/optional_fees/optional_feesSlice";
import ViewSchoolClick from "../../../components/ViewSchool";

const formatDate = (dateString: number | string) => {
   return dateString;
};

interface OptionalFeesGridProps {
   params?: {
      columnField?: string;
      searchTerm?: string;
      title?: string | number;
   };
}

export default function OptionalFeesGrid(props: OptionalFeesGridProps) {
   const dispatch = useAppDispatch();
   const optional_fees: OptionalFeesState = useAppSelector(
      (state: any) => state.optional_fees
   );
   const session = useAppSelector((state: any) => state.session);
   const [loading, setLoading] = React.useState(true);

   const rows = optional_fees.arr;
   const EN = session.lang === "en" ? true : false;
   const DEMO = session.user.demo;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData(
                  "optional_fees",
                  session.user.token
               ); // Replace with actual table name and token
               dispatch(setOptionalFees({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load fees data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (id: string) => {
      const editRow = optional_fees.arr.filter((a: any) => a.id === id);
      console.log(editRow[0]);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit Optioanl Fee" : "Editar Cuota Opcional",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "optional_fees",
            },
         })
      );
      // Handle edit action
   };

   const handleOptionalFees = (row: OptionalFee) => {
      //const editRow = students.arr.filter((a: any) => a.document === document);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Fees" : "Cuotas",
            content: "Fees",
            params: {
               columnField: "id",
               searchTerm: row.id,
               //    title: EN ? row.description_en : row.description_es ,
            },
         })
      );
      // Handle edit action
   };

   const handleView = (id: string) => {
      const viewRow = optional_fees.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Optional Fees`,
            content: "ViewOptionalFees",
            params: {
               row: viewRow[0],
               tableName: "fees",
            },
         })
      );
   };
   //    const handleMakePayment = (row: Fee) => {
   //       dispatch(
   //          setDialog({
   //             open: true,
   //             title: `Make Payment`,
   //             content: "AddFeePayment",
   //             params: {
   //                DEMO,
   //                EN,
   //                row,
   //             },
   //          })
   //       );
   //    };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "",
         width: 90,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <Tooltip title={EN ? "Edit" : "Editar"} arrow>
                  <IconButton
                     aria-label='edit'
                     size='small'
                     color='primary'
                     onClick={() => handleEdit(params.row.id)}
                  >
                     <EditIcon fontSize='inherit' />
                  </IconButton>
               </Tooltip>
               <Tooltip title={EN ? "View" : "Ver"} arrow>
                  <IconButton
                     aria-label='view'
                     size='small'
                     color='primary'
                     onClick={() => handleView(params.row.id)}
                  >
                     <RemoveRedEyeIcon fontSize='inherit' />
                  </IconButton>
               </Tooltip>
            </>
         ),
      },
      { field: "id", headerName: "ID", width: 60 }, // Primary key
      {
         field: "school_id",
         headerName: EN ? "Sc/ID" : "ID Escuela",
         width: 80,
         renderCell: (params) =>
            params && params.value ? (
               <ViewSchoolClick schoolId={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "description_en",
         headerName: EN ? "Description (EN)" : "Descripción (EN)",
         width: 200,
      },
      {
         field: "description_es",
         headerName: EN ? "Description (ES)" : "Descripción (ES)",
         width: 200,
      },
      {
         field: "amount",
         headerName: EN ? "Amount" : "Monto",
         width: 100,
         renderCell: (params: GridRenderCellParams) => (
            <div
               style={{
                  backgroundColor:
                     parseFloat(params.value) > 0
                        ? "rgba(0, 255, 0, 0.3)"
                        : "inherit",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               {params.value}
            </div>
         ),
      },
      {
         field: "penalty",
         headerName: EN ? "Penalty" : "Penalidad",
         width: 100,
         renderCell: (params: GridRenderCellParams) => (
            <div
               style={{
                  backgroundColor:
                     parseFloat(params.value) > 0
                        ? "rgba(255, 0, 0, 0.3)"
                        : "inherit",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               {params.value}
            </div>
         ),
      },
      {
         field: "is_active",
         headerName: EN ? "Active" : "Activo",
         width: 80,
         valueFormatter: (params: any) =>
            params.value === false ? "No" : "Yes",
      },
      {
         field: "creator_id",
         headerName: EN ? "Cr/ID" : "ID/Cr",
         width: 80,
      },
      {
         field: "demo",
         headerName: EN ? "Demo" : "Demostración",
         width: 80,
         valueFormatter: (params: any) =>
            params.value === false ? "No" : "Yes",
      },
   ];
   if (loading) {
      return (
         <DashboardTemplate
            title={
               EN ? "Optional Fees Grid View" : "Vista de cuadrícula de tarifas"
            }
         >
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }
   // React.useEffect(() => {
   //    // reload if fees state changes
   // }, [fees.arr, session.density]);

   try {
      return props.params?.columnField && props.params?.searchTerm ? (
         <div style={{ padding: 5 }}>
            ID{props.params.searchTerm}
            {props.params.title ? " " + props.params.title : ""}
            <DataGrid
               slots={{ toolbar: GridToolbar }}
               getRowId={(row) => row.id}
               rows={rows}
               columns={columns}
               initialState={{
                  filter: {
                     filterModel: {
                        items: [
                           {
                              field: props.params.columnField,
                              operator: "equals",
                              value: props.params.searchTerm,
                           },
                        ],
                     },
                  },
                  pagination: {
                     paginationModel: {
                        pageSize: 10,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               getRowClassName={(params) =>
                  parseFloat(params.row.total_due) === 0 ? "paidRow" : ""
               }
            />
         </div>
      ) : (
         <DashboardTemplate
            title={
               EN ? "Optional Fees Grid View" : "Vista de cuadrícula de tarifas"
            }
         >
            <DataGrid
               slots={{ toolbar: GridToolbar }}
               getRowId={(row) => row.id}
               rows={rows}
               columns={columns}
               initialState={{
                  pagination: {
                     paginationModel: {
                        pageSize: 15,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               getRowClassName={(params) =>
                  parseFloat(params.row.total_due) === 0 ? "paidRow" : ""
               }
            />
         </DashboardTemplate>
      );
   } catch (error) {
      console.error("An error occurred:", error);
      return (
         <div>
            <h2>Error</h2>
            <p>
               Something went wrong while rendering the data grid. Please try
               again later.
            </p>
         </div>
      );
   }
}
