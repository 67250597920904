import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Payments, PaymentsState } from "./payments";

const initialState: PaymentsState = {
   arr: [],
   init: true,
};

export const paymentsSlice = createSlice({
   name: "payments",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setPayments: (state, action: PayloadAction<PaymentsState>) => {
         // Clear the state before setting new payments
         state.arr.splice(0, state.arr.length);
         // Push each new payment into the state
         action.payload.arr.forEach((payment) => {
            state.arr.push(payment);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSinglePayment: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the payment with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (payment: Payments) => payment.payment_id === id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSinglePayment: (state, action: PayloadAction<Payments>) => {
         // Add a single payment to the state
         state.arr.push(action.payload);
      },
      clearPayments: (state) => initialState,
   },
});

export const {
   setPayments,
   updateSinglePayment,
   clearPayments,
   addSinglePayment,
} = paymentsSlice.actions;
export const selectPayments = (state: RootState) => state.payments;

export default paymentsSlice.reducer;
