import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridToolbar,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { SchoolsState } from "../../../features/schools/schools";
import EmailClick from "../../../components/EmailClick";
import WhatsAppClick from "../../../components/WhatsAppClick";
import { setSchools } from "../../../features/schools/schoolsSlice";
import { fetchData } from "../../../utilities/gen";

export default function SchoolsGrid() {
   const dispatch = useAppDispatch();
   const schools: SchoolsState = useAppSelector((state: any) => state.schools);
   const session = useAppSelector((state: any) => state.session);

   const [loading, setLoading] = useState(true);
   const rows = schools.arr;
   const EN = session.lang === "en" ? true : false;
   const USER_LEVEL = session.user_level;
   const DEMO = session.demo;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("schools", session.user.token); // Replace with actual table name and token
               dispatch(setSchools({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load schools data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (id: string) => {
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit School" : "Editar Escuela",
            content: "EditSchool",
            params: {
               EN,
               USER_LEVEL,
               DEMO,
               id,
            },
         })
      );
   };

   const handleView = (id: number) => {
      const viewRow = schools.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View School` : `Ver Escuela`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "schools",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      {
         field: "id",
         headerName: "ID",
         width: 30,
      },
      {
         field: "name",
         headerName: EN ? "Name" : "Nombre",
         width: 200,
      },
      {
         field: "phone",
         headerName: EN ? "Phone" : "Teléfono",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <WhatsAppClick phoneNumber={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "email",
         headerName: EN ? "Email" : "Correo Electrónico",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <EmailClick email={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "country_code",
         headerName: EN ? "CC" : "País",
         width: 50,
      },
      {
         field: "bank_account",
         headerName: EN ? "Bank Account" : "Cuenta Bancaria",
         width: 100,
      },
      {
         field: "bank_name",
         headerName: EN ? "Bank Name" : "Nombre del Banco",
         width: 100,
      },
      {
         field: "contact",
         headerName: EN ? "Contact" : "Contacto",
         width: 200,
      },
      {
         field: "address",
         headerName: EN ? "Address" : "Dirección",
         width: 200,
      },
      {
         field: "product_rates",
         headerName: EN ? "Product Rates" : "Tarifas de Productos",
         width: 200,
      },
      {
         field: "createdat",
         headerName: EN ? "Created At" : "Creado En",
         width: 200,
      },
      {
         field: "updatedat",
         headerName: EN ? "Updated At" : "Actualizado En",
         width: 200,
      },
      {
         field: "demo",
         headerName: EN ? "Demo" : "Demostración",
         width: 100,
         type: "boolean",
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={EN ? "Schools" : "Escuelas"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Schools" : "Escuelas"}>
         <div className='dataGridContainer'>
            <DataGrid
               slots={{ toolbar: GridToolbar }}
               rows={rows}
               getRowId={(row) => row.id}
               columns={columns}
               initialState={{
                  pagination: {
                     paginationModel: {
                        pageSize: 15,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               disableRowSelectionOnClick
            />
         </div>
      </DashboardTemplate>
   );
}
