import Box from "@mui/material/Box";
import React, { useRef } from "react";

import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

import { setDialog } from "../../features/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { dia, msg, rand, returnDateNow } from "../../utilities/gen";
import { SessionState } from "../../features/session/session";
import { API_URL } from "../../constants/api";
import { apiPost } from "../../utilities/ApiRequests";
import { RootState } from "../../app/store";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { updateSingleFee } from "../../features/fees/feesSlice";
import { updateSingleSchool } from "../../features/schools/schoolsSlice";
import { updateSingleUser } from "../../features/users/usersSlice";

interface UseEditProps {
   params: {
      row: any;
      additionalDisabled?: string[];
      tableName: string;
      limit?: number;
   };
}

export default function DialogEditOne(props: UseEditProps): JSX.Element {
   const {
      row,
      additionalDisabled = [],
      tableName = "",
      limit = 1,
   } = props.params;
   const disabled = ["id", "created_at", "updated_at", ...additionalDisabled];
   const [loading, setLoading] = React.useState(false);
   const dispatch = useAppDispatch();

   const init = useRef<boolean>(false);
   const session: SessionState = useAppSelector(
      (state: { session: any }) => state.session
   );

   const token = session.user.token;
   const editState = useAppSelector((state: RootState) => {
      switch (tableName) {
         case "schools":
            return state.schools;
         case "users":
            return state.users;
         case "students":
            return state.students;
         default:
            // Handle default case if needed
            return null; // or any other default value
      }
   });

   const handleEdit = async (e: any) => {
      try {
         e.preventDefault();
         setLoading(true);
         // edit the actually database using api
         // iterate through the input fields and update the fields
         const updateFields = [];
         const updateValues = [];
         for (const property in row) {
            if (
               property !== "id" &&
               property.toString().toLocaleLowerCase() !== "createdat"
            ) {
               if (property.toString().toLocaleLowerCase() === "updatedat") {
                  updateFields.push(property);
                  // push date in the following format 2024-02-25 15:52:12
                  updateValues.push(returnDateNow());
               } else {
                  updateFields.push(property);
                  updateValues.push(
                     (document.getElementById(property) as HTMLInputElement)
                        ?.value
                  );
               }
            }
         }
         console.log("pre apiPost");
         const res = await apiPost(API_URL + "/crud/updatebyid", {
            token,
            tableName,
            updateFields,
            updateValues,
            id: [row.id],
         });
         console.log(res);
         //now update the state depending on the table name
         switch (tableName) {
            case "schools":
               dispatch(
                  updateSingleSchool({
                     id: row.id,
                     updateFields,
                     updateValues,
                  })
               );
               break;

            case "users":
               dispatch(
                  updateSingleUser({
                     id: row.id,
                     updateFields,
                     updateValues,
                  })
               );
               break;

            default:
               // Handle default case if needed
               break;
         }

         setTimeout(() => {
            setLoading(false);
            dispatch(setDialog(dia(false, "", "", {})));
         }, 3000);
      } catch (error) {
         console.log(error);
         dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   return (
      <Container component='main' maxWidth='lg' sx={{ padding: 3 }}>
         <div style={{ padding: 5 }} />
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <form>
               <Box sx={{ width: "100%", typography: "body1" }}>
                  <TableContainer>
                     <Table sx={{ padding: 1 }}>
                        <TableBody>
                           {row &&
                              typeof row === "object" &&
                              Object.keys(row).length > 0 &&
                              Object.keys(row).map((paramName) => {
                                 const paramValue = row[paramName];

                                 return (
                                    <TableRow hover tabIndex={-1} key={rand()}>
                                       <TableCell>
                                          {paramName
                                             .toString()
                                             .replaceAll("_", " ")
                                             .toUpperCase()}
                                       </TableCell>
                                       <TableCell>
                                          {disabled.includes(paramName) ||
                                          paramName === "id" ? (
                                             <TextField
                                                size='small'
                                                defaultValue={paramValue}
                                                id={paramName}
                                                label={paramName}
                                                variant='outlined'
                                                disabled
                                             />
                                          ) : (
                                             <TextField
                                                size='small'
                                                defaultValue={paramValue}
                                                id={paramName}
                                                label={paramName}
                                                variant='outlined'
                                             />
                                          )}
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           <TableRow>
                              <TableCell colSpan={2}>
                                 <Button
                                    onClick={(event) => handleEdit(event)}
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    sx={{
                                       mt: 1,
                                       mb: 1,
                                       height: 50,
                                    }}
                                    disabled={loading}
                                 >
                                    Edit
                                 </Button>
                                 {loading && (
                                    <CircularProgress
                                       size={24}
                                       sx={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          marginTop: "-12px",
                                          marginLeft: "-12px",
                                       }}
                                    />
                                 )}
                              </TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Box>
            </form>
         </Paper>
      </Container>
   );
}
