import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

interface SnackbarState {
   msg: string;
   severity: string;
   prev: string;
}

const initialState: SnackbarState = {
   msg: "init",
   severity: "info",
   prev: "", // Track the previous message
};

export const snackbarSlice = createSlice({
   name: "snackbar",
   initialState,
   reducers: {
      addSnackbarMsg: (
         state,
         action: PayloadAction<{ msg: string; severity: string }>
      ) => {
         const { msg, severity } = action.payload;
         // console.log("addSnackbarMsg: ", msg, severity);

         // Check if the new message is the same as the previous one
         if (
            state.msg !== msg &&
            state.prev !== msg &&
            msg !== "" &&
            msg !== "init"
         ) {
            state.prev = state.msg; // Update prev with the current message before changing it
            state.msg = msg;
            state.severity = severity;
         }
      },
      removeSnackbarMsg: (state) => {
         // Logic to remove snackbar message if needed
      },
      setPrev: (state, action: PayloadAction<string>) => {
         state.prev = action.payload;
      },
      clearSnackbar: () => initialState,
   },
});

export const { addSnackbarMsg, removeSnackbarMsg, clearSnackbar, setPrev } =
   snackbarSlice.actions;

export const selectSnackbar = (state: RootState) => state.snackbar;

export default snackbarSlice.reducer;
