// create a form with disabled number field as that will be brough in from the parent component
// also a a textarea field for the message
// all with MUI components

import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setDialog } from "../../features/dialog/dialogSlice";
import { SessionState } from "../../features/session/session";

interface EmailMsgProps {
   params: {
      email: string | number;
   };
}

export default function EmailMsg(props: EmailMsgProps): JSX.Element {
   const dispatch = useAppDispatch();
   const session: SessionState = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en" ? true : false;
   const { email } = props.params;
   const [message, setMessage] = useState("");
   const viewEmail = email.toString().trim();

   const handleClick = () => {
      dispatch(
         setDialog({
            open: true,
            title: `Email`,
            content: "Email",
            params: {
               email,
               message,
            },
         })
      );
   };

   return (
      <div style={{ padding: 20 }}>
         <form>
            <TextField
               label={EN ? "Email Address" : "Correo electrónico"}
               variant='outlined'
               fullWidth
               disabled
               value={viewEmail}
            />
            <TextField
               label={EN ? "Message" : "Mensaje"}
               variant='outlined'
               fullWidth
               multiline
               rows={4}
               sx={{ mt: 2 }}
               value={message}
               onChange={(e) => setMessage(e.target.value)}
            />
            <Button
               onClick={handleClick}
               variant='contained'
               color='primary'
               fullWidth
               sx={{ mt: 2 }}
            >
               {EN ? "Send" : "Enviar"}
            </Button>
         </form>
      </div>
   );
}
