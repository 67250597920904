import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { UserStudentLink, UserStudentLinkState } from "./userStudentLink";

const initialState: UserStudentLinkState = {
   arr: [],
   init: false,
};

export const linksSlice = createSlice({
   name: "user_student_links",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setUserStudentLinkState: (
         state,
         action: PayloadAction<UserStudentLinkState>
      ) => {
         // Clear the state before setting new links
         state.arr.splice(0, state.arr.length);
         // Push each new link into the state
         action.payload.arr.forEach((link) => {
            state.arr.push(link);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleUserStudentLink: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the link with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((link: any) => link.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleUserStudentLink: (
         state,
         action: PayloadAction<UserStudentLink>
      ) => {
         // Add a single link
         state.arr.push(action.payload);
      },
      removeSingleUserStudentLink: (
         state,
         action: PayloadAction<UserStudentLink>
      ) => {
         // Remove a single link
         const index = state.arr.findIndex(
            (link: UserStudentLink) =>
               link.user_id === action.payload.user_id &&
               link.student_id === action.payload.student_id
         );
         if (index !== -1) {
            state.arr.splice(index, 1);
         }
      },
      clearUserStudentLinkState: (state) => {
         // Clear the state
         state.arr.splice(0, state.arr.length);
         state.init = false;
      },
   },
});

export const {
   setUserStudentLinkState,
   updateSingleUserStudentLink,
   addSingleUserStudentLink,
   clearUserStudentLinkState,
   removeSingleUserStudentLink,
} = linksSlice.actions;
export const selectUserStudentLink = (state: RootState) =>
   state.userStudentLinks;

export default linksSlice.reducer;
