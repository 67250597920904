import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
   Table,
   TableBody,
   TableCell,
   TableHead,
   TableRow,
   Paper,
   Container,
   Typography,
   Box,
   Autocomplete,
   Button,
   TextField,
   Tooltip,
} from "@mui/material";
import { Students } from "../../features/students/students";
import WhatsAppClick from "../WhatsAppClick";
import DeleteIcon from "@mui/icons-material/Delete";
import EmailClick from "../EmailClick";
import { User } from "../../features/users/users";
import { apiPost } from "../../utilities/ApiRequests";
import { API_URL } from "../../constants/api";
import {
   addSingleUserStudentLink,
   removeSingleUserStudentLink,
} from "../../features/user_student_links/userStudentLinkSlice";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { msg } from "../../utilities/gen";

interface UserStudentLink {
   user_id: number;
   student_id: string;
}

interface StudentGuardianLinkProps {
   params: {
      viewRow: Students;
   };
}

const StudentGuardianLink: React.FC<StudentGuardianLinkProps> = (
   props: StudentGuardianLinkProps
) => {
   const { viewRow } = props.params;

   // Select the userStudentLinks and users slices from the state
   const session = useSelector((state: any) => state.session);
   const userStudentLinks = useSelector(
      (state: any) => state.userStudentLinks.arr
   );
   const dispatch = useDispatch();
   const users = useSelector((state: any) => state.users);
   const EN = session.lang === "en" ? true : false;

   const DEMO = session.user.demo;
   const [selectedGuardian, setSelectedGuardian] = useState<User | null>(null);

   const guardianOptions = useMemo(() => {
      return users.arr
         .filter((user: User) => user.user_level === 10)
         .sort((a: User, b: User) => {
            if (a.name1.toLowerCase() < b.name1.toLowerCase()) return -1;
            if (a.name1.toLowerCase() > b.name1.toLowerCase()) return 1;
            return 0;
         });
   }, [users]);

   const handleDelete = async (user_id: number, student_id: string) => {
      const message = EN
         ? `Delete Guardian Student Link?`
         : `eliminar el enlace de tutor y estudiante?`;
      if (window.confirm(message)) {
         try {
            const res = await apiPost(API_URL + "/deleteUserStudentLink", {
               user_id,
               student_id,
               token: session.user.token,
            });

            if (res.status === 200) {
               // Remove from redux userStudentLinks
               dispatch(removeSingleUserStudentLink({ student_id, user_id }));
               dispatch(
                  addSnackbarMsg(
                     msg(EN ? "Guardian removed" : "Tutor eliminado", "success")
                  )
               );
            }
         } catch (error) {
            console.error("Error during API request:", error);
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? "Error removing guardian (2)"
                        : "Error al eliminar tutor",
                     "error"
                  )
               )
            );
         }
      }
   };

   const handleSubmit = async () => {
      if (!selectedGuardian) {
         return;
      }

      const addFields = "student_id,user_id,demo";
      const addValues = `${viewRow.document},${selectedGuardian.id},${DEMO}`;

      try {
         // Perform the API request
         const res = await apiPost(API_URL + "/crud/create", {
            token: session.user.token,
            tableName: "user_student_links",
            addFields,
            addValues,
            includesId: false,
         });

         // Check if the response is successful
         if (res && res.status === 200) {
            // Add to redux userStudentLinks
            dispatch(
               addSingleUserStudentLink({
                  user_id: selectedGuardian.id,
                  student_id: viewRow.document,
                  demo: DEMO,
               })
            );
            dispatch(
               addSnackbarMsg(
                  msg(EN ? "Guardian added" : "Tutor agregado", "success")
               )
            );
         } else {
            console.error("Failed to add user-student link:", res);
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? "Error adding guardian (1)"
                        : "Error al agregar tutor",
                     "error"
                  )
               )
            );
         }
      } catch (error) {
         console.error("Error during API request:", error);
         dispatch(
            addSnackbarMsg(
               msg(
                  EN ? "Error adding guardian (2)" : "Error al agregar tutor",
                  "error"
               )
            )
         );
      }
   };

   // Find the user IDs associated with the given student document
   const associatedUserIds = useMemo(() => {
      return userStudentLinks
         .filter(
            (link: UserStudentLink) => link.student_id === viewRow.document
         )
         .map((link: UserStudentLink) => link.user_id);
   }, [viewRow.document, userStudentLinks]);

   // Find the user records associated with those user IDs
   const associatedUsers = useMemo(() => {
      return users.arr.filter((user: User) =>
         associatedUserIds.includes(user.id)
      );
   }, [associatedUserIds, users]);

   return (
      <Container component='main' maxWidth='lg' sx={{ padding: 2 }}>
         <Typography variant='h6'>
            {EN ? "Student " : "Estudiante "}:{" "}
            <Typography component='span' color='primary'>
               {viewRow.name}{" "}
            </Typography>
            <Typography component='span' color='success'>
               {viewRow.document || "NA"}
            </Typography>
         </Typography>
         <Table>
            <TableHead>
               <TableRow>
                  <TableCell></TableCell>
                  <TableCell>{EN ? "User ID" : "ID de Usuario"}</TableCell>
                  <TableCell>{EN ? "Contact1" : "Contacto 1"}</TableCell>
                  <TableCell>{EN ? "Contact2" : "Contacto 2"}</TableCell>
                  <TableCell>{EN ? "School ID" : "ID de Escuela"}</TableCell>
                  <TableCell>{EN ? "Phone 1" : "Teléfono 1"}</TableCell>
                  <TableCell>{EN ? "Phone 2" : "Teléfono 2"}</TableCell>
                  <TableCell>
                     {EN ? "Email 1" : "Correo Electrónico 1"}
                  </TableCell>
                  <TableCell>
                     {EN ? "Email 2" : "Correo Electrónico 2"}
                  </TableCell>
               </TableRow>
            </TableHead>
            <TableBody>
               {associatedUsers.map((user: User) => (
                  <TableRow key={user.id}>
                     <TableCell>
                        <Tooltip
                           title={
                              EN
                                 ? "Delete Student Guardian Link"
                                 : "Eliminar enlace de tutor de estudiante"
                           }
                        >
                           <Button
                              size='small'
                              color='primary'
                              onClick={() =>
                                 handleDelete(user.id, viewRow.document)
                              }
                           >
                              <DeleteIcon />
                           </Button>
                        </Tooltip>
                     </TableCell>
                     <TableCell>{user.id}</TableCell>
                     <TableCell>{user.name1}</TableCell>
                     <TableCell>{user.name2 || ""}</TableCell>
                     <TableCell>{user.school_id}</TableCell>
                     <TableCell>
                        {user.telefono1 ? (
                           <WhatsAppClick phoneNumber={user.telefono1} />
                        ) : (
                           ""
                        )}
                     </TableCell>
                     <TableCell>
                        {user.telefono2 ? (
                           <WhatsAppClick phoneNumber={user.telefono2} />
                        ) : (
                           ""
                        )}
                     </TableCell>
                     <TableCell>
                        {user.email1 ? <EmailClick email={user.email1} /> : ""}
                     </TableCell>
                     <TableCell>
                        {user.email2 ? <EmailClick email={user.email2} /> : ""}
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
         </Table>
         <Box sx={{ mt: 4 }}>
            <Typography variant='h6' sx={{ mb: 2 }}>
               {EN ? "Add Guardian/User" : "Agregar Tutor/Usuario"}
            </Typography>
            <Autocomplete
               options={guardianOptions.filter(
                  (option: User) => option.name1 !== undefined
               )}
               getOptionLabel={(option: User) => `${option.name1}`}
               value={selectedGuardian}
               onChange={(event, newValue) => setSelectedGuardian(newValue)}
               renderInput={(params) => (
                  <TextField
                     {...params}
                     label={EN ? "Select Guardian" : "Seleccionar Tutor"}
                     variant='outlined'
                     fullWidth
                  />
               )}
            />

            <Button
               variant='contained'
               color='primary'
               sx={{ mt: 3 }}
               onClick={handleSubmit}
               disabled={!selectedGuardian}
            >
               {EN ? "Add Guardian" : "Agregar Tutor"}
            </Button>
         </Box>
      </Container>
   );
};

export default StudentGuardianLink;
