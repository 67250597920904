import * as React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SchoolIcon from "@mui/icons-material/School";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PaidIcon from "@mui/icons-material/Paid";
import StorageIcon from "@mui/icons-material/Storage";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { Collapse } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import Tooltip from "@mui/material/Tooltip";
import { useState } from "react";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { SessionState } from "../../../features/session/session";

interface LeftNavProps {
   goPage: any;
}

const FireNav = styled(List)<{ component?: React.ElementType }>({
   "& .MuiListItemButton-root": {
      paddingLeft: 27,
      paddingRight: 24,
   },
   "& .MuiListItemIcon-root": {
      minWidth: 0,
      marginRight: 16,
   },
   "& .MuiSvgIcon-root": {
      fontSize: 19,
   },
});

export const LeftNav = (props: LeftNavProps) => {
   const dispatch = useAppDispatch();
   const { goPage } = props;
   const session: SessionState = useAppSelector((state) => state.session);

   const USER_LEVEL: number = session.user.ul || 10;
   const EN: boolean = session.lang === "en" ? true : false;
   const DEMO: boolean = session.user.demo || false;

   const [openSubmenu, setOpenSubmenu] = useState<string | number>("");
   const handleClick = (index: any) => {
      console.log("index", index);
      setOpenSubmenu(openSubmenu === index ? "" : index);
   };

   const menuItems = [
      {
         label: EN ? "Dashboard" : "Tablero",
         icon: <DashboardIcon />,
         level: 100,
         path: "/dashboard",
      },
      {
         label: EN ? "Users" : "Usuarios",
         icon: <PeopleAltIcon />,
         level: 4,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/users",
               level: 4,
            },
            {
               label: EN ? "Add User" : "Agregar usuario",
               type: "Add",
               dialog: "AddUser",
               params: { EN, USER_LEVEL, DEMO },
               level: 3,
            },
            {
               label: EN ? "User Logs" : "Registros de usuario",
               path: "/grid/logs",
               level: 4,
            },
         ],
      },
      {
         label: EN ? "Students" : "Estudiantes",
         icon: <EmojiPeopleIcon />,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/students",
            },
            {
               label: EN ? "Add Student" : "Agregar estudiante",
               type: "Add",
               dialog: "AddStudent",
               params: { EN, DEMO },
               level: 4,
            },
         ],
      },
      { icon: <Divider /> },
      {
         label: EN ? "Transactions" : "Transacciones",
         icon: <CreditCardIcon />,
         level: 4,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/transactions",
               level: 4,
            },
         ],
      },
      {
         label: EN ? "Fees" : "Cuotas",
         icon: <CurrencyExchangeIcon />,
         level: 4,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/fees",
               level: 4,
            },
            {
               label: EN ? "Add Fees" : "Agregar cuotas",
               type: "Add",
               dialog: "AddFees",
               params: { EN, DEMO },
               level: 4,
            },
         ],
      },
      {
         label: EN ? "Payments" : "Pagos",
         icon: <PaidIcon />,
         level: 4,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/payments",
               level: 4,
            },
         ],
      },
      {
         label: EN ? "Other Payments" : "Otros Pagos",
         icon: <PaidIcon />,
         level: 4,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/otherPayments",
               level: 4,
            },
         ],
      },
      { icon: <Divider /> },
      {
         label: EN ? "Schools" : "Escuelas",
         icon: <SchoolIcon />,
         level: 3,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/schools",
               level: 3,
            },
            {
               label: EN ? "Optional Fees" : "Tarifas opcionales",
               path: "/grid/optional_fees",
               level: 3,
            },
            {
               label: EN ? "Add School" : "Agregar escuela",
               type: "Add",
               dialog: "AddSchool",
               params: { EN, USER_LEVEL, DEMO },
               level: 3,
            },
         ],
      },
      {
         label: EN ? "Validate" : "Validar",
         icon: <StorageIcon />,
         level: 1,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/validate",
               level: 1,
            },
         ],
      },
      {
         label: EN ? "Vouchers" : "Vales",
         icon: <ConfirmationNumberIcon />,
         level: 1,
         submenu: [
            {
               label: EN ? "Grid View" : "vista de tabla",
               path: "/grid/vouchers",
               level: 1,
            },
         ],
      },
      {
         label: EN ? "Settings" : "Ajustes",
         icon: <SettingsIcon />,
         level: 4,
         submenu: [
            {
               label: EN ? "General" : "General",
               path: "/settings",
               level: 4,
            },
         ],
      },
   ];
   const filterMenuItems = (items: any, USER_LEVEL: number) => {
      try {
         return items
            .filter((item: any) => !item.level || item.level >= USER_LEVEL)
            .map((item: any) => {
               if (item.submenu) {
                  const filteredSubmenu = item.submenu.filter(
                     (subItem: any) =>
                        !subItem.level || subItem.level >= USER_LEVEL
                  );
                  return { ...item, submenu: filteredSubmenu };
               }
               return item;
            });
      } catch (error) {
         console.error(error);
         return items;
      }
   };

   const filteredMenuItems = filterMenuItems(menuItems, USER_LEVEL);

   return (
      <>
         <FireNav component='nav' disablePadding>
            <List component='nav'>
               {filteredMenuItems.map((item: any, index: number) => (
                  <React.Fragment key={index}>
                     {item.submenu ? (
                        <>
                           <ListItemButton
                              onClick={() => handleClick(index)}
                              sx={{
                                 py: 0,
                                 minHeight: 22,
                                 ml: -1.7,
                              }}
                           >
                              <ListItemIcon sx={{ color: "inherit" }}>
                                 {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                 primary={item.label}
                                 primaryTypographyProps={{
                                    fontSize: 18,
                                    fontWeight: "medium",
                                 }}
                              />
                              {openSubmenu === index ? (
                                 <ExpandLess />
                              ) : (
                                 <ExpandMore />
                              )}
                           </ListItemButton>
                           <Collapse
                              in={openSubmenu === index}
                              timeout='auto'
                              unmountOnExit
                           >
                              <List component='div' disablePadding>
                                 {item.submenu.map(
                                    (subItem: any, subIndex: any) => (
                                       <ListItemButton
                                          key={subIndex}
                                          sx={{ pl: 4, py: 0, minHeight: 22 }}
                                          onClick={() => {
                                             if (item.submenu[subIndex].path) {
                                                goPage(subItem.path);
                                             } else if (
                                                item.submenu[subIndex].type &&
                                                item.submenu[subIndex].type ===
                                                   "Add"
                                             ) {
                                                dispatch(
                                                   setDialog({
                                                      open: true,
                                                      title: `Add record to ${subItem.label}`,
                                                      content:
                                                         item.submenu[subIndex]
                                                            .dialog,
                                                      params:
                                                         item.submenu[subIndex]
                                                            .params,
                                                      tableName: item.submenu[
                                                         subIndex
                                                      ].label
                                                         .toLowerCase()
                                                         .replace(/ /g, "_"),
                                                   })
                                                );
                                             } else {
                                                console.log(
                                                   "No path or dialog specified"
                                                );
                                             }
                                          }}
                                       >
                                          <ListItemIcon
                                             sx={{ color: "inherit" }}
                                          >
                                             <ChevronRightIcon />
                                          </ListItemIcon>
                                          <ListItemText
                                             primary={subItem.label}
                                             primaryTypographyProps={{
                                                fontSize: 18,
                                                fontWeight: "medium",
                                             }}
                                          />
                                       </ListItemButton>
                                    )
                                 )}
                              </List>
                           </Collapse>
                        </>
                     ) : (
                        <Tooltip title={item.label} placement='right'>
                           <ListItemButton
                              key={item.label}
                              sx={{
                                 py: 0,
                                 minHeight: 22,
                                 ml: -1.7,
                              }}
                              onClick={() => goPage(item.path)}
                           >
                              <ListItemIcon sx={{ color: "inherit" }}>
                                 {item.icon}
                              </ListItemIcon>
                              <ListItemText
                                 primary={item.label}
                                 primaryTypographyProps={{
                                    fontSize: 18,
                                    fontWeight: "medium",
                                 }}
                              />
                           </ListItemButton>
                        </Tooltip>
                     )}
                  </React.Fragment>
               ))}
            </List>
         </FireNav>
      </>
   );
};
