import React, { useRef, useState } from "react";
import {
   TextField,
   Button,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
   InputAdornment,
   IconButton,
   CircularProgress,
   Alert,
   Grid,
   Box,
   ListItemIcon,
   ListItemText,
   Divider,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { SessionState } from "../../../features/session/session";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { apiPost } from "../../../utilities/ApiRequests";
import { API_URL } from "../../../constants/api";
import { msg } from "../../../utilities/gen";
import PublicCenteredForm from "../../../components/TemplatePublic/CenteredForm";
import { schools } from "../../../constants/data/schools";
import { areaCodes } from "../../../constants/data/areaCodes";

const SignUp = () => {
   const dispatch = useAppDispatch();

   const session: SessionState = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en" ? true : false;

   const [formData, setFormData] = useState<any>({
      name1: "",
      name2: "",
      school_id: 0,
      user_level: 10,
      country_admin_id: "na",
      country_code: "PAN",
      telefono1: "",
      telefono2: "",
      email1: "",
      email2: "",
      demo: false,
      password1: "",
      password2: "",
      is_active: false,
   });

   const [showPassword, setShowPassword] = useState(false);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState<any>({});
   const password1Ref = useRef<any>(null);
   const password2Ref = useRef<any>(null);
   const buttonRef = useRef<any>(null);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleChange = (
      e:
         | React.ChangeEvent<
              HTMLInputElement | { name?: string; value: unknown }
           >
         | any
   ) => {
      const { name, value } = e.target as HTMLInputElement;

      // Clear the error for school_id if the user selects a school
      if (name === "school_id" && errors.school_id) {
         setErrors((prevErrors: any) => ({
            ...prevErrors,
            school_id: undefined, // Remove the error message
         }));
      }

      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const validateForm = () => {
      const errors: any = {};
      const requiredFields = [
         "name1",
         "school_id",
         "telefono1",
         "email1",
         "password1",
      ];

      requiredFields.forEach((field: any) => {
         if (!formData[field]) {
            errors[field] = EN
               ? "This field is required"
               : "Este campo es obligatorio";

            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? `Please note the mandatory field(s) ` +
                             new Date().toLocaleTimeString()
                        : `Tenga en cuenta los campos obligatorios del formulario.` +
                             new Date().toLocaleTimeString(),
                     "error"
                  )
               )
            );
         }
      });

      return errors;
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | any) => {
      e.preventDefault();
      let eCheck: any;
      try {
         const validationErrors = validateForm();
         if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
         } else {
            setLoading(true);
            console.log(formData);

            // Check if the area code is already included in the phone numbers
            const telefono1 =
               formData.telefono1.startsWith(formData.areaCode1) &&
               formData.telefono1.length > 8
                  ? formData.telefono1
                  : formData.areaCode1 + formData.telefono1;

            const telefono2 =
               formData.telefono2.startsWith(formData.areaCode2) &&
               formData.telefono2.length > 8
                  ? formData.telefono2
                  : formData.areaCode2 + formData.telefono2;

            // Update formData with the correct phone numbers
            const updatedFormData = {
               ...formData,
               telefono1,
               telefono2: formData.telefono2 ? telefono2 : "", // Only include if telefono2 is provided
            };

            // Check if email or phone already exists - must do an API call for this
            eCheck = await apiPost(API_URL + "/user/exists_check", {
               email1: updatedFormData.email1,
               telefono1: updatedFormData.telefono1,
            });

            if (eCheck.data.message !== "User does not exist") {
               console.log("User already exists");
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN
                           ? eCheck.data.message +
                                " " +
                                new Date().toLocaleTimeString()
                           : eCheck.data.mensaje +
                                " " +
                                new Date().toLocaleTimeString(),
                        "error"
                     )
                  )
               );
               setLoading(false);
               return;
            }

            // Add new user
            const res: any = await apiPost(API_URL + "/user/add", {
               token: session.user.token,
               ...updatedFormData, // Use updated form data with joined phone numbers
            });

            setLoading(false);
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? "You have successfully signed up"
                        : "Te has registrado con éxito",
                     "success"
                  )
               )
            );

            // Reset all fields to empty
            setFormData({
               name1: "",
               name2: "",
               school_id: "",
               user_level: "",
               country_admin_id: session.user.id,
               country_code: session.user.cc,
               telefono1: "",
               telefono2: "",
               email1: "",
               email2: "",
               demo: session.user.demo,
               password1: "",
               password2: "",
               is_active: "true",
            });
         }
      } catch (error: any) {
         setLoading(false);

         error.response && error.response.data
            ? dispatch(
                 addSnackbarMsg(
                    msg(
                       EN
                          ? error.response.data.message +
                               " " +
                               new Date().toLocaleTimeString()
                          : error.response.data.mensaje +
                               " " +
                               new Date().toLocaleTimeString(),
                       "error"
                    )
                 )
              )
            : dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   return (
      <PublicCenteredForm title={EN ? "Sign Up" : "Inscribirse"}>
         <form onSubmit={handleSubmit} autoComplete='off'>
            <TextField
               size='small'
               label={
                  EN ? "Person 1 (full name)" : "Persona 1 (nombre completo)"
               }
               name='name1'
               value={formData.name1}
               onChange={handleChange}
               fullWidth
               margin='normal'
               error={!!errors.name1}
               helperText={errors.name1}
            />
            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
               <FormControl size='small'>
                  <Select
                     name='areaCode1'
                     value={formData.areaCode}
                     onChange={handleChange}
                     style={{ width: 100, marginRight: 25, marginTop: 8 }}
                     renderValue={(selected) => {
                        const selectedArea: any = areaCodes.find(
                           (area) => area.code === selected
                        );
                        return (
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                 src={selectedArea.flag}
                                 alt={`${selectedArea.country} flag`}
                                 style={{
                                    width: 24,
                                    height: 16,
                                    marginRight: 8,
                                 }}
                              />
                              {`${selectedArea.code}`}
                           </Box>
                        );
                     }}
                  >
                     {areaCodes.map((area) => (
                        <MenuItem key={area.code} value={area.code}>
                           <ListItemIcon>
                              <img
                                 src={area.flag}
                                 alt={`${area.country} flag`}
                                 style={{
                                    width: 24,
                                    height: 16,
                                    marginRight: 8,
                                 }}
                              />
                           </ListItemIcon>
                           <ListItemText primary={area.code} />
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>

               <TextField
                  size='small'
                  label={EN ? "Phone 1" : "Teléfono 1"}
                  name='telefono1'
                  value={formData.telefono1}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.telefono1}
                  helperText={errors.telefono1}
                  autoComplete='off'
               />
            </Box>
            <TextField
               size='small'
               label={EN ? "Email 1" : "Correo electrónico 1"}
               name='email1'
               value={formData.email1}
               onChange={handleChange}
               fullWidth
               margin='normal'
               error={!!errors.email1}
               helperText={errors.email1}
               autoComplete='off'
            />
            <TextField
               size='small'
               margin='normal'
               required
               fullWidth
               name='password1'
               autoComplete='new-password'
               label={EN ? "Password" : "Contraseña"}
               type={showPassword ? "text" : "password"}
               id='password1'
               inputRef={password1Ref}
               helperText={errors.password}
               onChange={handleChange}
               value={formData.password1}
               InputProps={{
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='toggle password visibility'
                           onClick={handleClickShowPassword}
                           edge='end'
                        >
                           {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />

            <FormControl fullWidth margin='normal' error={!!errors.school_id}>
               <InputLabel>{EN ? "School" : "Escuela"}</InputLabel>
               <Select
                  size='small'
                  name='school_id'
                  value={formData.school_id}
                  onChange={handleChange}
               >
                  {schools.map((school) => (
                     <MenuItem key={school.id} value={school.id}>
                        {school.name}
                     </MenuItem>
                  ))}
               </Select>
               {errors.school_id && (
                  <Alert severity='error'>{errors.school_id}</Alert>
               )}
            </FormControl>
            <Divider textAlign='center' style={{ marginTop: 20 }}>
               {EN ? "2nd Contact (optional)" : "2do Contacto (opcional)"}
            </Divider>

            <TextField
               size='small'
               label={
                  EN ? "Person 2 (full name)" : "Persona 2 (nombre completo)"
               }
               name='name2'
               value={formData.name2}
               onChange={handleChange}
               fullWidth
               margin='normal'
               error={!!errors.name2}
               helperText={errors.name2}
            />

            <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
               <FormControl size='small'>
                  <Select
                     name='areaCode2'
                     value={formData.areaCode2} // Corrected to areaCode2
                     onChange={handleChange}
                     style={{ width: 100, marginRight: 25, marginTop: 8 }}
                     renderValue={(selected) => {
                        const selectedArea: any = areaCodes.find(
                           (area) => area.code === selected
                        );
                        return (
                           <Box sx={{ display: "flex", alignItems: "center" }}>
                              <img
                                 src={selectedArea.flag}
                                 alt={`${selectedArea.country} flag`}
                                 style={{
                                    width: 24,
                                    height: 16,
                                    marginRight: 8,
                                 }}
                              />
                              {`${selectedArea.code}`}
                           </Box>
                        );
                     }}
                  >
                     {areaCodes.map((area) => (
                        <MenuItem key={area.code} value={area.code}>
                           <ListItemIcon>
                              <img
                                 src={area.flag}
                                 alt={`${area.country} flag`}
                                 style={{
                                    width: 24,
                                    height: 16,
                                    marginRight: 8,
                                 }}
                              />
                           </ListItemIcon>
                           <ListItemText primary={area.code} />
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>

               <TextField
                  size='small'
                  label={EN ? "Phone 2" : "Teléfono 2"}
                  name='telefono2'
                  value={formData.telefono2} // Corrected to telefono2
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.telefono2}
                  helperText={errors.telefono2}
                  autoComplete='off'
               />
            </Box>

            <TextField
               size='small'
               label={EN ? "Email 2" : "Correo electrónico 2"}
               name='email2'
               value={formData.email2}
               onChange={handleChange}
               fullWidth
               margin='normal'
               autoComplete='off'
            />

            <TextField
               size='small'
               margin='normal'
               fullWidth
               name='password2'
               autoComplete='new-password 2'
               label={EN ? "Password 2" : "Contraseña 2"}
               type={showPassword ? "text" : "password"}
               id='password2'
               inputRef={password2Ref}
               helperText={errors.password}
               onChange={handleChange}
               value={formData.password2}
               InputProps={{
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='toggle password visibility'
                           onClick={handleClickShowPassword}
                           edge='end'
                        >
                           {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />

            <Button
               size='small'
               type='submit'
               ref={buttonRef}
               fullWidth
               variant='contained'
               sx={{ mt: 3, mb: 2 }}
               disabled={loading}
            >
               {loading ? (
                  <CircularProgress size={24} />
               ) : EN ? (
                  "Submit"
               ) : (
                  "Enviar"
               )}
            </Button>
         </form>
         <Grid container>
            <Button href='/forgot' size='small' fullWidth>
               {EN ? "Forgot password?" : "¿Olvidaste tu contraseña?"}
            </Button>
            <Button href='/login' size='small' fullWidth>
               {EN ? "Login" : "Iniciar Sesión"}
            </Button>
         </Grid>
      </PublicCenteredForm>
   );
};

export default SignUp;
