import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReplayIcon from "@mui/icons-material/Replay";
import { Button, IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { TransactionsState } from "../../../features/transactions/transactions";
import { setTransactions } from "../../../features/transactions/transactionsSlice";
import { fetchData } from "../../../utilities/gen";

export default function TransactionGrid() {
   const dispatch = useAppDispatch();
   const transactions: TransactionsState = useAppSelector(
      (state: any) => state.transactions
   );
   const session: any = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en" ? true : false;

   const [loading, setLoading] = useState(true);
   const rows = transactions.arr;

   const refresh = async () => {
      setLoading(true);
      try {
         const data = await fetchData("transactions", session.user.token);
         dispatch(setTransactions({ arr: data, init: true }));
      } catch (error) {
         console.error("Failed to load transactions data:", error);
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("transactions", session.user.token); // Replace with actual table name and token
               dispatch(setTransactions({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load transactions data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch]);

   const handleView = (transaction_id: number) => {
      const viewRow = transactions.arr.filter(
         (a: any) => a.transaction_id === transaction_id
      );
      dispatch(
         setDialog({
            open: true,
            title: `View Transaction`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "transactions",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "-",
         width: 30,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               {/* <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.transaction_id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton> */}
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.transaction_id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      {
         field: "amount",
         headerName: "Amount",
         width: 150,
         valueFormatter: (params: any) => {
            const value = params && params.value ? params.value : 0;
            return value !== undefined && value !== null
               ? `$${value.toFixed(2)}`
               : "$0.00";
         },
      },

      { field: "transaction_id", headerName: "#", width: 150 },

      { field: "description", headerName: "Description", width: 200 },

      { field: "name", headerName: EN ? "Name" : "Nombre", width: 200 },
      {
         field: "response_date",
         headerName: EN ? "Date" : "Fetcha",
         width: 110,
      },
      { field: "response_code", headerName: "RC", width: 20 },
      {
         field: "authorization_number",
         headerName: "Auth#",
         width: 120,
      },
      {
         field: "user_email",
         headerName: EN ? "User/Email" : "Correo/usuario",
         width: 200,
      },
      {
         field: "user_phone",
         headerName: EN ? "Phone" : "Teléfono",
         width: 150,
      },
      { field: "ip", headerName: "", width: 150 },
      // { field: "processor_id", headerName: "PID", width: 120 },
   ];

   if (loading) {
      return (
         <DashboardTemplate title={"Transactions Grid View"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={"Transactions Grid View"}>
         <Button
            variant='outlined'
            startIcon={<ReplayIcon />}
            onClick={() => refresh()}
            sx={{ marginBottom: 2 }}
         >
            {EN ? "Refresh Data" : "Actualizar Datos"}
         </Button>
         <DataGrid
            density='compact'
            rows={rows}
            columns={columns}
            getRowId={(row) => row.transaction_id}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
