import React from "react";
import IconButton from "@mui/material/IconButton";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { setDialog } from "../../features/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SessionState } from "../../features/session/session";
import { Tooltip } from "@mui/material";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { msg } from "../../utilities/gen";

interface WhatsAppClickProps {
   phoneNumber: string | number;
}

export default function WhatsAppClick(props: WhatsAppClickProps): JSX.Element {
   const dispatch = useAppDispatch();
   const { phoneNumber } = props;
   const session: SessionState = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en" ? true : false;
   let viewPhoneNumber = phoneNumber.toString().replace(/\D/g, "");
   const handleClick = () => {
      dispatch(
         setDialog({
            open: true,
            title: `WhatsApp`,
            content: "WhatsApp",
            params: {
               phoneNumber,
            },
         })
      );
   };
   const handleCopy = (text: string) => {
      navigator.clipboard.writeText(text);
      dispatch(
         addSnackbarMsg(
            msg(
               EN
                  ? `Copied number ${text} to clipboard`
                  : `Copiado número ${text} al portapapeles`,
               "info"
            )
         )
      );
   };

   return (
      <>
         <Tooltip
            title={
               EN
                  ? `WhatsApp Message: ${phoneNumber}`
                  : `Mensaje de WhatsApp: ${phoneNumber}`
            }
         >
            <span>
               <IconButton
                  size='small'
                  sx={{
                     // "backgroundColor": "#25D366",
                     "color": "#25D366",
                     "&:hover": {
                        backgroundColor: "#1ebe57",
                        color: "#fff",
                     },
                  }}
                  onClick={() => handleClick()}
               >
                  <WhatsAppIcon />
                  {/* {viewPhoneNumber} */}
               </IconButton>
            </span>
         </Tooltip>

         <Tooltip
            title={
               EN
                  ? `Copy ${phoneNumber} to Clipboard`
                  : `Copiar ${phoneNumber} al portapapeles`
            }
         >
            <span>
               <IconButton
                  onClick={() => handleCopy(viewPhoneNumber)}
                  color='primary'
                  size='small'
               >
                  <ContentCopyIcon />
                  {/* {viewPhoneNumber} */}
               </IconButton>
            </span>
         </Tooltip>
      </>
   );
}
