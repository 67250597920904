import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Log, LogsState } from "./logs";

const initialState: LogsState = {
   arr: [],
   init: true,
};

export const logsSlice = createSlice({
   name: "logs",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setLogs: (state, action: PayloadAction<LogsState>) => {
         // Clear the state before setting new logs
         state.arr.splice(0, state.arr.length);
         // Push each new log into the state
         action.payload.arr.forEach((log) => {
            state.arr.push(log);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleLog: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the log with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((log: Log) => log.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleLog: (state, action: PayloadAction<Log>) => {
         // Add a single log to the state
         state.arr.push(action.payload);
      },
      clearLogs: () => initialState, // Reset the state to the initial state
   },
});

export const { setLogs, updateSingleLog, clearLogs, addSingleLog } =
   logsSlice.actions;
export const selectLogs = (state: RootState) => state.logs;

export default logsSlice.reducer;
