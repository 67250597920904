import { combineReducers } from "@reduxjs/toolkit";

import dialogReducer from "../features/dialog/dialogSlice";
import menuReducer from "../features/menu/menuSlice";
import pageReducer from "../features/page/pageSlice";
import sessionReducer from "../features/session/sessionSlice";
import snackbarReducer from "../features/snackbar/snackbarSlice";
import suggestReducer from "../features/suggest/suggestSlice";
import feesReducer from "../features/fees/feesSlice";
import logsReducer from "../features/logs/logsSlice";
import optionalFeesReducer from "../features/optional_fees/optional_feesSlice";
import otherPaymentsReducer from "../features/otherPayments/otherPaymentsSlice";
import paymentsReducer from "../features/payments/paymentsSlice";
import settlementsReducer from "../features/settlements/settlementsSlice";
import schoolRedcer from "../features/schools/schoolsSlice";
import studentsReducer from "../features/students/studentsSlice";
import usersReducer from "../features/users/usersSlice";
import userSchoolLinksReducer from "../features/user_school_links/userSchoolLinkSlice";
import userStudentLinksReducer from "../features/user_student_links/userStudentLinkSlice";
import validateOTPReducer from "../features/validateOTP/validateOTPSlice";
import vouchersReducer from "../features/vouchers/vouchersSlice";
import transactionsReducer from "../features/transactions/transactionsSlice";

const rootReducer = combineReducers({
   dialog: dialogReducer,
   menu: menuReducer,
   page: pageReducer,
   session: sessionReducer,
   snackbar: snackbarReducer,
   suggest: suggestReducer,
   fees: feesReducer,
   logs: logsReducer,
   optional_fees: optionalFeesReducer,
   otherPayments: otherPaymentsReducer,
   payments: paymentsReducer,
   settlements: settlementsReducer,
   schools: schoolRedcer,
   students: studentsReducer,
   users: usersReducer,
   userSchoolLinks: userSchoolLinksReducer,
   userStudentLinks: userStudentLinksReducer,
   validateOTP: validateOTPReducer,
   vouchers: vouchersReducer,
   transactions: transactionsReducer,
});

export default rootReducer;
