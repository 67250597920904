import React, { useRef, useState, useEffect } from "react";
import {
   TextField,
   Button,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
   Container,
   Box,
   CircularProgress,
   Alert,
   Typography,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { generateName, msg, rand, updateRecordByID } from "../../utilities/gen";
import { Students } from "../../features/students/students"; // Import the Student type
import { apiPost } from "../../utilities/ApiRequests";
import { API_URL } from "../../constants/api";
import {
   addSingleStudent,
   updateSingleStudent,
} from "../../features/students/studentsSlice"; // Import student actions
import { closeDialog } from "../../features/dialog/dialogSlice";
import { SessionState } from "../../features/session/session";

interface AddEditStudentProps {
   params: {
      EN: boolean | any;
      DEMO: boolean | any;
      document?: string;
   };
}

const AddEditStudent = (props: AddEditStudentProps) => {
   const dispatch = useAppDispatch();
   const { EN, DEMO, document } = props.params;
   const students: Students[] = useAppSelector(
      (state: any) => state.students.arr
   );
   const schools: any = useAppSelector((state: any) => state.schools.arr);
   const session: SessionState = useAppSelector((state: any) => state.session);
   const token = session.user.token;

   const studentToEdit = document
      ? students.find((s) => s.document === document)
      : null;
   const name = generateName(3);
   const [formData, setFormData] = useState<any>({
      name: DEMO ? name : studentToEdit ? studentToEdit.name : "",
      document: DEMO
         ? "DOC" + Math.floor(Math.random() * 10000)
         : studentToEdit
         ? studentToEdit.document
         : "",
      grade: DEMO
         ? "Grade " + Math.floor(Math.random() * 12)
         : studentToEdit
         ? studentToEdit.grade
         : "",
      year: DEMO
         ? new Date().getFullYear()
         : studentToEdit
         ? studentToEdit.year
         : "",
      guardian_id: DEMO
         ? Math.floor(Math.random() * 1000)
         : studentToEdit
         ? studentToEdit.guardian_id
         : "",
      school_id: DEMO
         ? Math.floor(Math.random() * 6) + 55
         : studentToEdit
         ? studentToEdit.school_id
         : "",
      monthly_fee: DEMO
         ? Math.floor(Math.random() * 1000)
         : studentToEdit
         ? studentToEdit.monthly_fee
         : "",
      telefono1: DEMO
         ? "507000" + Math.floor(Math.random() * 10000)
         : studentToEdit
         ? studentToEdit.telefono1
         : "",
      telefono2: DEMO
         ? "507000" + Math.floor(Math.random() * 10000)
         : studentToEdit
         ? studentToEdit.telefono2
         : "",
      email: DEMO
         ? name.toLowerCase().replace(/ /g, ".") + "@mygmail.com"
         : studentToEdit
         ? studentToEdit.email
         : "",
      guardian_cedula: DEMO
         ? "CED" + Math.floor(Math.random() * 10000)
         : studentToEdit
         ? studentToEdit.guardian_cedula
         : "",
      comment: DEMO
         ? "Demo comment"
         : studentToEdit
         ? studentToEdit.comment
         : "",
      demo: session.user.demo,
      is_active: studentToEdit ? studentToEdit.is_active : true,
   });

   useEffect(() => {
      if (studentToEdit) {
         setFormData({
            ...formData,
            ...studentToEdit,
         });
      }
   }, [studentToEdit]);

   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState<any>({});
   const buttonRef = useRef<any>(null);

   const handleChange = (
      e:
         | React.ChangeEvent<
              HTMLInputElement | { name?: string; value: unknown }
           >
         | any
   ) => {
      const { name, value } = e.target as HTMLInputElement;
      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const validateForm = () => {
      const errors: any = {};
      const requiredFields = [
         "name",
         "document",
         "grade",
         "year",
         "guardian_id",
         "school_id",
         "telefono1",
         "email",
      ];

      requiredFields.forEach((field: any) => {
         if (!formData[field]) {
            errors[field] = EN
               ? "This field is required"
               : "Este campo es obligatorio";
         }
      });

      return errors;
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | any) => {
      e.preventDefault();
      try {
         const validationErrors = validateForm();
         if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
         } else {
            setLoading(true);
            console.log(formData);

            if (document) {
               // Update existing student
               const updateFieldsObj = Object.keys(formData);
               const updateValuesObj = Object.values(formData);
               // get the updateFields must be made into a string
               const updateFields = Object.keys(formData).join(",");
               // get the updateValues which means first removing all commas from the values then making into a coma delimited string
               const updateValues = Object.values(formData)
                  .map((v: any) => v.toString().replace(/,/g, ""))
                  .join(",");
               let res = await apiPost(API_URL + "/crud/update/matching", {
                  tableName: "students",
                  updateFields,
                  updateValues,
                  searchFields: "document",
                  search: document,
                  token,
               });
               console.log(res);
               dispatch(
                  updateSingleStudent({
                     document,
                     updateFields: Object.keys(formData),
                     updateValues: Object.values(formData),
                  })
               );
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN ? "Student updated" : "Estudiante actualizado",
                        "success"
                     )
                  )
               );
            } else {
               // Add new student
               const addFields = Object.keys(formData).join(",");
               const addValues = Object.values(formData)
                  .map((v: any) => v.toString().replace(/,/g, ""))
                  .join(",");
               const res = await apiPost(API_URL + "/crud/create", {
                  token,
                  tableName: "students",
                  addFields,
                  addValues,
                  includesId: false,
               });
               dispatch(addSingleStudent(formData));
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN ? "Student added" : "Estudiante agregado",
                        "success"
                     )
                  )
               );
            }

            setLoading(false);
            dispatch(closeDialog());
            if (!document) {
               setFormData({
                  name: "",
                  document: "",
                  grade: "",
                  year: "",
                  guardian_id: "",
                  school_id: "",
                  monthly_fee: "",
                  telefono1: "",
                  telefono2: "",
                  email: "",
                  guardian_cedula: "",
                  comment: "",
                  demo: session.user.demo,
                  is_active: true,
               });
            }
         }
      } catch (error: any) {
         setLoading(false);
         console.log(error);
         error.response && error.response.data
            ? dispatch(
                 addSnackbarMsg(
                    msg(
                       EN
                          ? error.response.data.message
                          : error.response.data.mensaje,
                       "error"
                    )
                 )
              )
            : dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   return (
      <Container component='main' maxWidth='md' sx={{ padding: 2 }}>
         <h3>
            {EN
               ? document
                  ? "Edit Student"
                  : "Add Student"
               : document
               ? "Editar estudiante"
               : "Agregar estudiante"}
         </h3>
         {DEMO && (
            <Typography sx={{ fontWeight: 700, color: "red", mb: 2 }}>
               {EN
                  ? "Demo Mode will auto fill fields"
                  : "Modo demo llenará automáticamente los campos"}
            </Typography>
         )}
         <Box sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit} autoComplete='off'>
               <TextField
                  size='small'
                  label={EN ? "Name" : "Nombre"}
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.name}
                  helperText={errors.name}
               />
               <TextField
                  size='small'
                  label={EN ? "Document" : "Documento"}
                  name='document'
                  value={formData.document}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.document}
                  helperText={errors.document}
               />
               <TextField
                  size='small'
                  label={EN ? "Grade" : "Grado"}
                  name='grade'
                  value={formData.grade}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.grade}
                  helperText={errors.grade}
               />
               <TextField
                  size='small'
                  label={EN ? "Year" : "Año"}
                  name='year'
                  value={formData.year}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.year}
                  helperText={errors.year}
               />
               <TextField
                  size='small'
                  label={EN ? "Guardian ID" : "ID del Guardián"}
                  name='guardian_id'
                  value={formData.guardian_id}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.guardian_id}
                  helperText={errors.guardian_id}
               />
               <FormControl
                  fullWidth
                  margin='normal'
                  error={!!errors.school_id}
               >
                  <InputLabel>
                     {EN ? "School ID" : "ID de la Escuela"}
                  </InputLabel>
                  <Select
                     size='small'
                     name='school_id'
                     value={formData.school_id}
                     onChange={handleChange}
                     label={EN ? "School ID" : "ID de la Escuela"}
                  >
                     {schools.map((school: any) => (
                        <MenuItem key={school.id} value={school.id}>
                           {school.name}
                        </MenuItem>
                     ))}
                  </Select>
                  {errors.school_id && (
                     <Alert severity='error'>{errors.school_id}</Alert>
                  )}
               </FormControl>

               <TextField
                  size='small'
                  label={EN ? "Monthly Fee" : "Cuota Mensual"}
                  name='monthly_fee'
                  value={formData.monthly_fee}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.monthly_fee}
                  helperText={errors.monthly_fee}
               />
               <TextField
                  size='small'
                  label={EN ? "Phone 1" : "Teléfono 1"}
                  name='telefono1'
                  value={formData.telefono1}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.telefono1}
                  helperText={errors.telefono1}
                  autoComplete='off'
               />
               <TextField
                  size='small'
                  label={EN ? "Phone 2" : "Teléfono 2"}
                  name='telefono2'
                  value={formData.telefono2}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  autoComplete='off'
               />
               <TextField
                  size='small'
                  label={EN ? "Email" : "Correo electrónico"}
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.email}
                  helperText={errors.email}
                  autoComplete='off'
               />
               <TextField
                  size='small'
                  label={EN ? "Guardian Cedula" : "Cédula del Guardián"}
                  name='guardian_cedula'
                  value={formData.guardian_cedula}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.guardian_cedula}
                  helperText={errors.guardian_cedula}
               />
               <TextField
                  size='small'
                  label={EN ? "Comment" : "Comentario"}
                  name='comment'
                  value={formData.comment}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.comment}
                  helperText={errors.comment}
               />
               <FormControl
                  fullWidth
                  margin='normal'
                  error={!!errors.is_active}
               >
                  <InputLabel>{EN ? "Active" : "Activo"}</InputLabel>
                  <Select
                     name='is_active'
                     size='small'
                     value={formData.is_active}
                     onChange={handleChange}
                  >
                     <MenuItem value='true'>TRUE</MenuItem>
                     <MenuItem value='false'>FALSE</MenuItem>
                  </Select>
                  {errors.is_active && (
                     <Alert severity='error'>{errors.is_active}</Alert>
                  )}
               </FormControl>
               <Button
                  size='small'
                  type='submit'
                  ref={buttonRef}
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
               >
                  {loading ? (
                     <CircularProgress size={24} />
                  ) : EN ? (
                     "Submit"
                  ) : (
                     "Enviar"
                  )}
               </Button>
            </form>
         </Box>
      </Container>
   );
};

export default AddEditStudent;
