export const firstNames = [
   "Alice",
   "Bob",
   "Charlie",
   "David",
   "Emily",
   "Frank",
   "Grace",
   "Henry",
   "Isabella",
   "Jack",
   "Karen",
   "Liam",
   "Megan",
   "Noah",
   "Olivia",
   "Penelope",
   "Quentin",
   "Rachel",
   "Samuel",
   "Thomas",
   "Ursula",
   "Victor",
   "Wendy",
   "Xavier",
   "Yara",
   "Zachary",
   "Abigail",
   "Benjamin",
   "Chloe",
   "Daniel",
   "Eleanor",
   "Fiona",
   "Gabriel",
   "Hannah",
   "Isaac",
   "Jasmine",
   "Kevin",
   "Laura",
   "Michael",
   "Natalie",
   "Oscar",
   "Patricia",
   "Quinn",
   "Riley",
   "Sophia",
   "Tyler",
];

export const middleNames = [
   "Alexander",
   "Beatrice",
   "Carter",
   "Danielle",
   "Ethan",
   "Fiona",
   "Gabriel",
   "Harper",
   "Isabella",
   "Jackson",
   "Kennedy",
   "Liam",
   "Madison",
   "Noah",
   "Olivia",
   "Parker",
   "Quinn",
   "Riley",
   "Sophia",
   "Thomas",
   "Ursula",
   "Victor",
   "Willow",
   "Xavier",
   "Yasmin",
   "Zachary",
   "Abigail",
   "Benjamin",
   "Chloe",
   "Daniel",
   "Eleanor",
   "Frederick",
   "Grace",
   "Henry",
   "Ivy",
   "Jacob",
   "Katherine",
   "Leo",
   "Mia",
   "Nathaniel",
   "Oliver",
   "Penelope",
   "Quentin",
   "Rachel",
   "Samuel",
   "Tyler",
];

export const lastNames = [
   "Anderson",
   "Baker",
   "Carter",
   "Davis",
   "Ellis",
   "Fisher",
   "Garcia",
   "Harris",
   "Irwin",
   "Jackson",
   "Kelly",
   "Lewis",
   "Miller",
   "Nelson",
   "Owens",
   "Parker",
   "Quinn",
   "Roberts",
   "Smith",
   "Thomas",
   "Underwood",
   "Vaughn",
   "Walker",
   "Xavier",
   "Young",
   "Zimmerman",
   "Adams",
   "Brown",
   "Cooper",
   "Daniels",
   "Evans",
   "Foster",
   "Green",
   "Hill",
   "Jones",
   "King",
   "Lee",
   "Martin",
   "Murphy",
   "Nguyen",
   "Ortiz",
   "Perez",
   "Russell",
   "Scott",
   "Taylor",
   "Williams",
];
