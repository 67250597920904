import Box from "@mui/material/Box";
import React, { useRef } from "react";

import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import Container from "@mui/material/Container";
import { rand } from "../../utilities/gen";
import { IMG_PATH } from "../../constants/api";

interface UseViewProps {
   params: {
      row: any;
      tableName?: string;
      imgPath?: string;
      imgColName?: string;
      imgWidth?: string;
      imgHeight?: string;
   };
}

export default function DialogViewOne(props: UseViewProps): JSX.Element {
   const {
      row,
      tableName = "Table Name Not Specified",
      imgPath = "/",
      imgColName = "null",
      imgHeight = "100px",
      imgWidth = "100px",
   } = props.params;

   let finalRow = { ...row }; // Create a new object for finalRow

   if (finalRow.hasOwnProperty(imgColName)) {
      const imgColValue = finalRow[imgColName];

      // Create a new object with imgColName as the first column
      finalRow = {
         [imgColName]: imgColValue,
         ...Object.fromEntries(
            Object.entries(finalRow).filter(([key]) => key !== imgColName)
         ),
      };
   }

   return (
      <Container component='main' maxWidth='lg' sx={{ padding: 3 }}>
         <div style={{ padding: 5 }} />
         <h3>View {tableName}</h3>
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <form>
               <Box sx={{ width: "100%", typography: "body1" }}>
                  <TableContainer>
                     <Table sx={{ padding: 1 }}>
                        <TableBody>
                           {finalRow &&
                              typeof finalRow === "object" &&
                              Object.keys(finalRow).length > 0 &&
                              Object.keys(finalRow).map((paramName) => {
                                 let paramValue = finalRow[paramName];

                                 if (paramName === imgColName) {
                                    paramValue += ","; // Add a comma to the end of the string
                                    const images = paramValue.split(",");

                                    return (
                                       <TableRow
                                          hover
                                          tabIndex={-1}
                                          key={rand()}
                                       >
                                          <TableCell>{paramName}</TableCell>
                                          <TableCell>
                                             <div>
                                                {images.map(function (
                                                   image: string,
                                                   index: number
                                                ) {
                                                   if (
                                                      image &&
                                                      image.length > 1
                                                   ) {
                                                      return (
                                                         <img
                                                            key={index}
                                                            src={
                                                               IMG_PATH +
                                                               imgPath +
                                                               image.trim()
                                                            }
                                                            alt='Thumbnail'
                                                            style={{
                                                               width: imgWidth,
                                                               height:
                                                                  imgHeight,
                                                               marginRight:
                                                                  "10px",
                                                               cursor:
                                                                  "pointer",
                                                            }}
                                                            onClick={() =>
                                                               window.open(
                                                                  IMG_PATH +
                                                                     imgPath +
                                                                     image.trim(),
                                                                  "_blank"
                                                               )
                                                            }
                                                         />
                                                      );
                                                   }
                                                })}
                                             </div>
                                          </TableCell>
                                       </TableRow>
                                    );
                                 }

                                 return (
                                    <TableRow hover tabIndex={-1} key={rand()}>
                                       <TableCell>
                                          {paramName
                                             .toString()
                                             .replaceAll("_", " ")
                                             .toUpperCase()}
                                       </TableCell>
                                       <TableCell>{paramValue}</TableCell>
                                    </TableRow>
                                 );
                              })}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Box>
            </form>
         </Paper>
      </Container>
   );
}
