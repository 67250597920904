import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton, Toolbar } from "@mui/material";
import { LeftNav } from "./LeftNav";
import { useNavigate } from "react-router-dom";

interface DrawerLeftProps {
   open: boolean | any;
   variant: any;
   toggleDrawer: any;
}

export const DrawerLeft = (props: DrawerLeftProps) => {
   console.log("DrawerLeft: ", props);
   const { open, variant, toggleDrawer } = props;
   const navigate = useNavigate();

   const goPage = (link: string) => {
      navigate(link);
   };

   const DrawerStyle = styled(MuiDrawer, {
      shouldForwardProp: (prop) => prop !== "open",
   })(({ theme, open }) => ({
      "& .MuiDrawer-paper": {
         position: "relative",
         whiteSpace: "nowrap",
         width: 220,
         transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
         }),
         boxSizing: "border-box",
         ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
               easing: theme.transitions.easing.sharp,
               duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(6),
            [theme.breakpoints.up("sm")]: {
               width: theme.spacing(5),
            },
         }),
      },
   }));

   return (
      <>
         <DrawerStyle variant={variant} open={open}>
            <Toolbar
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  px: [1],
               }}
            ></Toolbar>
            <IconButton
               edge='start'
               color='inherit'
               aria-label='open drawer'
               onClick={toggleDrawer}
               sx={{
                  width: "10px",
                  height: "10px",
                  paddingL: "0px",
                  marginLeft: "15px",
                  marginTop: "1px",
               }}
            >
               {!open ? <MenuIcon /> : <ChevronLeftIcon />}
            </IconButton>
            <LeftNav goPage={goPage} />
         </DrawerStyle>
      </>
   );
};
