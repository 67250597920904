import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "../../app/store";
import { SessionState } from "./session";

const initialState: SessionState = {
   density: "comfortable",
   loginDisplay: 0,
   loginDisplayLastClicked: 0,
   lang: "es",
   paused: false,
   notRobot: false,
   toggle: true,
   cookieConsent: true,
   darkMode: false,
   trigger: false,
   status: "idle",
   loadInit: false,
   user: {
      token: "",
      email: "",
      name: "",
      photoUrl: "",
      createdAt: 1651367411061,
      bio: `NA `,
      lastLoginAt: 1661467511061,
      lastSignInTime: "1661467511061",
      ul: 99, // user level
      demo: true, // demo user
   },
   value: 0,
   dim: { wi: 100, he: 100 },
};

export const sessionSlice = createSlice({
   name: "session",
   initialState,
   reducers: {
      setSession: (state, action: PayloadAction<SessionState>) => {
         const o: SessionState = action.payload;
         console.log(o);
         try {
            if (o.user.email) state.user.email = o.user.email;
            if (o.user.token) state.user.token = o.user.token;
            if (o.user.cc) state.user.cc = o.user.cc;
            if (o.user.id) state.user.id = o.user.id;
            state.user.demo = o.user.demo;
            if (o.user.lastLoginAt) state.user.lastLoginAt = o.user.lastLoginAt;
            if (o.lang) state.lang = o.lang;
            if (o.notRobot) state.notRobot = o.notRobot;
            state.user.ul = o.user.ul; // user level
            state.user.name = o.user.name;
            state.density = o.density;
            state.darkMode = o.darkMode;
            state.paused = o.paused;
            state.loginDisplayLastClicked = o.loginDisplayLastClicked;
            state.status = o.status;
            state.value = o.value;
            state.dim = o.dim;
            state.cookieConsent = o.cookieConsent;
         } catch (error) {
            console.log(error);
         }
      },
      setToggleLang: (state) => {
         state.lang = state.lang === "en" ? "es" : "en";
      },
      setToggleDarkMode: (state) => {
         state.darkMode = !state.darkMode;
      },
      clearSession: (state) => {
         // Store lang and darkMode
         const lang = state.lang;
         const darkMode = state.darkMode;

         // Clear the session state
         state.user.token = "";
         state.user.email = "";
         state.loginDisplay = 1; // 0 or -1, no login, 1 login, 2 show logout
         state.loginDisplayLastClicked = 2;

         // Restore lang and darkMode
         state.lang = lang;
         state.darkMode = darkMode;
      },
      toggleTrigger(state) {
         state.trigger = !state.trigger;
      },
   },
});

export const {
   setSession,
   clearSession,
   setToggleLang,
   toggleTrigger,
   setToggleDarkMode,
} = sessionSlice.actions;

export const selectSession = (state: RootState) => state.session.value;

export default sessionSlice.reducer;
