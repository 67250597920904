export default function () {
   return (
      <div>
         <h1>None Specified</h1>
         You need to specify proper value for content
         <pre>
            setDialog[[ open: true, title: `View Fees`, content: "View", params:
            row: agents.arr[id] ]], then make the update in Dialog/index
         </pre>
      </div>
   );
}
