import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Settlements, SettlementsState } from "./settlements";

const initialState: SettlementsState = {
   arr: [],
   init: false,
};

export const settlementsSlice = createSlice({
   name: "settlements",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setSettlements: (state, action: PayloadAction<SettlementsState>) => {
         // Clear the state before setting new settlements
         state.arr.splice(0, state.arr.length);
         // Push each new settlement into the state
         action.payload.arr.forEach((settlement) => {
            state.arr.push(settlement);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleSettlements: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the settlement with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (settlement: Settlements) => settlement.reference === id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleSettlements: (state, action: PayloadAction<Settlements>) => {
         // Add a single settlement to the state
         state.arr.push(action.payload);
      },
      clearSettlements: () => initialState,
   },
});

export const {
   setSettlements,
   updateSingleSettlements,
   clearSettlements,
   addSingleSettlements,
} = settlementsSlice.actions;
export const selectSettlements = (state: RootState) => state.settlements;

export default settlementsSlice.reducer;
