import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";

// import { firebaseConfig } from "../../../firebase/constants";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useState } from "react";
import { isValidEmail } from "../../../utilities/validate";
import { addSnackbarMsg } from "../../../features/snackbar/snackbarSlice";
import { msg } from "../../../utilities/gen";
import CircularProgress from "@mui/material/CircularProgress";
import SnackbarMsg from "../../../components/Snackbar/Messages";
import PublicTemplate from "../../../components/TemplatePublic";
import { SessionState } from "../../../features/session/session";
import { BORDER_RADIUS, ELEVATION } from "../../../constants/styles";
import PublicCenteredForm from "../../../components/TemplatePublic/CenteredForm";
import { Divider } from "@mui/material";

export default function SignUp() {
   // const app = initializeApp(firebaseConfig);
   // const auth = getAuth(app);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const session: SessionState = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en";

   const [loading, setLoading] = useState(false);
   const [success, setSuccess] = useState(false);

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      console.log("---------BS");
      event.preventDefault();
      setLoading(true);

      const data = new FormData(event.currentTarget);

      const email: any = data.get("email");

      if (isValidEmail(email)) {
         //dispatch(addSnackbarMsg(msg("Searching for account", "info")));
         try {
            // const resp: any = await sendPasswordResetEmail(auth, email);
            // console.log(resp);
            setSuccess(true);
            dispatch(addSnackbarMsg(msg("Password Reset Success", "success")));
         } catch (error: any) {
            dispatch(addSnackbarMsg(msg("Email not found", "error")));

            setLoading(false);
         }
      } else {
         setLoading(false);
         dispatch(
            addSnackbarMsg(
               msg(
                  EN
                     ? "Invalid Email: " + new Date().toLocaleTimeString()
                     : "Correo Electrónico Inválido " +
                          new Date().toLocaleTimeString(),
                  "error"
               )
            )
         );
      }
   };

   const goHome = () => navigate(`/`);

   return (
      <PublicCenteredForm
         title={EN ? `Forgot Password` : `Has olvidado tu contraseña`}
      >
         {success ? (
            <>
               Email reset Button sent
               <div style={{ padding: 20 }} />
               <Button onClick={() => navigate("/login")}>Go To Login</Button>
            </>
         ) : (
            <Box
               component='form'
               noValidate
               onSubmit={(e) => handleSubmit(e)}
               sx={{ mt: 3 }}
            >
               <Grid container spacing={2}>
                  <Grid item xs={12}>
                     <TextField
                        required
                        fullWidth
                        size='small'
                        id='email'
                        label={EN ? "WhatsApp #" : "Número de WhatsApp"}
                        name='email'
                        autoComplete='email'
                     />
                     <Divider textAlign='center' style={{ marginTop: 20 }}>
                        {EN ? "OR" : "O"}
                     </Divider>
                  </Grid>
                  <Grid item xs={12}>
                     <TextField
                        required
                        fullWidth
                        size='small'
                        id='email'
                        label={EN ? "Email Address" : "Correo Electrónico"}
                        name='email'
                        autoComplete='email'
                     />
                  </Grid>

                  <Grid item xs={12}>
                     <Button
                        fullWidth
                        type='submit'
                        size='small'
                        variant='contained'
                        disabled={loading}
                        sx={{ textTransform: "none" }}
                     >
                        Reset Password
                     </Button>
                     {loading && (
                        <CircularProgress
                           size={24}
                           sx={{
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              marginTop: "-12px",
                              marginLeft: "-12px",
                           }}
                        />
                     )}
                  </Grid>
               </Grid>
               <Grid container style={{ marginTop: 10 }}>
                  <Button href='/login' size='small' fullWidth>
                     {EN ? "login" : "Iniciar Sesión"}
                  </Button>
                  <Button href='/signup' size='small' fullWidth>
                     {EN ? "Sign Up" : "Regístrate"}
                  </Button>
               </Grid>
            </Box>
         )}
      </PublicCenteredForm>
   );
}
