import React from "react";
import IconButton from "@mui/material/IconButton";
import EmailIcon from "@mui/icons-material/Email";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { setDialog } from "../../features/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SessionState } from "../../features/session/session";
import { Tooltip } from "@mui/material";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { msg } from "../../utilities/gen";

interface EmailClickProps {
   email: string | number;
}

export default function EmailClick(props: EmailClickProps): JSX.Element {
   const dispatch = useAppDispatch();
   const { email } = props;
   const session: SessionState = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en" ? true : false;
   const USER_LEVEL = session.user.ul || 10;
   let viewEmail = email.toString().trim();

   const handleClick = () => {
      dispatch(
         setDialog({
            open: true,
            title: `Email`,
            content: "Email",
            params: {
               email,
            },
         })
      );
   };
   const handleCopy = (text: string) => {
      navigator.clipboard.writeText(text);
      dispatch(
         addSnackbarMsg(
            msg(
               EN
                  ? `Copied email ${text} to clipboard`
                  : `Copiado correo ${text} al portapapeles`,
               "info"
            )
         )
      );
   };

   return (
      <>
         {USER_LEVEL <= 3 ? (
            <>
               <Tooltip
                  title={
                     EN
                        ? `Email Message: ${email}`
                        : `Mensaje de correo electrónico: ${email}`
                  }
               >
                  <span>
                     <IconButton
                        size='small'
                        onClick={handleClick}
                        color='primary'
                     >
                        <EmailIcon />
                     </IconButton>
                  </span>
               </Tooltip>

               <Tooltip
                  title={
                     EN
                        ? `Copy ${email} to Clipboard`
                        : `Copiar ${email} al portapapeles`
                  }
               >
                  <span>
                     <IconButton
                        onClick={() => handleCopy(viewEmail)}
                        color='primary'
                        size='small'
                     >
                        <ContentCopyIcon />
                     </IconButton>
                  </span>
               </Tooltip>
            </>
         ) : (
            <>{email}</>
         )}
      </>
   );
}
