import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Fee, FeesState } from "./fees";

const initialState: FeesState = {
   arr: [],
   init: true,
};

export const feesSlice = createSlice({
   name: "fees",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setFees: (state, action: PayloadAction<FeesState>) => {
         // Clear the state before setting new fees
         state.arr.splice(0, state.arr.length);
         // Push each new fee into the state
         action.payload.arr.forEach((fee) => {
            state.arr.push(fee);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleFee: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the fee with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((fee: Fee) => fee.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleFee: (state, action: PayloadAction<Fee>) => {
         // Add a single fee to the state
         state.arr.push(action.payload);
      },
      clearFees: (state) => {
         console.log("Clearing fees");
         state.arr = []; // Clear the array
         state.init = true; // Reset other state properties if needed
      },
   },
});

export const { setFees, updateSingleFee, clearFees, addSingleFee } =
   feesSlice.actions;
export const selectFees = (state: RootState) => state.fees;

export default feesSlice.reducer;
