//--------USERS
// COUNTRY ADMINS
export const VIEW_COUNTRY_ADMINS = 1; // 1 = super admin
export const ADD_COUNTRY_ADMINS = 1;

// SCHOOLS ADMINS
export const VIEW_SCHOOL_ADMINS = 3; // 3 = country admin
export const ADD_SCHOOL_ADMINS = 3;
export const EDIT_SCHOOL_ADMINS = 3;

// GUARDIANS
export const VIEW_GUARDIANS = 4; // 4 = school admin
export const ADD_GUARDIANS = 4; // 4 at their own school 3 at any school
export const EDIT_GUARDIANS = 4; // 4 at their own school 3 at any school

// USERS
export const VIEW_USERS = 4; // 3 = country admin

//-----FEES
export const VIEW_FEES = 4; // 4 = school admin if same school_id as user or linked to guardian
export const EDIT_FEES = 3;

//-----PAYMENTS PAYMENTS_OTHER
export const VIEW_PAYMENTS = 4; // 4 = school admin if same school_id as user or linked to guardian
export const EDIT_PAYMENTS = 4; // 4 at their own school 3 at any school

//----SCHOOLS
export const VIEW_SCHOOLS = 3; // 3 = country admin
export const ADD_SCHOOLS = 3;

//----SETTLEMENTS
export const VIEW_SETTLEMENTS = 4; // 4 = school admin if same school_id as user or linked to guardian
export const ADD_SETTLEMENTS = 4; // 4 at their own school 3 at any school

//-----STUDENTS table
export const VIEW_STUDENTS = 10; // 4 = school admin if same school_id as user or linked to guardian
export const ADD_STUDENTS = 4; // 4 at their own school 3 at any school

//-----USER SCHOOL LINKS
export const VIEW_USER_SCHOOL_LINKS = 10;
export const ADD_USER_SCHOOL_LINKS = 4;

//-----USER STUDENT LINKS
export const VIEW_USER_STUDENT_LINKS = 10;
export const ADD_USER_STUDENT_LINKS = 4;
