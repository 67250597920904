import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
   addSnackbarMsg,
   clearSnackbar,
} from "../features/snackbar/snackbarSlice";
import { msg } from "../utilities/gen";
import { isValidEmail, isValidPassword } from "../utilities/validate";
import { SessionState } from "../features/session/session";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import "../App.css";
import { apiPost } from "../utilities/ApiRequests";
import { API_URL } from "../constants/api";
import { setSession } from "../features/session/sessionSlice";
import { useNavigate } from "react-router-dom";
import { SnackbarState } from "../features/snackbar/snackbar";
import {
   CircularProgress,
   Divider,
   FormControl,
   IconButton,
   InputAdornment,
   InputLabel,
   MenuItem,
   Select,
} from "@mui/material";
import { useRef, useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";

import { clearFees } from "../features/fees/feesSlice";
import { clearPayments } from "../features/payments/paymentsSlice";
import { clearOtherPayments } from "../features/otherPayments/otherPaymentsSlice";
import { clearVouchers } from "../features/vouchers/vouchersSlice";
import { clearValidateOTP } from "../features/validateOTP/validateOTPSlice";
import { clearUserSchoolLinkState } from "../features/user_school_links/userSchoolLinkSlice";
import { clearUsers } from "../features/users/usersSlice";
import { clearStudents } from "../features/students/studentsSlice";
import { clearSettlements } from "../features/settlements/settlementsSlice";
import { clearSchools } from "../features/schools/schoolsSlice";
import PublicCenteredForm from "../components/TemplatePublic/CenteredForm";
import { clearLogs } from "../features/logs/logsSlice";

const defaultTheme = createTheme();

export default function SignInSide() {
   const session: SessionState = useAppSelector((state: any) => state.session);
   const snackbar: SnackbarState = useAppSelector((state) => state.snackbar);
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const EN = session.lang === "en" ? true : false;

   const [showPassword, setShowPassword] = useState(false);
   const [loading, setLoading] = useState(false);
   const emailRef = useRef<any>(null);
   const passwordRef = useRef<any>(null);
   const buttonRef = useRef<any>(null);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleChangeDemo = (event: any) => {
      const email = event.target.value;
      emailRef.current.value = email;
      passwordRef.current.value = "demo1098K5";
      setTimeout(() => {
         buttonRef.current.click();
      }, 500);
   };

   const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      try {
         event.preventDefault();
         const data = new FormData(event.currentTarget);
         const email = data.get("email") as string;
         const password = data.get("password") as string;
         // 1 for dev 2 for prod
         const url = window.location.href
            .toString()
            .replace("http://localhost:3000", "1")
            .replace("paytiptap.com", "2")
            .replace("https://", "");

         console.log("email", email);
         console.log("password", password);
         if (!isValidEmail(email)) {
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? `Please enter a valid email ${new Date().toLocaleTimeString()}`
                        : `Por favor, introduzca un correo electrónico válido ${new Date().toLocaleTimeString()}`,
                     "error"
                  )
               )
            );
            return;
         }
         if (!isValidPassword(password)) {
            dispatch(
               addSnackbarMsg(
                  msg(
                     EN
                        ? `Please enter a valid password ${new Date().toLocaleTimeString()}`
                        : `Por favor, introduzca una contraseña válida ${new Date().toLocaleTimeString()}`,
                     "error"
                  )
               )
            );
            return;
         }

         dispatch(
            addSnackbarMsg(
               msg(
                  EN
                     ? `Testing Credentials... ${new Date().toLocaleTimeString()}`
                     : `Credenciales de prueba...${new Date().toLocaleTimeString()}`,
                  "info"
               )
            )
         );
         setLoading(true);
         const res: any = await apiPost(API_URL + "/user/login", {
            email,
            password,
            url,
         });

         dispatch(clearFees());
         dispatch(clearLogs());
         dispatch(clearPayments());
         dispatch(clearOtherPayments());
         dispatch(clearSchools());
         dispatch(clearSettlements());
         // //dispatch(clearSession());
         dispatch(clearSnackbar());
         dispatch(clearStudents());
         dispatch(clearUsers());
         dispatch(clearUserSchoolLinkState());
         dispatch(clearUserSchoolLinkState());
         dispatch(clearValidateOTP());
         dispatch(clearVouchers());

         const user = {
            email,
            token: res.data.token,
            ul: res.data.ul,
            name: res.data.name,
            lastLoginAt: Date.now(),
            createdAt: 1661467411061,
            id: res.data.id,
            cc: res.data.cc,
            demo: res.data.demo,
         };
         dispatch(setSession({ ...session, user }));

         dispatch(
            addSnackbarMsg(
               msg(EN ? `Login success` : `Inicio de sesión exitoso`, "success")
            )
         );
         navigate(`/dashboard`);
      } catch (error: any) {
         console.log(error);
         dispatch(
            addSnackbarMsg(
               msg(
                  EN
                     ? "Login Failed " + new Date().toLocaleTimeString()
                     : "Error de inicio de sesion " +
                          new Date().toLocaleTimeString(),
                  "error"
               )
            )
         );
         setLoading(false);
      }
   };

   React.useEffect(() => {
      // console.log('snackbar',snackbar)
   }, [snackbar, session.density]);
   return (
      <PublicCenteredForm title={EN ? `Sign in` : `Iniciar Sesión`}>
         <Box
            component='form'
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 1 }}
         >
            <FormControl fullWidth size='small'>
               <InputLabel id='demo-simple-select-label'>
                  {EN ? "Demo User" : "Seleccionar"}
               </InputLabel>
               <Select
                  defaultValue={"-"}
                  label={EN ? "Demo User" : "Seleccionar"}
                  onChange={handleChangeDemo}
               >
                  <MenuItem value='-'>
                     {EN ? "Select a Demo User" : "Demostración"}
                  </MenuItem>
                  <MenuItem value='guardian@paytiptap.com'>
                     {EN ? "Demo Guardian" : "Guardian (demo)"}
                  </MenuItem>
                  <MenuItem value='demo-school-admin@paytiptap.com'>
                     {EN ? "Demo School Admin" : "Admin/Escuela (demo)"}
                  </MenuItem>
                  <MenuItem value='demo-country-admin@paytiptap.com'>
                     {EN ? "Demo Country Admin" : "Admini/País"}
                  </MenuItem>
               </Select>
            </FormControl>
            <Divider textAlign='center' style={{ marginTop: 20 }}>
               {EN ? "OR" : "O"}
            </Divider>
            <TextField
               margin='normal'
               size='small'
               required
               fullWidth
               id='email'
               label={EN ? "Email Address" : "Correo Electrónico"}
               name='email'
               autoComplete='email'
               autoFocus
               inputRef={emailRef}
            />
            <TextField
               margin='normal'
               size='small'
               required
               fullWidth
               name='password'
               label={EN ? "Password" : "Contraseña"}
               type={showPassword ? "text" : "password"}
               id='password'
               autoComplete='current-password'
               inputRef={passwordRef}
               InputProps={{
                  endAdornment: (
                     <InputAdornment position='end'>
                        <IconButton
                           aria-label='toggle password visibility'
                           onClick={handleClickShowPassword}
                           edge='end'
                        >
                           {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />

            <Button
               type='submit'
               ref={buttonRef}
               fullWidth
               variant='contained'
               sx={{ mt: 3, mb: 2 }}
               disabled={loading}
            >
               {loading ? (
                  <CircularProgress size={24} />
               ) : EN ? (
                  "Sign In"
               ) : (
                  "Iniciar Sesión"
               )}
            </Button>
            <Grid container>
               <Button href='/forgot' size='small' fullWidth>
                  {EN ? "Forgot password?" : "¿Olvidaste tu contraseña?"}
               </Button>
               <Button href='/signup' size='small' fullWidth>
                  {EN ? "Sign Up" : "Regístrate"}
               </Button>
            </Grid>
         </Box>
      </PublicCenteredForm>
   );
}
