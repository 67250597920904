// boilerplate for tsx component

import React from "react";

interface Props {
   centered?: boolean;
}

export default function (props: Props) {
   const { centered = true } = props;
   const logoStyle = {
      height: "60px",
      margin: centered ? "auto" : 0,
      display: "block",
      padding: 4,
      borderRadius: 30,
   };
   return (
      <>
         <img src='/assets/img/logo.jpg' style={logoStyle} />
      </>
   );
}
