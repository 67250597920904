import Box from "@mui/material/Box";
import React, { useRef } from "react";

import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";

import { setDialog } from "../../features/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { dia, fetchData, msg, rand, returnDateNow } from "../../utilities/gen";
import { SessionState } from "../../features/session/session";
import { API_URL } from "../../constants/api";
import { apiPost } from "../../utilities/ApiRequests";
import { RootState } from "../../app/store";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { setFees } from "../../features/fees/feesSlice";
import { setUsers } from "../../features/users/usersSlice";
import { setStudents } from "../../features/students/studentsSlice";

interface UseAddProps {
   params: {
      additionalDisabled?: string[];
      nullByDefaultFields?: string[];
      numericFields?: string[];
      hiddenFields?: string[];
      tableName: string;
   };
}

export default function DialogAddOne(props: UseAddProps): JSX.Element {
   const {
      additionalDisabled = [],
      nullByDefaultFields = [],
      hiddenFields = [],
      tableName = "",
   } = props.params;

   const countryAdmins = useAppSelector(
      (state: any) => state.countryAdmins.arr[0]
   );
   const fees = useAppSelector((state: any) => state.fees.arr[0]);
   const users = useAppSelector((state: any) => state.users.arr[0]);
   const links = useAppSelector((state: any) => state.links.arr[0]);
   const otherPayments = useAppSelector(
      (state: any) => state.otherPayments.arr[0]
   );
   const payments = useAppSelector((state: any) => state.payments.arr[0]);
   const schoolAdmins = useAppSelector(
      (state: any) => state.schoolAdmins.arr[0]
   );
   const schools = useAppSelector((state: any) => state.schools.arr[0]);
   const settlements = useAppSelector((state: any) => state.settlements.arr[0]);
   const students = useAppSelector((state: any) => state.students.arr[0]);
   const validateOTP = useAppSelector((state: any) => state.validateOTP.arr[0]);
   const vouchers = useAppSelector((state: any) => state.vouchers.arr[0]);

   const row = useAppSelector((state: RootState) => {
      switch (tableName) {
         case "countryAdmins":
            return countryAdmins;
         case "fees":
            return fees;
         case "users":
            return users;
         case "links":
            return links;
         case "otherPayments":
            return otherPayments;
         case "payments":
            return payments;
         case "schoolAdmins":
            return schoolAdmins;
         case "schools":
            return schools;
         case "settlements":
            return settlements;
         case "students":
            return students;
         case "validateOTP":
            return validateOTP;
         case "vouchers":
            return vouchers;

         default:
            // Handle default case if needed
            return null; // or any other default value
      }
   });

   const disabled = ["id", "created_at", "addd_at", ...additionalDisabled];
   const [loading, setLoading] = React.useState(false);
   const dispatch = useAppDispatch();

   const init = useRef<boolean>(false);
   const session: SessionState = useAppSelector(
      (state: { session: any }) => state.session
   );

   const token = session.user.token;

   const handleAdd = async (e: any) => {
      try {
         e.preventDefault();
         setLoading(true);
         // add the actually database using api
         // iterate through the input fields and add the fields
         const addFields = [];
         const addValues = [];
         for (const property in row) {
            if (property.toLowerCase() === "id") {
               // Ignore the "id" field
               continue;
            } else if (
               property.toString().toLocaleLowerCase() === "updatedat" ||
               property.toString().toLocaleLowerCase() === "date_listed" ||
               property.toString().toLocaleLowerCase() === "date_expired" ||
               property.toString().toLocaleLowerCase() === "last_login" ||
               property.toString().toLocaleLowerCase() === "createdat"
            ) {
               addFields.push(property);
               // push date in the following format 2024-02-25 15:52:12
               addValues.push(returnDateNow());
            } else if (
               property.toString().toLocaleLowerCase() === "accesslevel"
            ) {
               addFields.push(property);
               addValues.push(9);
            } else if (
               property.toString().toLocaleLowerCase() === "isdeleted"
            ) {
               addFields.push(property);
               addValues.push(0);
            } else if (property.toString().toLocaleLowerCase() === "isauto") {
               addFields.push(property);
               addValues.push(0);
            } else {
               // Check if the field value is blank and it's in the nullByDefaultFields array
               const fieldValue = (
                  document.getElementById(property) as HTMLInputElement
               )?.value;
               if (
                  fieldValue === "" &&
                  nullByDefaultFields.includes(property.toLowerCase())
               ) {
                  addValues.push(null);
               } else {
                  addValues.push(fieldValue);
               }
               addFields.push(property);
            }
         }

         const res = await apiPost(API_URL + "/crud/create", {
            token,
            tableName,
            addFields,
            addValues,
         });

         setTimeout(() => setLoading(false), 5000);
         try {
            setTimeout(async () => {
               if (res.data.err) {
                  dispatch(
                     addSnackbarMsg(
                        msg(
                           "Failed: Add record to " +
                              tableName +
                              " (notify admin) " +
                              new Date(),
                           "error"
                        )
                     )
                  );
               } else {
                  dispatch(
                     addSnackbarMsg(
                        msg(
                           "Record Added to " + tableName + "|" + new Date(),
                           "success"
                        )
                     )
                  );
                  //now add the state depending on the table name

                  console.log("tablename: " + tableName);
                  switch (tableName) {
                     case "fees":
                        dispatch(addSnackbarMsg(msg("Reloading Fees", "info")));
                        dispatch(
                           setFees({
                              arr: await fetchData(tableName, token),
                              init: false,
                           })
                        );
                        break;

                     case "users":
                        dispatch(
                           addSnackbarMsg(msg("Reloading Users", "info"))
                        );
                        dispatch(
                           setUsers({
                              arr: await fetchData(tableName, token),
                              init: false,
                           })
                        );
                        break;

                     case "students":
                        dispatch(
                           addSnackbarMsg(msg("Reloading Students", "info"))
                        );
                        dispatch(
                           setStudents({
                              arr: await fetchData(tableName, token),
                              init: false,
                           })
                        );
                        break;

                     default:
                        // Handle default case if needed
                        break;
                  }
               }
            }, 2000);
         } catch (error) {
            console.log(error);
            dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
         }
         dispatch(setDialog(dia(false, "", "", {})));
      } catch (error) {
         console.log(error);
         dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   return (
      <Container component='main' maxWidth='lg' sx={{ padding: 3 }}>
         <div style={{ padding: 5 }} />
         <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <form>
               <Box sx={{ width: "100%", typography: "body1" }}>
                  <TableContainer>
                     <Table sx={{ padding: 1 }}>
                        <TableBody>
                           {row &&
                              typeof row === "object" &&
                              Object.keys(row).length > 0 &&
                              Object.keys(row).map((paramName) => {
                                 const paramValue = row[paramName];

                                 // Check if paramName is included in hiddenFields
                                 if (hiddenFields.includes(paramName)) {
                                    return null; // Return null to skip rendering this TableRow
                                 }

                                 return (
                                    <TableRow hover tabIndex={-1} key={rand()}>
                                       <TableCell>
                                          {paramName
                                             .toString()
                                             .replaceAll("_", " ")
                                             .toUpperCase()}
                                       </TableCell>
                                       <TableCell>
                                          {disabled.includes(paramName) ? (
                                             <TextField
                                                size='small'
                                                id={paramName}
                                                label={paramName}
                                                variant='outlined'
                                                disabled
                                             />
                                          ) : (
                                             <TextField
                                                size='small'
                                                id={paramName}
                                                label={paramName}
                                                variant='outlined'
                                             />
                                          )}
                                       </TableCell>
                                    </TableRow>
                                 );
                              })}
                           <TableRow>
                              <TableCell colSpan={2}>
                                 <Button
                                    onClick={(event) => handleAdd(event)}
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    sx={{
                                       mt: 1,
                                       mb: 1,
                                       height: 50,
                                    }}
                                    disabled={loading}
                                 >
                                    Add
                                 </Button>
                                 {loading && (
                                    <CircularProgress
                                       size={24}
                                       sx={{
                                          position: "absolute",
                                          top: "50%",
                                          left: "50%",
                                          marginTop: "-12px",
                                          marginLeft: "-12px",
                                       }}
                                    />
                                 )}
                              </TableCell>
                           </TableRow>
                        </TableBody>
                     </Table>
                  </TableContainer>
               </Box>
            </form>
         </Paper>
      </Container>
   );
}
