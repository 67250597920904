import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { User, UsersState } from "./users";

const initialState: UsersState = {
   arr: [],
   init: true,
};

export const usersSlice = createSlice({
   name: "users",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setUsers: (state, action: PayloadAction<UsersState>) => {
         // Clear the state before setting new users
         state.arr.splice(0, state.arr.length);
         // Push each new user into the state
         action.payload.arr.forEach((user) => {
            state.arr.push(user);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleUser: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[] | any[];
            updateValues: string[] | any[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the user with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex((user: any) => user.id === id);
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleUser: (state, action: PayloadAction<User>) => {
         console.log(action.payload);
         // Add a single user
         state.arr.push(action.payload);
      },
      clearUsers: (state) => initialState,
   },
});

export const { setUsers, clearUsers, updateSingleUser, addSingleUser } =
   usersSlice.actions;
export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
