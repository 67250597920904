import { setDialog } from "../../features/dialog/dialogSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SessionState } from "../../features/session/session";
import { Button, Tooltip } from "@mui/material";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { fetchData, msg } from "../../utilities/gen";
import { Schools, SchoolsState } from "../../features/schools/schools";
import { setSchools } from "../../features/schools/schoolsSlice";
import React from "react";
import Loading from "../Loading";

interface SchoolClickProps {
   schoolId: number;
}

export default function ViewSchoolClick(props: SchoolClickProps): JSX.Element {
   const dispatch = useAppDispatch();
   const { schoolId } = props;

   let data: Schools[] = useAppSelector((state: any) => state.schools.arr);
   const session: SessionState = useAppSelector((state: any) => state.session);
   const EN = session.lang === "en" ? true : false;

   const handleView = async (id: number) => {
      // check to see if schools state is loaded
      if (data.length === 0) {
         await fetchData("schools", session.user.token)
            .then((data) => {
               dispatch(setSchools(data));
            })
            .catch((error) => {
               console.error("Error in ViewSchoolClick: ", error);
            });
      }
      console.log(data);
      const viewRow = data.filter((a: any) => a.id === id);
      console.log("View School: ", viewRow[0]);
      dispatch(
         setDialog({
            open: true,
            title: `View School`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "schools",
            },
         })
      );
      dispatch(
         addSnackbarMsg(
            msg(EN ? `View School: ${id}` : `Ver escuela ${id}`, "info")
         )
      );
   };

   return (
      <>
         <Tooltip
            title={EN ? `View School info` : `Ver información de la escuela`}
         >
            <Button
               size='small'
               sx={{ mx: -0.625, p: 0 }} // Equivalent to -5px for mx (margin x-axis)
               onClick={() => handleView(schoolId as number)}
            >
               {schoolId}
            </Button>
         </Tooltip>
      </>
   );
}
