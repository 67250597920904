import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Schools, SchoolsState } from "./schools";

const initialState: SchoolsState = {
   arr: [],
   init: true,
};

export const schoolsSlice = createSlice({
   name: "schools",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setSchools: (state, action: PayloadAction<SchoolsState>) => {
         // Clear the state before setting new schools
         state.arr.splice(0, state.arr.length);
         // Push each new school into the state
         action.payload.arr.forEach((school) => {
            state.arr.push(school);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleSchool: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the school with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (school: Schools) => school.id === id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleSchool: (state, action: PayloadAction<Schools>) => {
         // Add a single school to the state
         state.arr.push(action.payload);
      },
      clearSchools: () => initialState,
   },
});

export const { setSchools, updateSingleSchool, clearSchools, addSingleSchool } =
   schoolsSlice.actions;
export const selectSchools = (state: RootState) => state.schools;

export default schoolsSlice.reducer;
