import * as React from "react";
import {
   Grid,
   Container,
   Box,
   Link,
   IconButton,
   FormControl,
   MenuItem,
   Select,
} from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "../../App.css";
import Logo from "../../components/Logo";
import { darkTheme, lightTheme } from "../../components/themes/Theme";
import { SessionState } from "../../features/session/session";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
   setSession,
   setToggleDarkMode,
   setToggleLang,
} from "../../features/session/sessionSlice";
import SnackStack from "../Snackbar/SnackStack";
import Copyright from "../../pages/Public/CopyRight";

const defaultTheme = createTheme();

interface PublicTemplateProps {
   children: any;
   title?: string;
}

const PublicTemplate = (props: PublicTemplateProps) => {
   const { children, title = "Tiptap Inc" } = props;
   const session: SessionState = useAppSelector((state: any) => state.session);
   const snackbar = useAppSelector((state: any) => state.snackbar);
   const dispatch = useAppDispatch();
   const EN = session.lang === "en" ? true : false;

   const handleChangeLang = () => {
      try {
         dispatch(setToggleLang());
      } catch (error) {
         console.error("Error in ResponsiveAppBar.tsx: ", error);
      }
   };

   const toggleDM = () => dispatch(setToggleDarkMode());

   return (
      <ThemeProvider theme={session.darkMode ? darkTheme : lightTheme}>
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               minHeight: "100vh",
               width: "100%",
            }}
         >
            {/* Top Row */}
            <Grid
               container
               style={{
                  backgroundColor: session.darkMode ? "#222" : "#fff",
                  color: session.darkMode ? "#eee" : "#222",
                  boxShadow: "0px 0px 5px 0px #000000",
                  width: "100%",
                  height: "70px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "0 7px",
               }}
            >
               <Grid item xs={6} sm={6}>
                  {/* Content on the left */}
                  <Box>
                     <Logo centered={false} />
                  </Box>
               </Grid>
               <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{
                     display: "flex",
                     justifyContent: { xs: "center", sm: "flex-end" },
                  }}
               >
                  {/* Content on the right */}
                  <FormControl>
                     <Select
                        value={session.lang ? session.lang : "es"}
                        size='small'
                        onChange={handleChangeLang}
                     >
                        <MenuItem value='en'>EN</MenuItem>
                        <MenuItem value='es'>ES</MenuItem>
                     </Select>
                  </FormControl>
                  <IconButton aria-haspopup='true' onClick={toggleDM}>
                     {session.darkMode ? (
                        <LightModeIcon style={{ color: "#bbb" }} />
                     ) : (
                        <DarkModeIcon style={{ color: "#333" }} />
                     )}
                  </IconButton>
               </Grid>
            </Grid>

            {/* Middle Row */}
            <Container
               maxWidth='md'
               sx={{
                  flex: "1 0 auto", // Allows the middle row to grow and take available space
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "16px",
                  paddingBottom: "16px",
               }}
            >
               {/* Centered Content */}
               <Box>{children}</Box>
            </Container>

            {/* Bottom Row */}
            <Grid
               container
               sx={{
                  backgroundColor: session.darkMode ? "#222" : "#ddd",
                  color: session.darkMode ? "#999" : "#222",
                  padding: 1,
                  height: "70px",
                  mt: "auto", // Pushes the bottom row to the bottom if there's space
               }}
            >
               <Grid item xs={6} sm={6}>
                  {/* Content on the left */}
                  <Box>
                     <Link
                        href='https://paytiptap.com/en/terms-and-conditions.html'
                        variant='body2'
                     >
                        {EN
                           ? "Terms and Conditions"
                           : "Condiciones de servicio"}
                     </Link>
                  </Box>
               </Grid>
               <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{
                     display: "flex",
                     justifyContent: { xs: "center", sm: "flex-end" },
                  }}
               >
                  {/* Content on the right */}
                  <Box>
                     <Copyright sx={{ mt: 5 }} />
                     <br /> ver0.1.20
                  </Box>
               </Grid>
            </Grid>
         </Box>
         {snackbar.msg !== "Welcome" && <SnackStack />}
      </ThemeProvider>
   );
};

export default PublicTemplate;
