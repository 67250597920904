import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridToolbar,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Log, LogsState } from "../../../features/logs/logs";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
   IconButton,
   Typography,
   Box,
   CircularProgress,
   Button,
} from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useState } from "react";
import EmailClick from "../../../components/EmailClick";
import ReplayIcon from "@mui/icons-material/Replay";
import { fetchData } from "../../../utilities/gen";
import { setLogs } from "../../../features/logs/logsSlice";

export default function LogsGrid() {
   const dispatch = useAppDispatch();
   const logs: LogsState = useAppSelector((state: any) => state.logs);
   const session = useAppSelector((state: any) => state.session);

   const rows: Log[] = logs.arr;
   const EN = session.lang === "en" ? true : false;
   const [filteredRows, setFilteredRows] = useState(rows);
   const [loading, setLoading] = useState(true); // Add loading state

   const handleView = (id: string) => {
      const viewRow = logs.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View Logs` : "Ver Tutores",
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "logs",
            },
         })
      );
   };
   const refresh = async () => {
      setLoading(true);
      try {
         const data = await fetchData("logs", session.user.token);
         dispatch(setLogs({ arr: data, init: true }));
      } catch (error) {
         console.error("Failed to load transactions data:", error);
      } finally {
         setLoading(false);
      }
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 110,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => {
            return (
               <>
                  <IconButton
                     aria-label='view'
                     size='small'
                     color='primary'
                     onClick={() => handleView(params.row.id)}
                  >
                     <RemoveRedEyeIcon fontSize='inherit' />
                  </IconButton>
               </>
            );
         },
      },
      {
         field: "id",
         headerName: "ID",
         width: 60,
      },
      {
         field: "ip_address",
         headerName: EN ? "IP" : "IP",
         width: 120,
         renderCell: (params) => {
            const ip = params.value;

            // Open the URL in a new tab when the user clicks on the IP address
            const handleClick = () => {
               window.open(`https://ipinfo.io/${ip}`, "_blank");
            };

            return (
               <Typography
                  variant='body2'
                  color='primary'
                  onClick={handleClick}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
               >
                  {ip}
               </Typography>
            );
         },
      },
      {
         field: "url",
         headerName: EN ? "Location" : "Ubicación",
         width: 200,
         renderCell: (params) => {
            const url = params.value.toString().trim();
            let message = "";

            switch (url) {
               case "d-1":
                  message = EN ? "User not found" : "Usuario no encontrado";
                  break;
               case "d-2":
                  message = EN ? "User disabled" : "Usuario deshabilitado";
                  break;
               case "d-3":
                  message = EN ? "Invalid password" : "Contraseña inválida";
                  break;
               case "d":
                  message = EN ? "dashboard" : "tablero";
                  break;
               default:
                  message = url;
                  break;
            }

            return url.toString().includes("-") ? (
               <Typography color='secondary' sx={{ mt: 1 }}>
                  {message}
               </Typography>
            ) : (
               url
            );
         },
      },
      {
         field: "success",
         headerName: EN ? "Success" : "Éxito",
         width: 100,
      },
      {
         field: "email",
         headerName: EN ? "Email" : "Correo",
         width: 100,
         renderCell: (params) =>
            params && params.value && params.value.toString().length > 5 ? (
               <EmailClick email={params.value} />
            ) : (
               ""
            ),
      },
      {
         field: "datestamp",
         headerName: EN ? "Date/Time" : "Fecha/Hora",
         width: 180,
      },
   ];

   React.useEffect(() => {
      // Simulate data loading
      setTimeout(() => {
         setFilteredRows(logs.arr);
         setLoading(false); // Set loading to false after data is loaded
      }, 500); // Adjust the timeout duration as necessary
   }, [logs.arr]);

   if (loading) {
      return (
         <DashboardTemplate title={EN ? "Logs" : "Usuarios"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Logs" : "Usuarios"}>
         <div
            className='dataGridContainer'
            id={"j" + new Date().getMilliseconds}
         >
            <Button
               variant='outlined'
               startIcon={<ReplayIcon />}
               onClick={() => refresh()}
               sx={{ marginBottom: 2 }}
            >
               {EN ? "Refresh Data" : "Actualizar Datos"}
            </Button>
            <DataGrid
               key={"i" + new Date().getMilliseconds()}
               slots={{ toolbar: GridToolbar }}
               getRowId={(row) => row.id}
               rows={filteredRows}
               columns={columns}
               initialState={{
                  pagination: {
                     paginationModel: {
                        pageSize: 10,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               disableRowSelectionOnClick
               getRowClassName={(params) =>
                  params.row.is_active === false ? "inactiveRow" : ""
               }
            />
         </div>
      </DashboardTemplate>
   );
}
