import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Students, StudentsState } from "./students";

const initialState: StudentsState = {
   arr: [],
   init: true,
};

export const studentsSlice = createSlice({
   name: "students",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setStudents: (state, action: PayloadAction<StudentsState>) => {
         // Clear the state before setting new students
         state.arr.splice(0, state.arr.length);
         // Push each new student into the state
         action.payload.arr.forEach((student) => {
            state.arr.push(student);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleStudent: (
         state: any,
         action: PayloadAction<{
            document: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the student with the id and update the fields
         const { document, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (student: Students) => student.document === document
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleStudent: (state, action: PayloadAction<Students>) => {
         // Add a single student to the state
         state.arr.push(action.payload);
      },
      clearStudents: (state) => initialState,
   },
});

export const {
   setStudents,
   updateSingleStudent,
   clearStudents,
   addSingleStudent,
} = studentsSlice.actions;
export const selectStudents = (state: RootState) => state.students;

export default studentsSlice.reducer;
