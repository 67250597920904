import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { storePromise } from "./app/store";
import { BrowserRouter } from "react-router-dom";

storePromise.then((store) => {
   const container = document.getElementById("root");
   const root = createRoot(container!); // Create root using React 18's `createRoot` API
   root.render(
      <Provider store={store}>
         <BrowserRouter>
            <App />
         </BrowserRouter>
      </Provider>
   );
});
