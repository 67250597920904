import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { DialogState } from "../../features/dialog/dialog";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { clearDialog } from "../../features/dialog/dialogSlice";
import DialogEditOne from "./EditOne";
import DialogViewOne from "./ViewOne";
import NoneSpecified from "./NoneSpecified";
import DialogAddOne from "./AddOne";

import FeesGrid from "../../pages/Protected/Fees/Grid";
import WhatsAppMsg from "./WhatsAppMsg";
import EmailMsg from "./EmailMsg";
import AddSchool from "./AddEditSchool";
import AddUser from "./AddEditUser";
import AddEditUser from "./AddEditUser";
import AddEditStudent from "./AddEditStudents";
import AddEditSchool from "./AddEditSchool";
import StudentGuardianLink from "./StudentGuardianLink";
import AddFees from "./AddFees";
import AddPayment from "./AddPayment";

const Transition = React.forwardRef(function Transition(
   props: TransitionProps & {
      children: React.ReactElement<any, any>;
   },
   ref: React.Ref<unknown>
) {
   return <Slide direction='up' ref={ref} {...props} />;
});

export default React.memo((): JSX.Element => {
   const dispatch = useAppDispatch();
   const dialog: DialogState = useAppSelector((state) => state.dialog);
   const { open, content, params } = dialog;

   const handleClose = () => {
      dispatch(clearDialog());
   };

   console.log(dialog);

   const compPicker = (content: string, params: any): any => {
      switch (content) {
         case "View":
            return <DialogViewOne params={params} />;
         case "Edit":
            return <DialogEditOne params={params} />;
         case "Add":
            return <DialogAddOne params={params} />;
         case "Fees":
            return <FeesGrid params={params} />;
         case "WhatsApp":
            return <WhatsAppMsg params={params} />;
         case "Email":
            return <EmailMsg params={params} />;
         case "AddSchool":
            return <AddSchool params={params} />;
         case "AddUser":
            return <AddEditUser params={params} />;
         case "EditUser":
            return <AddEditUser params={params} />;
         case "AddStudent":
            return <AddEditStudent params={params} />;
         case "EditStudent":
            return <AddEditStudent params={params} />;
         case "AddSchool":
            return <AddEditSchool params={params} />;
         case "EditSchool":
            return <AddEditSchool params={params} />;
         case "StudentGuardianLink":
            return <StudentGuardianLink params={params} />;
         case "AddFees":
            return <AddFees params={params} />;
         case "AddPayment":
            return <AddPayment params={params} />;

         default:
            return <NoneSpecified />;
      }
   };

   return (
      <>
         <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth='xl'
         >
            {compPicker(content, params)}
         </Dialog>
      </>
   );
});
