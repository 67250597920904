import React, { useState } from "react";
import {
   Button,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Container,
   Box,
   CircularProgress,
   Typography,
   TextField,
   InputAdornment,
   TableContainer,
   Table,
   TableHead,
   TableRow,
   Paper,
   TableCell,
   TableBody,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { apiPost } from "../../utilities/ApiRequests";
import { API_URL } from "../../constants/api";
import { closeDialog } from "../../features/dialog/dialogSlice";
import { msg } from "../../utilities/gen";
import { addSinglePayment } from "../../features/payments/paymentsSlice";
import { Fee } from "../../features/fees/fees";

interface AddPaymentProps {
   params: {
      EN: boolean;
      DEMO: boolean;
      row: Fee;
   };
}

const AddPayment = (props: AddPaymentProps) => {
   const { EN, DEMO, row } = props.params;
   console.log(row);
   const dispatch = useAppDispatch();
   const session = useAppSelector((state: any) => state.session);
   const token = session.user.token;

   const [amount, setAmount] = useState<number>(0);
   const [paymentDate, setPaymentDate] = useState<string>("");
   const [paymentMethod, setPaymentMethod] = useState<string>("");
   const [comment, setComment] = useState<string>("");
   const [loading, setLoading] = useState(false);
   const [showTable, setShowTable] = useState<boolean>(false);

   const toggleTableVisibility = () => {
      setShowTable(!showTable);
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setLoading(true);
      console.log("Submitting payment...");

      try {
         const paymentObject = {
            token,
            fee_id: row.id,
            id_appsheet: null, // Assuming this is not used or can be null
            payment_date: paymentDate,
            student_name: row.student_name,
            school_id: row.school_id,
            payment_method: paymentMethod,
            student_id: row.student_id,
            amount_paid: amount,
            concept: "Payment for " + row.installment_name, // or any other concept
            comment: "fee.id" + row.id,
            applied: true,
            payment_proof: null, // If not used, set it to null or include logic to handle this
            guardian_id: row.guardian_id,
            registration_date: new Date().toISOString().split("T")[0], // today's date in YYYY-MM-DD format
            demo: row.demo,
         };
         console.log(paymentObject);
         const res = await apiPost(API_URL + "/payments/add", paymentObject);

         //Assume res.data.payment is the new payment added
         dispatch(addSinglePayment(res.data.payment));

         dispatch(
            addSnackbarMsg(
               msg(
                  EN ? "Payment added successfully" : "Pago agregado con éxito",
                  "success"
               )
            )
         );
         dispatch(
            addSnackbarMsg(
               msg(EN ? "Under Contruction" : "En Construcción", "info")
            )
         );

         setLoading(false);
         dispatch(closeDialog());
      } catch (error: any) {
         setLoading(false);
         console.error(error);
         dispatch(
            addSnackbarMsg(
               msg(
                  EN
                     ? `Error: ${new Date().toLocaleString()}`
                     : `Error: ${new Date().toLocaleString()}`,
                  "error"
               )
            )
         );
      }
   };

   const keysToShow = [
      "student_id",
      "school_id",
      "guardian_id",
      "total_due",
      "installment_name",
   ];

   return (
      <Container component='main' maxWidth='sm' sx={{ padding: 2 }}>
         <Typography variant='h5' gutterBottom>
            {EN ? "Add Payment" : "Agregar Pago"}
         </Typography>
         <Container component='main' maxWidth='sm' sx={{ padding: 2 }}>
            <Button
               variant='contained'
               onClick={toggleTableVisibility}
               sx={{ mb: 2 }}
            >
               {showTable
                  ? EN
                     ? "Hide Details"
                     : "Ocultar Detalles"
                  : EN
                  ? "Show Details"
                  : "Mostrar Detalles"}
            </Button>

            {showTable && (
               <TableContainer component={Paper}>
                  <Table>
                     <TableHead>
                        <TableRow>
                           <TableCell>
                              <Typography variant='subtitle1' fontWeight='bold'>
                                 {EN ? "Field" : "Campo"}
                              </Typography>
                           </TableCell>
                           <TableCell>
                              <Typography variant='subtitle1' fontWeight='bold'>
                                 {EN ? "Value" : "Valor"}
                              </Typography>
                           </TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {Object.entries(row)
                           .filter(([key]) => keysToShow.includes(key))
                           .map(([key, value]) => (
                              <TableRow key={key}>
                                 <TableCell component='th' scope='row'>
                                    <Typography variant='body2'>
                                       {key}
                                    </Typography>
                                 </TableCell>
                                 <TableCell>
                                    <Typography variant='body2'>
                                       {value}
                                    </Typography>
                                 </TableCell>
                              </TableRow>
                           ))}
                     </TableBody>
                  </Table>
               </TableContainer>
            )}
         </Container>
         <Box sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit} autoComplete='off'>
               <TextField
                  label={EN ? "Amount" : "Monto"}
                  value={amount}
                  size='small'
                  onChange={(event) => setAmount(Number(event.target.value))}
                  fullWidth
                  margin='normal'
                  InputProps={{
                     startAdornment: (
                        <InputAdornment position='start'>$</InputAdornment>
                     ),
                  }}
               />

               <TextField
                  label={EN ? "Payment Date" : "Fecha de Pago"}
                  type='date'
                  value={paymentDate}
                  size='small'
                  onChange={(event) => setPaymentDate(event.target.value)}
                  fullWidth
                  margin='normal'
                  InputLabelProps={{
                     shrink: true,
                  }}
               />

               <FormControl fullWidth margin='normal'>
                  <InputLabel>
                     {EN ? "Payment Method" : "Método de Pago"}
                  </InputLabel>
                  <Select
                     value={paymentMethod}
                     size='small'
                     onChange={(event) => setPaymentMethod(event.target.value)}
                     label={EN ? "Payment Method" : "Método de Pago"}
                  >
                     {[
                        "Directo a Escuela",
                        "ACH",
                        "Tarjeta Fisica",
                        "Cheque",
                        "App Tiptap",
                        "Recurrencia",
                        "App Tiptap",
                        "Efectivo",
                     ].map((method) => (
                        <MenuItem key={method} value={method}>
                           {method}
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>

               <TextField
                  label={EN ? "Comment" : "Comentario"}
                  value={comment}
                  size='small'
                  onChange={(event) => setComment(event.target.value)}
                  fullWidth
                  margin='normal'
                  multiline
                  rows={4}
               />

               <Button
                  size='small'
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
               >
                  {loading ? (
                     <CircularProgress size={24} />
                  ) : EN ? (
                     "Submit"
                  ) : (
                     "Enviar"
                  )}
               </Button>
            </form>
         </Box>
      </Container>
   );
};

export default AddPayment;
