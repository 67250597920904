import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { Vouchers, VouchersState } from "./vouchers";

const initialState: VouchersState = {
   arr: [],
   init: true,
};

export const vouchersSlice = createSlice({
   name: "vouchers",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setVouchers: (state, action: PayloadAction<VouchersState>) => {
         // Clear the state before setting new vouchers
         state.arr.splice(0, state.arr.length);
         // Push each new voucher into the state
         action.payload.arr.forEach((voucher) => {
            state.arr.push(voucher);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleVouchers: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the voucher with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (voucher: Vouchers) => voucher.id === id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleVouchers: (state, action: PayloadAction<Vouchers>) => {
         // Add a single voucher to the state
         state.arr.push(action.payload);
      },
      clearVouchers: (state) => initialState,
   },
});

export const {
   setVouchers,
   updateSingleVouchers,
   clearVouchers,
   addSingleVouchers,
} = vouchersSlice.actions;
export const selectVouchers = (state: RootState) => state.vouchers;

export default vouchersSlice.reducer;
