import * as React from "react";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridTreeNodeWithRender,
   GridToolbar, // Import the GridFilterModel type
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { Fee, FeesState } from "../../../features/fees/fees";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";

import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Box, CircularProgress, IconButton, Tooltip } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { useEffect } from "react";
import { fetchData } from "../../../utilities/gen";
import { setFees } from "../../../features/fees/feesSlice";

const formatDate = (dateString: number | string) => {
   return dateString;
};

interface FeesGridProps {
   params?: {
      columnField?: string;
      searchTerm?: string;
      title?: string | number;
   };
}

export default function FeesGrid(props: FeesGridProps) {
   const dispatch = useAppDispatch();
   const fees: FeesState = useAppSelector((state: any) => state.fees);
   const session = useAppSelector((state: any) => state.session);
   const [loading, setLoading] = React.useState(true);

   const rows = fees.arr;
   const EN = session.lang === "en" ? true : false;
   const DEMO = session.user.demo;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("fees", session.user.token); // Replace with actual table name and token
               dispatch(setFees({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load fees data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (id: string) => {
      const editRow = fees.arr.filter((a: any) => a.id === id);
      console.log(editRow[0]);
      dispatch(
         setDialog({
            open: true,
            title: "Edit Fee",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "fees",
            },
         })
      );
      // Handle edit action
   };

   const handleFees = (row: Fee) => {
      //const editRow = students.arr.filter((a: any) => a.document === document);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Fees" : "Cuotas",
            content: "Fees",
            params: {
               columnField: "student_id",
               searchTerm: row.student_id,
               title: row.student_name,
            },
         })
      );
      // Handle edit action
   };

   const handleView = (id: string) => {
      const viewRow = fees.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: `View Fees`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "fees",
            },
         })
      );
   };
   const handleMakePayment = (row: Fee) => {
      dispatch(
         setDialog({
            open: true,
            title: `Make Payment`,
            content: "AddPayment",
            params: {
               DEMO,
               EN,
               row,
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "",
         width: 180,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <Tooltip title={EN ? "Edit" : "Editar"} arrow>
                  <IconButton
                     aria-label='edit'
                     size='small'
                     color='primary'
                     onClick={() => handleEdit(params.row.id)}
                  >
                     <EditIcon fontSize='inherit' />
                  </IconButton>
               </Tooltip>
               <Tooltip title={EN ? "View" : "Ver"} arrow>
                  <IconButton
                     aria-label='view'
                     size='small'
                     color='primary'
                     onClick={() => handleView(params.row.id)}
                  >
                     <RemoveRedEyeIcon fontSize='inherit' />
                  </IconButton>
               </Tooltip>
               <Tooltip
                  title={EN ? "Associated Fees" : "Tarifas asociadas"}
                  arrow
               >
                  <IconButton
                     aria-label='delete'
                     size='small'
                     color='primary'
                     onClick={() => handleFees(params.row)}
                  >
                     <CurrencyExchangeIcon />
                  </IconButton>
               </Tooltip>
               <Tooltip title={EN ? "Make Payment" : "Realizar pago"} arrow>
                  <IconButton
                     aria-label='delete'
                     size='small'
                     color='primary'
                     onClick={() => handleMakePayment(params.row)}
                  >
                     <AttachMoneyIcon />
                  </IconButton>
               </Tooltip>
            </>
         ),
      },
      {
         field: "student_id",
         headerName: EN ? "Student ID" : "ID Estudiante",
         width: 150,
      },
      { field: "id", headerName: "ID", width: 150 }, // Assuming ID is displayed
      { field: "quota", headerName: "#", width: 20 },
      {
         field: "installment_name",
         headerName: EN ? "Name" : "Nombre",
         width: 130,
      },
      {
         field: "due_date",
         headerName: EN ? "Due Date" : "Fecha/Vencimiento",
         width: 110,
         valueFormatter: (params: any) => formatDate(params.value),
      },
      {
         field: "payment_status",
         headerName: EN ? "Status" : "Estado",
         width: 80,
      },
      {
         field: "monthly_amount",
         headerName: EN ? "Monthly" : "Mensual",
         width: 80,
      },
      {
         field: "total_due",
         headerName: EN ? "Total Due" : "Debido",
         width: 80,
      },
      {
         field: "amount_paid",
         headerName: EN ? "Amount Paid" : "Pagado",
         width: 80,
      },
      {
         field: "late_payment",
         headerName: EN ? "Late" : "Tarde",
         width: 60,
         valueFormatter: (params: any) => (params.value ? "Yes" : "No"),
      },
      {
         field: "partial_payment",
         headerName: EN ? "Partial" : "Parcial",
         width: 60,
         valueFormatter: (params: any) => (params.value ? "Yes" : "No"),
      },
      {
         field: "payment_method",
         headerName: EN ? "Pay Method" : "Método de pago",
         width: 130,
      },
      {
         field: "payment_date",
         headerName: EN ? "Payment Date" : "Fecha de pago",
         width: 110,
         valueFormatter: (params: any) => {
            if (!params || params.value === null) {
               return "Not Paid Yet"; // Or any message you prefer for null values
            } else {
               return formatDate(params.value);
            }
         },
      },
      {
         field: "student_name",
         headerName: EN ? "Student Name" : "Nombre del estudiante",
         width: 180,
      },
      {
         field: "comment",
         headerName: EN ? "Comment" : "Comentario",
         width: 255,
      },
      {
         field: "school_id",
         headerName: EN ? "Sc/ID" : "Escu/ID",
         width: 50,
      }, // Assuming school_id is displayed
      {
         field: "user_id",
         headerName: EN ? "Gu/ID" : "Tutor/ID",
         width: 50,
      }, // Assuming user_id is displayed
      {
         field: "late_fee_total",
         headerName: EN ? "Late Fee" : "Cargo por demora",
         width: 100,
         renderCell: (params: GridRenderCellParams) => (
            <div
               style={{
                  backgroundColor:
                     parseFloat(params.value) > 0
                        ? "rgba(255, 0, 0, 0.3)"
                        : "inherit",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               {params.value}
            </div>
         ),
      },
      {
         field: "discount",
         headerName: EN ? "Discount" : "Descuento",
         width: 100,
         renderCell: (params: GridRenderCellParams) => (
            <div
               style={{
                  backgroundColor:
                     parseFloat(params.value) > 0
                        ? "rgba(0, 255,0, 0.3)"
                        : "inherit",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
               }}
            >
               {params.value}
            </div>
         ),
      },
      {
         field: "subtotal",
         headerName: EN ? "Subtotal" : "Total parcial",
         width: 120,
      },
      {
         field: "has_late_fees",
         headerName: EN ? "Late Fees" : "Cargos por demora",
         width: 120,
         valueFormatter: (params: any) => (params.value ? "Yes" : "No"),
      },
   ];
   if (loading) {
      return (
         <DashboardTemplate title={"Fees Grid View"}>
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }
   // React.useEffect(() => {
   //    // reload if fees state changes
   // }, [fees.arr, session.density]);

   try {
      return props.params?.columnField && props.params?.searchTerm ? (
         <div style={{ padding: 5 }}>
            ID{props.params.searchTerm}
            {props.params.title ? " " + props.params.title : ""}
            <DataGrid
               slots={{ toolbar: GridToolbar }}
               getRowId={(row) => row.id}
               rows={rows}
               columns={columns}
               initialState={{
                  filter: {
                     filterModel: {
                        items: [
                           {
                              field: props.params.columnField,
                              operator: "equals",
                              value: props.params.searchTerm,
                           },
                        ],
                     },
                  },
                  pagination: {
                     paginationModel: {
                        pageSize: 10,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               getRowClassName={(params) =>
                  parseFloat(params.row.total_due) === 0 ? "paidRow" : ""
               }
            />
         </div>
      ) : (
         <DashboardTemplate title={"Fees Grid View"}>
            <DataGrid
               slots={{ toolbar: GridToolbar }}
               getRowId={(row) => row.id}
               rows={rows}
               columns={columns}
               initialState={{
                  pagination: {
                     paginationModel: {
                        pageSize: 15,
                     },
                  },
               }}
               pageSizeOptions={[5, 10, 15, 20, 25, 50]}
               getRowClassName={(params) =>
                  parseFloat(params.row.total_due) === 0 ? "paidRow" : ""
               }
            />
         </DashboardTemplate>
      );
   } catch (error) {
      console.error("An error occurred:", error);
      return (
         <div>
            <h2>Error</h2>
            <p>
               Something went wrong while rendering the data grid. Please try
               again later.
            </p>
         </div>
      );
   }
}
