import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
   DataGrid,
   GridColDef,
   GridRenderCellParams,
   GridToolbar,
   GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import DashboardTemplate from "../../../components/TemplateAdmin/DashboardTemplate";
import CircularProgress from "@mui/material/CircularProgress";
import EditIcon from "@mui/icons-material/Edit";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";
import { setDialog } from "../../../features/dialog/dialogSlice";
import { PaymentsState } from "../../../features/payments/payments";
import { setPayments } from "../../../features/payments/paymentsSlice";
import { fetchData } from "../../../utilities/gen";

export default function PaymentsGrid() {
   const dispatch = useAppDispatch();
   let payments: PaymentsState = useAppSelector((state: any) => state.payments);
   const session = useAppSelector((state: any) => state.session);

   const [loading, setLoading] = useState(true);
   const rows = payments.arr;
   const EN = session.lang === "en" ? true : false;

   useEffect(() => {
      const loadData = async () => {
         if (rows.length === 0) {
            try {
               const data = await fetchData("payments", session.user.token); // Replace with actual table name and token
               dispatch(setPayments({ arr: data, init: true }));
            } catch (error) {
               console.error("Failed to load payments data:", error);
            } finally {
               setLoading(false);
            }
         } else {
            setLoading(false);
         }
      };

      loadData();
   }, [rows.length, dispatch, session.token]);

   const handleEdit = (id: number) => {
      const editRow = payments.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? "Edit Payment" : "Editar Pago",
            content: "Edit",
            params: {
               row: editRow[0],
               tableName: "payments",
            },
         })
      );
   };

   const handleView = (id: number) => {
      const viewRow = payments.arr.filter((a: any) => a.id === id);
      dispatch(
         setDialog({
            open: true,
            title: EN ? `View Payment` : `Ver Pago`,
            content: "View",
            params: {
               row: viewRow[0],
               tableName: "payments",
            },
         })
      );
   };

   const columns: GridColDef[] = [
      {
         field: "actions",
         headerName: "Actions",
         width: 120,
         renderCell: (
            params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
         ) => (
            <>
               <IconButton
                  aria-label='edit'
                  size='small'
                  color='primary'
                  onClick={() => handleEdit(params.row.id)}
               >
                  <EditIcon fontSize='inherit' />
               </IconButton>
               <IconButton
                  aria-label='view'
                  size='small'
                  color='primary'
                  onClick={() => handleView(params.row.id)}
               >
                  <RemoveRedEyeIcon fontSize='inherit' />
               </IconButton>
            </>
         ),
      },
      { field: "payment_id", headerName: "ID", width: 110 },
      { field: "id_appsheet", headerName: "Appsheet", width: 110 },
      {
         field: "payment_date",
         headerName: EN ? "Payment Date" : "Fecha de Pago",
         width: 120,
         valueFormatter: (params: any) => params.value,
      },
      {
         field: "student_name",
         headerName: EN ? "Student Name" : "Nombre/estudiante",
         width: 200,
      },
      { field: "student_id", headerName: EN ? "St/ID" : "Est/ID", width: 100 },
      { field: "school_id", headerName: EN ? "Sc/ID" : "Escula/ID", width: 50 },
      {
         field: "payment_method",
         headerName: EN ? "P/Method" : "Método",
         width: 110,
      },
      {
         field: "amount_paid",
         headerName: EN ? "Amount Paid" : "Cantidad pagada",
         width: 110,
      },
      { field: "concept", headerName: EN ? "Concept" : "Concepto", width: 120 },
      {
         field: "comment",
         headerName: EN ? "Comment" : "Comentario",
         width: 205,
      },
      {
         field: "applied",
         headerName: EN ? "Applied" : "Aplicado",
         width: 60,
         valueFormatter: (params: any) =>
            params && params.value !== null
               ? params.value
                  ? "Yes"
                  : "No"
               : "-",
      },
      {
         field: "payment_proof",
         headerName: EN ? "Payment Proof" : "Prueba",
         width: 120,
      },
      { field: "user_id", headerName: "User ID", width: 150 },
      {
         field: "registration_date",
         headerName: EN ? "Registration Date" : "Fecha de Registro",
         width: 180,
         valueFormatter: (params: any) => (params.value ? params.value : "-"),
      },
   ];

   if (loading) {
      return (
         <DashboardTemplate
            title={EN ? "Payments Grid View" : "Vista de Pagos"}
         >
            <Box
               display='flex'
               justifyContent='center'
               alignItems='center'
               height='100%'
            >
               <CircularProgress />
            </Box>
         </DashboardTemplate>
      );
   }

   return (
      <DashboardTemplate title={EN ? "Payments Grid View" : "Vista de Pagos"}>
         <DataGrid
            slots={{ toolbar: GridToolbar }}
            rows={rows}
            columns={columns}
            getRowId={(row) => row.payment_id}
            initialState={{
               pagination: {
                  paginationModel: {
                     pageSize: 15,
                  },
               },
            }}
            pageSizeOptions={[5, 10, 15, 20, 25, 50]}
            disableRowSelectionOnClick
         />
      </DashboardTemplate>
   );
}
