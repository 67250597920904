import { Link, Typography } from "@mui/material";

export default function Copyright(props: any) {
   return (
      <span {...props}>
         {"Copyright © "}
         <Link color='inherit' href='https://paytiptap.com/'>
            Tiptap
         </Link>{" "}
         {new Date().getFullYear()}
         {"."}
      </span>
   );
}
