import React, { useRef, useState, useEffect } from "react";
import {
   TextField,
   Button,
   Select,
   MenuItem,
   FormControl,
   InputLabel,
   Container,
   Box,
   InputAdornment,
   IconButton,
   CircularProgress,
   Alert,
   Typography,
   Divider,
   ListItemText,
   ListItemIcon,
} from "@mui/material";
import { Schools } from "../../features/schools/schools";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { SessionState } from "../../features/session/session";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { generateName, msg, rand, updateUserByID } from "../../utilities/gen";
import { User } from "../../features/users/users";
import { apiPost } from "../../utilities/ApiRequests"; // Import apiPut for update requests
import { API_URL } from "../../constants/api";
import {
   addSingleUser,
   updateSingleUser,
} from "../../features/users/usersSlice"; // Import updateUser action
import { closeDialog } from "../../features/dialog/dialogSlice";
import { areaCodes } from "../../constants/data/areaCodes";

interface AddSchoolProps {
   params: {
      EN: boolean | any;
      USER_LEVEL: number | any;
      DEMO: boolean | any;
      id?: number;
   };
}

const AddEditUser = (props: AddSchoolProps) => {
   const dispatch = useAppDispatch();
   const { EN, USER_LEVEL, DEMO, id } = props.params;
   const schools: Schools[] = useAppSelector((state: any) => state.schools.arr);
   const users: User[] = useAppSelector((state: any) => state.users.arr);
   const session: SessionState = useAppSelector((state: any) => state.session);

   const userToEdit = id ? users.find((u) => u.id === id) : null;
   const name1 = generateName(3);
   const name2 = generateName(3);
   const [formData, setFormData] = useState<any>({
      name1: DEMO ? name1 : userToEdit ? userToEdit.name1 : "",
      name2: DEMO ? name2 : userToEdit ? userToEdit.name2 : "",
      school_id: DEMO
         ? Math.floor(Math.random() * 6) + 55
         : userToEdit
         ? userToEdit.school_id
         : "",
      user_level: DEMO ? 10 : userToEdit ? userToEdit.user_level : "",
      country_admin_id: userToEdit
         ? userToEdit.country_admin_id
         : session.user.id,
      country_code: userToEdit ? userToEdit.country_code : session.user.cc,
      telefono1: DEMO
         ? "507000" + Math.floor(Math.random() * 10000)
         : userToEdit
         ? userToEdit.telefono1
         : "",
      telefono2: DEMO
         ? "507000" + Math.floor(Math.random() * 10000)
         : userToEdit
         ? userToEdit.telefono2
         : "",
      email1: DEMO
         ? name1.toLowerCase().replace(/ /g, ".") + "@mygmail.com"
         : userToEdit
         ? userToEdit.email1
         : "",
      email2: DEMO
         ? name2.toLowerCase().replace(/ /g, ".") + "@mygmail.com"
         : userToEdit
         ? userToEdit.email2
         : "",
      demo: session.user.demo,
      password: DEMO ? rand() : "", // shorting it to offset autocomplete
      is_active: userToEdit ? userToEdit.is_active : "true",
   });

   useEffect(() => {
      if (userToEdit) {
         setFormData({
            ...formData,
            ...userToEdit,
         });
      }
   }, [userToEdit]);

   const [showPassword, setShowPassword] = useState(false);
   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState<any>({});
   const passwordRef = useRef<any>(null);
   const buttonRef = useRef<any>(null);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleChange = (
      e:
         | React.ChangeEvent<
              HTMLInputElement | { name?: string; value: unknown }
           >
         | any
   ) => {
      const { name, value } = e.target as HTMLInputElement;
      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const validateForm = () => {
      const errors: any = {};
      const requiredFields = [
         "name1",
         "school_id",
         "user_level",
         "country_admin_id",
         "country_code",
         "telefono1",
         "email1",
         "password",
      ];

      requiredFields.forEach((field: any) => {
         if (!formData[field]) {
            errors[field] = EN
               ? "This field is required"
               : "Este campo es obligatorio";
         }
      });

      return errors;
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | any) => {
      e.preventDefault();
      try {
         const validationErrors = validateForm();
         if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
         } else {
            setLoading(true);
            console.log(formData);

            // Check if the area code is already included in the phone numbers
            const telefono1 =
               formData.telefono1.startsWith(formData.areaCode1) &&
               formData.telefono1.length > 8
                  ? formData.telefono1
                  : formData.areaCode1 + formData.telefono1;

            const telefono2 =
               formData.telefono2.startsWith(formData.areaCode2) &&
               formData.telefono2.length > 8
                  ? formData.telefono2
                  : formData.areaCode2 + formData.telefono2;

            // check if email or phone already exists (only for adding new user)
            if (!id) {
               const user = users.find((u) => u.email1 === formData.email1);
               console.log(user);
               if (user) {
                  dispatch(
                     addSnackbarMsg(
                        msg(
                           EN
                              ? "Email already exists in the system"
                              : "Correo electrónico ya existe en el sistema",
                           "error"
                        )
                     )
                  );
                  setLoading(false);
                  return;
               }
               // const user2 = users.find(
               //    (u) => u.telefono1 === formData.telefono1
               // );
               // console.log(user2);
               // if (user2) {
               //    dispatch(
               //       addSnackbarMsg(
               //          msg(
               //             EN
               //                ? "Phone already exists in the system"
               //                : "Teléfono ya existe en el sistema",
               //             "error"
               //          )
               //       )
               //    );
               //    setLoading(false);
               //    return;
               // }
            }

            if (id) {
               // Filter out areaCode1 and areaCode2 from the formData
               const filteredFormData = Object.keys(formData)
                  .filter((key) => key !== "areaCode1" && key !== "areaCode2")
                  .reduce((obj: any, key) => {
                     obj[key] = formData[key];
                     return obj;
                  }, {});

               // Get the updateFields and updateValues without areaCode1 and areaCode2
               const updateFieldsObj = Object.keys(filteredFormData);
               const updateValuesObj = Object.values(filteredFormData);

               // Convert updateFields to a comma-separated string
               const updateFields = updateFieldsObj.join(",");

               // Convert updateValues to a comma-separated string after removing commas from values
               const updateValues = updateValuesObj
                  .map((v: any) => v.toString().replace(/,/g, ""))
                  .join(",");

               // Update existing user
               let res = await updateUserByID(
                  updateFields,
                  updateValues,
                  session.user.token,
                  id
               );
               console.log(res);
               // dispatch(updateSingleUser(formData));
               dispatch(
                  updateSingleUser({
                     id,
                     updateFields: updateFieldsObj,
                     updateValues: updateValuesObj,
                  })
               );
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN
                           ? "User updated " + new Date()
                           : "Usuario actualizado " + new Date(),
                        "success"
                     )
                  )
               );
            } else {
               // Add new user
               const res: any = await apiPost(API_URL + "/user/add", {
                  token: session.user.token,
                  ...formData,
               });
               // get the next highest id number from redux user state
               let highestId = users.reduce(
                  (maxId, user) => Math.max(maxId, user.id),
                  -Infinity
               );
               highestId++;
               formData.id = highestId;
               dispatch(addSingleUser(formData));
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN
                           ? "User added" + new Date()
                           : "Usuario agregado: " + new Date(),
                        "success"
                     )
                  )
               );
            }

            setLoading(false);
            // close the dialoge box
            dispatch(closeDialog());
            // reset all fields to empty if not editing
            if (!id) {
               setFormData({
                  name1: "",
                  name2: "",
                  school_id: "",
                  user_level: "",
                  country_admin_id: session.user.id,
                  country_code: session.user.cc,
                  telefono1: "",
                  telefono2: "",
                  email1: "",
                  email2: "",
                  demo: session.user.demo,
                  password: "",
                  is_active: "true",
               });
            }
         }
      } catch (error: any) {
         setLoading(false);
         console.log(error);
         error.response && error.response.data
            ? dispatch(
                 addSnackbarMsg(
                    msg(
                       EN
                          ? error.response.data.message
                          : error.response.data.mensaje,
                       "error"
                    )
                 )
              )
            : dispatch(addSnackbarMsg(msg(`Error ${new Date()}`, "error")));
      }
   };

   return (
      <Container component='main' maxWidth='md' sx={{ padding: 2 }}>
         <h3>
            {EN
               ? id
                  ? "Edit User"
                  : "Add User"
               : id
               ? "Editar usuario"
               : "Agregar usuario"}
         </h3>
         {DEMO && (
            <Typography sx={{ fontWeight: 700, color: "red", mb: 2 }}>
               {EN
                  ? "Demo Mode will auto fill fields"
                  : "Modo demo llenará automáticamente los campos"}
            </Typography>
         )}
         <Box sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit} autoComplete='off'>
               <FormControl
                  fullWidth
                  margin='normal'
                  error={!!errors.user_level}
               >
                  <InputLabel>
                     {EN ? "User Type" : "Tipo de usuario"}
                  </InputLabel>
                  <Select
                     size='small'
                     name='user_level'
                     value={formData.user_level}
                     onChange={handleChange}
                  >
                     <MenuItem key='i0' value={""}>
                        {EN ? "Select" : "Seleccione uno"}
                     </MenuItem>
                     <MenuItem key='i10' value={10}>
                        {EN ? "Guardian" : "Guardián"}
                     </MenuItem>
                     {USER_LEVEL >= 3 && (
                        <MenuItem key='i4' value={4}>
                           {EN
                              ? "School Admin"
                              : "Administración de la escuela"}
                        </MenuItem>
                     )}
                  </Select>
                  {errors.user_level && (
                     <Alert severity='error'>{errors.user_level}</Alert>
                  )}
               </FormControl>
               <TextField
                  size='small'
                  label={
                     EN ? "Person 1 (full name)" : "Persona 1 (nombre completo)"
                  }
                  name='name1'
                  value={formData.name1}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.name1}
                  helperText={errors.name1}
               />
               <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <FormControl size='small'>
                     <Select
                        name='areaCode1'
                        value={formData.areaCode}
                        onChange={handleChange}
                        style={{ width: 100, marginRight: 25, marginTop: 8 }}
                        renderValue={(selected) => {
                           const selectedArea: any = areaCodes.find(
                              (area) => area.code === selected
                           );
                           return (
                              <Box
                                 sx={{ display: "flex", alignItems: "center" }}
                              >
                                 <img
                                    src={selectedArea.flag}
                                    alt={`${selectedArea.country} flag`}
                                    style={{
                                       width: 24,
                                       height: 16,
                                       marginRight: 8,
                                    }}
                                 />
                                 {`${selectedArea.code}`}
                              </Box>
                           );
                        }}
                     >
                        {areaCodes.map((area) => (
                           <MenuItem key={area.code} value={area.code}>
                              <ListItemIcon>
                                 <img
                                    src={area.flag}
                                    alt={`${area.country} flag`}
                                    style={{
                                       width: 24,
                                       height: 16,
                                       marginRight: 8,
                                    }}
                                 />
                              </ListItemIcon>
                              <ListItemText primary={area.code} />
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>

                  <TextField
                     size='small'
                     label={EN ? "Phone 1" : "Teléfono 1"}
                     name='telefono1'
                     value={formData.telefono1}
                     onChange={handleChange}
                     fullWidth
                     margin='normal'
                     error={!!errors.telefono1}
                     helperText={errors.telefono1}
                     autoComplete='off'
                  />
               </Box>
               <TextField
                  size='small'
                  label={EN ? "Email 1" : "Correo electrónico 1"}
                  name='email1'
                  value={formData.email1}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.email1}
                  helperText={errors.email1}
                  autoComplete='off'
               />
               <TextField
                  size='small'
                  margin='normal'
                  disabled
                  required
                  fullWidth
                  name='password'
                  autoComplete='new-password'
                  defaultValue=''
                  label={EN ? "Password" : "Contraseña"}
                  type={showPassword ? "text" : "password"}
                  id='password'
                  inputRef={passwordRef}
                  helperText={errors.password}
                  onChange={handleChange}
                  value={formData.password}
                  InputProps={{
                     endAdornment: (
                        <InputAdornment position='end'>
                           <IconButton
                              aria-label='toggle password visibility'
                              onClick={handleClickShowPassword}
                              edge='end'
                           >
                              {showPassword ? (
                                 <VisibilityOff />
                              ) : (
                                 <Visibility />
                              )}
                           </IconButton>
                        </InputAdornment>
                     ),
                  }}
               />
               <FormControl
                  fullWidth
                  margin='normal'
                  error={!!errors.school_id}
               >
                  <InputLabel>
                     {EN ? "School ID" : "Identificación escolar"}
                  </InputLabel>
                  <Select
                     size='small'
                     name='school_id'
                     value={formData.school_id}
                     onChange={handleChange}
                  >
                     {schools.map((school) => (
                        <MenuItem key={school.id} value={school.id}>
                           {school.name}
                        </MenuItem>
                     ))}
                  </Select>
                  {errors.school_id && (
                     <Alert severity='error'>{errors.school_id}</Alert>
                  )}
               </FormControl>
               <FormControl
                  fullWidth
                  margin='normal'
                  error={!!errors.is_active}
               >
                  <InputLabel>{EN ? "Active" : "Activo"}</InputLabel>
                  <Select
                     name='is_active'
                     size='small'
                     value={formData.is_active}
                     onChange={handleChange}
                  >
                     <MenuItem value='true'>TRUE</MenuItem>
                     <MenuItem value='false'>FALSE</MenuItem>
                  </Select>
                  {errors.is_active && (
                     <Alert severity='error'>{errors.is_active}</Alert>
                  )}
               </FormControl>
               <Divider textAlign='center' style={{ marginTop: 20 }}>
                  {EN ? "2nd Contact (optional)" : "2do Contacto (opcional)"}
               </Divider>
               <TextField
                  size='small'
                  label={
                     EN ? "Person 2 (full name)" : "Persona 2 (nombre completo)"
                  }
                  name='name2'
                  value={formData.name2}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.name2}
                  helperText={errors.name2}
               />

               <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
                  <FormControl size='small'>
                     <Select
                        name='areaCode2'
                        value={formData.areaCode2} // Corrected to areaCode2
                        onChange={handleChange}
                        style={{ width: 100, marginRight: 25, marginTop: 8 }}
                        renderValue={(selected) => {
                           const selectedArea: any = areaCodes.find(
                              (area) => area.code === selected
                           );
                           return (
                              <Box
                                 sx={{ display: "flex", alignItems: "center" }}
                              >
                                 <img
                                    src={selectedArea.flag}
                                    alt={`${selectedArea.country} flag`}
                                    style={{
                                       width: 24,
                                       height: 16,
                                       marginRight: 8,
                                    }}
                                 />
                                 {`${selectedArea.code}`}
                              </Box>
                           );
                        }}
                     >
                        {areaCodes.map((area) => (
                           <MenuItem key={area.code} value={area.code}>
                              <ListItemIcon>
                                 <img
                                    src={area.flag}
                                    alt={`${area.country} flag`}
                                    style={{
                                       width: 24,
                                       height: 16,
                                       marginRight: 8,
                                    }}
                                 />
                              </ListItemIcon>
                              <ListItemText primary={area.code} />
                           </MenuItem>
                        ))}
                     </Select>
                  </FormControl>

                  <TextField
                     size='small'
                     label={EN ? "Phone 2" : "Teléfono 2"}
                     name='telefono2'
                     value={formData.telefono2} // Corrected to telefono2
                     onChange={handleChange}
                     fullWidth
                     margin='normal'
                     error={!!errors.telefono2}
                     helperText={errors.telefono2}
                     autoComplete='off'
                  />
               </Box>

               <TextField
                  size='small'
                  label={EN ? "Email 2" : "Correo electrónico 2"}
                  name='email2'
                  value={formData.email2}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  autoComplete='off'
               />
               <Button
                  size='small'
                  type='submit'
                  ref={buttonRef}
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
               >
                  {loading ? (
                     <CircularProgress size={24} />
                  ) : EN ? (
                     "Submit"
                  ) : (
                     "Enviar"
                  )}
               </Button>
            </form>
         </Box>
      </Container>
   );
};

export default AddEditUser;
