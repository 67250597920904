import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { DrawerLeft } from "./components/DrawerLeft";
import { AppBarTop } from "./AppBarTop";
import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../app/hooks";
import { getUserLevelText } from "../../utilities/gen";

interface DashboardTemplateProps {
   children: React.ReactNode;
   title?: string;
}

const DashboardTemplate = React.memo((props: DashboardTemplateProps) => {
   const { children, title = "Tiptap Inc" } = props;
   const [open, setOpen] = React.useState(true);
   const session = useAppSelector((state: any) => state.session);

   // Memoize the `EN` value and toggleDrawer function
   const EN = React.useMemo(() => session.lang === "en", [session.lang]);

   const toggleDrawer = React.useCallback(() => {
      setOpen((prev) => !prev);
   }, []);

   // Memoize the user level text to avoid recalculating on each render
   const userLevelText = React.useMemo(() => {
      return getUserLevelText(session.user.ul, EN);
   }, [session.user.ul, EN]);

   console.log("DashboardTemplate: ", props);

   return (
      <Box sx={{ display: "flex" }}>
         <CssBaseline />
         <AppBarTop />
         <DrawerLeft
            variant='permanent'
            open={open}
            toggleDrawer={toggleDrawer}
         />
         <Box
            component='main'
            sx={{
               backgroundColor: (theme) =>
                  theme.palette.mode === "light"
                     ? theme.palette.grey[100]
                     : theme.palette.grey[900],
               flexGrow: 1,
               height: "100vh",
               overflow: "auto",
            }}
         >
            <Toolbar />
            <Container maxWidth={false} sx={{ width: "100%", mt: 1, mb: 4 }}>
               <h3>{title}</h3>
               <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                     <Typography>{session.user.name}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} style={{ textAlign: "right" }}>
                     <Typography>{userLevelText}</Typography>
                  </Grid>
               </Grid>
               <div style={{ height: 20 }}></div>
               {children}
            </Container>
         </Box>
      </Box>
   );
});

export default DashboardTemplate;
