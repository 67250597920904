import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { otherPayments, otherPaymentsState } from "./otherPayments";

const initialState: otherPaymentsState = {
   arr: [],
   init: true,
};

export const otherPaymentsSlice = createSlice({
   name: "otherPayments",
   initialState,
   // The `reducers` field lets us define reducers and generate associated actions
   reducers: {
      setOtherPayments: (state, action: PayloadAction<otherPaymentsState>) => {
         // Clear the state before setting new otherPayments
         state.arr.splice(0, state.arr.length);
         // Push each new otherPayment into the state
         action.payload.arr.forEach((otherPayment) => {
            state.arr.push(otherPayment);
         });
         // Set the init flag to true
         state.init = true;
      },
      updateSingleOtherPayments: (
         state: any,
         action: PayloadAction<{
            id: string | number;
            updateFields: string[];
            updateValues: string[];
         }>
      ) => {
         // given that you are passed the id, updateFields[] and updateValues[]
         // find the otherPayment with the id and update the fields
         const { id, updateFields, updateValues } = action.payload;
         const index = state.arr.findIndex(
            (otherPayment: otherPayments) => otherPayment.payment_id === id
         );
         if (index !== -1) {
            updateFields.forEach((field: any, i: any) => {
               state.arr[index][field] = updateValues[i];
            });
         }
      },
      addSingleOtherPayments: (state, action: PayloadAction<otherPayments>) => {
         // Add a single otherPayment to the state
         state.arr.push(action.payload);
      },
      clearOtherPayments: (state) => initialState,
   },
});

export const {
   setOtherPayments,
   updateSingleOtherPayments,
   clearOtherPayments,
   addSingleOtherPayments,
} = otherPaymentsSlice.actions;
export const selectotherPaymentss = (state: RootState) => state.otherPayments;

export default otherPaymentsSlice.reducer;
