import { Suspense, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme, lightTheme } from "./components/themes/Theme";
import ProtectedRoute from "./pages/Protected/ProtectedRoute";
import CssBaseline from "@mui/material/CssBaseline";
import "./App.css";

import Dashboard from "./pages/Protected/Dashboard";
import Login from "./pages/Login";
import SignUp from "./pages/Public/Signup";
import Forgot from "./pages/Public/Forgot";
import { SessionState } from "./features/session/session";
import { useAppSelector } from "./app/hooks";
import Terms from "./pages/Public/Legal/Terms";
import Privacy from "./pages/Public/Legal/Privacy";
import FeesGrid from "./pages/Protected/Fees/Grid";
import SchoolGrid from "./pages/Protected/Schools/Grid";
import StudentsGrid from "./pages/Protected/Students/Grid";
import UsersGrid from "./pages/Protected/Users/Grid";
import OtherPaymentsGrid from "./pages/Protected/OtherPayments/Grid";
import PaymentsGrid from "./pages/Protected/Payments/Grid";
import ValidateOTPGrid from "./pages/Protected/Validate/Grid";
import VouchersGrid from "./pages/Protected/Vouchers/Grid";
import Page404 from "./pages/Public/Page404";
import Settings from "./pages/Protected/Settings";
import GridLogs from "./pages/Protected/Users/GridLogs";
import TransactionGrid from "./pages/Protected/Transactions/Grid";
import OptionalFeesGrid from "./pages/Protected/Schools/OptionalFees";

export default function App() {
   // import session from redux store
   const session: SessionState = useAppSelector((state: any) => state.session);

   //const dispatch = useAppDispatch();
   const { darkMode = false } = session;

   useEffect(() => {
      // update template
   }, [darkMode]);
   return (
      <>
         <Suspense fallback={<div>Loading...</div>}>
            <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
               <CssBaseline />
               <Routes>
                  <Route path='' element={<Login />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/' element={<Login />} />
                  <Route path='/signup' element={<SignUp />} />
                  <Route path='/forgot' element={<Forgot />} />
                  <Route path='/privacy' element={<Privacy />} />
                  <Route path='/terms' element={<Terms />} />

                  <Route element={<ProtectedRoute />}>
                     <Route path='/dashboard' element={<Dashboard />} />
                     <Route path='/grid/students' element={<StudentsGrid />} />
                     <Route path='/grid/schools' element={<SchoolGrid />} />
                     <Route path='/grid/users' element={<UsersGrid />} />
                     <Route path='/grid/fees' element={<FeesGrid />} />
                     <Route path='/grid/logs' element={<GridLogs />} />
                     <Route
                        path='/grid/otherPayments'
                        element={<OtherPaymentsGrid />}
                     />
                     <Route
                        path='/grid/optional_fees'
                        element={<OptionalFeesGrid />}
                     />

                     <Route
                        path='/grid/validate'
                        element={<ValidateOTPGrid />}
                     />
                     <Route path='/grid/vouchers' element={<VouchersGrid />} />
                     <Route path='/grid/payments' element={<PaymentsGrid />} />
                     <Route
                        path='/grid/transactions'
                        element={<TransactionGrid />}
                     />
                     <Route path='settings' element={<Settings />} />
                     {/* <Route path='*' element={<Page404 />} /> */}
                  </Route>
               </Routes>
            </ThemeProvider>
         </Suspense>
      </>
   );
}
