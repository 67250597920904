import React, { useState, useEffect } from "react";
import {
   TextField,
   Button,
   FormControl,
   InputLabel,
   Container,
   Box,
   CircularProgress,
   Alert,
   Typography,
   Divider,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { SessionState } from "../../features/session/session";
import { addSnackbarMsg } from "../../features/snackbar/snackbarSlice";
import { apiPost } from "../../utilities/ApiRequests";
import { API_URL } from "../../constants/api";
import {
   addSingleSchool,
   updateSingleSchool,
} from "../../features/schools/schoolsSlice"; // Assuming you have these actions
import { closeDialog } from "../../features/dialog/dialogSlice";
import { generateName, msg } from "../../utilities/gen";

interface AddEditSchoolProps {
   params: {
      EN: boolean;
      DEMO: boolean;
      id?: number;
   };
}

const AddEditSchool = (props: AddEditSchoolProps) => {
   const dispatch = useAppDispatch();
   const { EN, DEMO, id } = props.params;
   const schools = useAppSelector((state: any) => state.schools.arr);
   const session: SessionState = useAppSelector((state: any) => state.session);
   const token = session.user.token;

   const schoolToEdit = id ? schools.find((s: any) => s.id === id) : null;
   const name1 = generateName(3);
   const sName = generateName(1);

   const [formData, setFormData] = useState<any>({
      name: DEMO
         ? "Escuela " + generateName(1)
         : schoolToEdit
         ? schoolToEdit.name
         : "",
      country_code: schoolToEdit ? schoolToEdit.country_code : session.user.cc,
      bank_account: DEMO
         ? Math.floor(Math.random() * 10000000000)
         : schoolToEdit
         ? schoolToEdit.bank_account
         : "",
      contact: DEMO ? name1 : schoolToEdit ? schoolToEdit.contact : "",
      address: DEMO
         ? Math.floor(Math.random() * 100) + ` Ave ${sName}, Ciudad de Panamá`
         : schoolToEdit
         ? schoolToEdit.address
         : "",
      product_rates: DEMO
         ? "Demo Rates"
         : schoolToEdit
         ? schoolToEdit.product_rates
         : "",
      bank_name: DEMO
         ? "Banco " + generateName(1)
         : schoolToEdit
         ? schoolToEdit.bank_name
         : "",
      phone: DEMO
         ? "507000" + Math.floor(Math.random() * 10000)
         : schoolToEdit
         ? schoolToEdit.phone
         : "",
      email: DEMO
         ? name1.toLowerCase().replace(/ /g, ".") + "@mygmail.com"
         : schoolToEdit
         ? schoolToEdit.email
         : "",
      demo: session.user.demo,
   });

   useEffect(() => {
      if (schoolToEdit) {
         setFormData({
            ...formData,
            ...schoolToEdit,
         });
      }
   }, [schoolToEdit]);

   const [loading, setLoading] = useState(false);
   const [errors, setErrors] = useState<any>({});

   const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = e.target;
      setFormData({
         ...formData,
         [name]: value,
      });
   };

   const validateForm = () => {
      const errors: any = {};
      const requiredFields = [
         "name",
         "country_code",
         "bank_account",
         "contact",
         "phone",
         "email",
      ];

      requiredFields.forEach((field: any) => {
         if (!formData[field]) {
            errors[field] = EN
               ? "This field is required"
               : "Este campo es obligatorio";
         }
      });

      return errors;
   };

   const handleSubmit = async (e: React.FormEvent<HTMLFormElement> | any) => {
      e.preventDefault();
      try {
         const validationErrors = validateForm();
         if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
         } else {
            setLoading(true);

            if (id) {
               // Update existing school
               // Get the updateFields as an array of field names
               const updateFields = Object.keys(formData);

               // Get the updateValues as an array of corresponding values
               const updateValues = Object.values(formData).map((v: any) =>
                  v.toString().replace(/,/g, "")
               );

               // Make the API call
               let res = await apiPost(API_URL + "/crud/update/matching", {
                  tableName: "schools",
                  updateFields: updateFields.join(","), // Send as a string for the API call
                  updateValues: updateValues.join(","), // Send as a string for the API call
                  searchFields: "id",
                  search: id,
                  token,
               });

               // Dispatch the action with arrays for updateFields and updateValues
               dispatch(updateSingleSchool({ id, updateFields, updateValues }));
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN
                           ? "School updated successfully"
                           : "Escuela actualizada con éxito",
                        "success"
                     )
                  )
               );
            } else {
               // Add new school
               const addFields = Object.keys(formData).join(",");
               const addValues = Object.values(formData)
                  .map((v: any) => v.toString().replace(/,/g, ""))
                  .join(",");
               const res = await apiPost(API_URL + "/crud/create", {
                  token,
                  tableName: "schools",
                  addFields,
                  addValues,
               });

               console.log(res.data.data.id);
               const id = res.data.data.id;

               // add the new school to the schools array
               dispatch(
                  addSingleSchool({
                     id,
                     ...formData,
                  })
               );
               dispatch(
                  addSnackbarMsg(
                     msg(
                        EN
                           ? "School added successfully"
                           : "Escuela agregada con éxito",
                        "success"
                     )
                  )
               );
            }

            setLoading(false);
            dispatch(closeDialog());
            if (!id) {
               setFormData({
                  name: "",
                  country_code: session.user.cc,
                  bank_account: "",
                  contact: "",
                  address: "",
                  product_rates: "",
                  bank_name: "",
                  phone: "",
                  email: "",
                  demo: session.user.demo,
               });
            }
         }
      } catch (error: any) {
         setLoading(false);
         console.log(error);
         dispatch(
            addSnackbarMsg(
               msg(
                  EN
                     ? `Error: ${new Date().toLocaleString()}`
                     : `Error: ${new Date().toLocaleString()}`,
                  "error"
               )
            )
         );
      }
   };

   return (
      <Container component='main' maxWidth='md' sx={{ padding: 2 }}>
         <h3>
            {EN
               ? id
                  ? "Edit School"
                  : "Add School"
               : id
               ? "Editar escuela"
               : "Agregar escuela"}
         </h3>
         {DEMO && (
            <Typography sx={{ fontWeight: 700, color: "red", mb: 2 }}>
               {EN
                  ? "Demo Mode will auto fill fields"
                  : "Modo demo llenará automáticamente los campos"}
            </Typography>
         )}
         <Box sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit} autoComplete='off'>
               <TextField
                  size='small'
                  label={EN ? "School Name" : "Nombre de la escuela"}
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.name}
                  helperText={errors.name}
               />
               <TextField
                  size='small'
                  label={EN ? "Country Code" : "Código de país"}
                  name='country_code'
                  value={formData.country_code}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.country_code}
                  helperText={errors.country_code}
               />
               <TextField
                  size='small'
                  label={EN ? "Bank Account" : "Cuenta bancaria"}
                  name='bank_account'
                  value={formData.bank_account}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.bank_account}
                  helperText={errors.bank_account}
               />
               <TextField
                  size='small'
                  label={EN ? "Contact Person" : "Persona de contacto"}
                  name='contact'
                  value={formData.contact}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.contact}
                  helperText={errors.contact}
               />
               <TextField
                  size='small'
                  label={EN ? "Address" : "Dirección"}
                  name='address'
                  value={formData.address}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
               />
               <TextField
                  size='small'
                  label={EN ? "Product Rates" : "Tarifas de productos"}
                  name='product_rates'
                  value={formData.product_rates}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
               />
               <TextField
                  size='small'
                  label={EN ? "Bank Name" : "Nombre del banco"}
                  name='bank_name'
                  value={formData.bank_name}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
               />
               <TextField
                  size='small'
                  label={EN ? "Phone" : "Teléfono"}
                  name='phone'
                  value={formData.phone}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.phone}
                  helperText={errors.phone}
               />
               <TextField
                  size='small'
                  label={EN ? "Email" : "Correo electrónico"}
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  fullWidth
                  margin='normal'
                  error={!!errors.email}
                  helperText={errors.email}
               />
               <Button
                  size='small'
                  type='submit'
                  fullWidth
                  variant='contained'
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
               >
                  {loading ? (
                     <CircularProgress size={24} />
                  ) : EN ? (
                     "Submit"
                  ) : (
                     "Enviar"
                  )}
               </Button>
            </form>
         </Box>
      </Container>
   );
};

export default AddEditSchool;
